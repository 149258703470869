import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Swal from 'sweetalert2';

import { Loading } from '../../../../ui/Loading';
import { EstadoRegistro } from '../../../../elements/EstadoRegistro';
import { InputFilterTable } from '../../../../elements/InputFilterTable';
import { PaginationTable } from '../../../../elements/PaginationTable';
import { EncabezadoTablaBuscador } from '../../../../elements/EncabezadoTablaBuscador';
import { IndicadorBodega } from '../../../../elements/IndicadorBodega';

import { pedidoLoad, pedidoTransferirSAP } from '../../../../actions/comercial';

import { useForm } from '../../../../../hooks/useForm';
import { BodyFiltroPedido } from './BodyFiltroPedido';
import { AccionPedidoPermisos } from './AccionPedidoPermisos';
import { FormularioPedido } from './FormularioPedido';
import { FormularioPedidoEditar } from './FormularioPedidoEditar';

export const PedidoScreen = () => {
  const dispatch = useDispatch();

  const { token, user } = useSelector(state => state.auth);
  const { pedidoTabla, idPedido, pedidoComentario } = useSelector(state => state.comercial);
  const [ cliente, setCliente ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false); 
  const [ isOpenVisor, setIsOpenVisor ] = useState(false);
  const [ isOpenEditor, setIsOpenEditor ] = useState(false);
  const [ isOpenComentario, setIsOpenComentario ] = useState(false);
  const [ idDetalle, setIdDetalle ] = useState(-1);
  const [data, setData] = useState([]);
  const { comentario, onInputChange, onResetForm } = useForm({comentario: ''});

  let digitador_pedido = false;

  const [ formFiltro, setFormFiltro ] = useState({
    pedido: '',
    sap: 0, 
    fecha_1: '', 
    fecha_2: '', 
    fecha_sap_1: '', 
    fecha_sap_2: '', 
    cliente: '-1'
  });

  const colSpanTable = 14;

  /*Encabezado de la tabla*/
  const columns = useMemo(
      () => [
          {
            Header: "#",
            accessor: "ID_Pedido",
            Cell: ({value, cell}) => {
              return <AccionPedidoPermisos 
                Id = { value }
                Cliente = { cell.row.values.Cliente }
                Fecha = { cell.row.values.Fecha } 
                SAP = { cell.row.values.SAP }  
                ClienteNombre = { `${cell.row.values.ID_Cliente} - ${cell.row.values.Cliente}` }          
                setIdDetalle = { setIdDetalle }                  
                setCliente = { setCliente }
                setIsLoading = { setIsLoading }
                setIsOpenVisor = { setIsOpenVisor }
                setIsOpenEditor = { setIsOpenEditor }
                setIsOpenComentario = { setIsOpenComentario }/>
            }
          },
          {
            Header: "Colaborador",
            accessor: "Colaborador"
          },
          {
            Header: "Telefono",
            accessor: "Telefono"
          },
          {
            Header: "No. Orden",
            accessor: "Orden"
          },
          {
            Header: "Codigo Cliente",
            accessor: "ID_Cliente"
          },
          {
            Header: "Cliente",
            accessor: "Cliente"
          },
          {
            Header: "Fecha/Hora",
            accessor: "Fecha"
          },
          {
            Header: "Fecha Entrega",
            accessor: "Fecha_Entrega"
          },
          {
            Header: "Fecha/Hora SAP",
            accessor: "Fecha_SAP"
          },
          {
            Header: "Comentario",
            accessor: "Comentario"
          },
          {
            Header: "SAP",
            accessor: "SAP"
          },
          {
            Header: "Digitador",
            accessor: "Digitador"
          },
          {
            Header: "Bodega",
            accessor: "Bodega",
            Cell: ({ value, cell }) => {
                return <IndicadorBodega Bodega ={value}/>
            }
          },
          {
            Header: "Estado",
            accessor: "Estado",
            Cell: ({ value, cell }) => {
                return <EstadoRegistro Estado ={value}/>
            }
          }
      ]
  , []);

  /*Hook para crear la tabla*/
  const table = useTable({ columns, data, initialState: {
      hiddenColumns: ['Fecha_SAP', 'SAP', 'Digitador', 'Estado'],
      pageSize: 50,
      pageIndex: 0
    } }, useGlobalFilter, useSortBy, usePagination);

  /*El restructuracion de parametros basados del objeto table*/
  const {
      preGlobalFilteredRows,
      setGlobalFilter,
      state: { globalFilter },
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize }
  } = table;

  useEffect(() => {
    onBuscarFiltro();
  }, []);

  useEffect(() => {
    if(idPedido){
      const tablaData = data.filter((row) => row.ID_Pedido !== idPedido.data);
      setData( tablaData );
    }
  }, [idPedido]);

  useEffect(() => {
    if(pedidoComentario){
      if(pedidoComentario.data){
        setIsOpenComentario(true);
        setIdDetalle(pedidoComentario.data);
      }
    }
  }, [pedidoComentario]);

  useMemo(() => {
    if(pedidoTabla){
        setData( pedidoTabla ); 
    }
  }, [pedidoTabla]);

  const onBuscarFiltro = (e, setIsOpenFiltro) =>{
    if(e){
        e.preventDefault();
        setIsOpenFiltro(false);
    }
    
    dispatch( pedidoLoad( token, user.id_usuario, (formFiltro.pedido.length < 1 ? '-1': formFiltro.pedido), formFiltro.sap,
      formFiltro.cliente, formFiltro.fecha_1, formFiltro.fecha_2, 
      formFiltro.fecha_sap_1, formFiltro.fecha_sap_2, setIsLoading ) );
  }

  const onEnviarSAP = (e) =>{
    e.preventDefault();
    dispatch( pedidoTransferirSAP(token, user.id_usuario, idDetalle, comentario, cliente, setIsLoading) );
    setIsOpenComentario(false);
    onResetForm();
  }

  //obtener los permisos especiales
  user.permiso.filter(item => item.Permiso === 'SUPER_TMK').map(filteredName => {
    digitador_pedido =true;
  });

  user.permiso.filter(item => item.Permiso === 'DIGITA_PEDIDO').map(filteredName => {
    digitador_pedido =true;
  });
  //console.log(digitador_pedido);
  return (
  <>
    <Loading isLoading={isLoading} />
      <div className="container">
        <EncabezadoTablaBuscador Titulo='Lista de pedidos'
          BodyModal = { <BodyFiltroPedido
            setIsLoading={ setIsLoading } 
            setFormFiltro = { setFormFiltro }
            formFiltro = { formFiltro }/> }
            onBuscarFiltro = { onBuscarFiltro }
            DataTabla = { (digitador_pedido && data) }/>
        <div className='table-responsive' style={{minHeight: '500px'}}>
          <table
            className='table table-striped table-hover table-bordered'
            { ...getTableProps }>
            <thead>
              <tr>
                <th colSpan={ colSpanTable }>
                  <InputFilterTable 
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter} />
                </th>
              </tr>
              {
                headerGroups.map( headerGroup => (
                  <tr { ...headerGroup.getHeaderGroupProps() }>
                    {
                      headerGroup.headers.map( columns => 
                      (
                        <th { ...columns.getHeaderProps(columns.getSortByToggleProps()) }
                          className={
                            columns.isSorted 
                              ? columns.isSortedDesc
                                ? "desc"
                                : "asc"
                              : ""
                          }>
                          {
                            columns.render("Header")
                          }
                        </th>
                      ))
                    }
                  </tr>
                ) ) 
              }
            </thead>
            <tbody { ...getTableBodyProps() }>
              {
                page.map( row => {

                  prepareRow( row )
                  return  (
                    <tr { ...row.getRowProps() }>
                      {
                        row.cells.map( cell => 
                        (
                          <td { ...cell.getCellProps() }>
                            {
                              cell.render("Cell")
                            }
                          </td>
                        ))
                      }
                    </tr>
                  )
                } )
              }

              <tr className='table-secondary'>
                <td colSpan={ colSpanTable }>
                  <PaginationTable 
                    canPreviousPage = {canPreviousPage}
                    canNextPage = {canNextPage}
                    pageOptions = {pageOptions}
                    pageCount = {pageCount}
                    gotoPage = {gotoPage}
                    nextPage = {nextPage}
                    previousPage = {previousPage}
                    setPageSize = {setPageSize}
                    pageIndex = {pageIndex}
                    pageSize = {pageSize}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>

    <Modal isOpen={isOpenVisor} fullscreen>
      <ModalBody>
        <FormularioPedido Id_Pedido = { idDetalle } setIsOpenVisor = { setIsOpenVisor }/>
      </ModalBody>
    </Modal>

    <Modal isOpen={isOpenEditor} fullscreen>
      <ModalBody>
        <FormularioPedidoEditar Id_Pedido = { idDetalle } Cliente_Nombre = { cliente } 
          onBuscarFiltro = { onBuscarFiltro } setIsOpenEditor = { setIsOpenEditor } />
      </ModalBody>
    </Modal>

    <Modal isOpen={isOpenComentario}>
      <ModalHeader>Pedido #{idDetalle}</ModalHeader>
      <ModalBody>
          <label>{ pedidoComentario?.msg }</label>
          <textarea 
            className='form-control' 
            rows='3' 
            name='comentario' 
            value={comentario}
            onChange={ onInputChange }/>
      </ModalBody>
      <ModalFooter>
          <button type="button" className="btn btn-primary" onClick={ onEnviarSAP }>Enviar</button>
          <button type="button" className="btn btn-secondary" onClick={(e)=>setIsOpenComentario(false)}>Cerrar</button>                    
      </ModalFooter>
    </Modal>
  </>
  )
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2'

import { useForm } from '../../../../hooks/useForm';

import { Loading } from '../../../ui/Loading';
import { CardCliente } from './CardCliente';
export const DatoScreen = () => {

    let index = 0;
    const [clientes, setClientes] = useState([]);
    const { token, user } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);

    const { tipo, buscador, formState, onInputChange } = useForm({
        tipo: "N", buscador: ""
    });
  
    useEffect(() => {
      setIsLoading(false);
    }, [setIsLoading]);

    const handlerBuscar = (e) => {
        e.preventDefault();

        setIsLoading(true);
        setClientes( [] );
        const datos = { 
            id_usuario: user.id_usuario, tipo: tipo, nit: buscador, telefono: buscador
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( `${ process.env.REACT_APP_API_SALAVENTA }consulta/dato_cliente_entrega_domicilio`, datos )
        .then( response =>{
            let json = response.data;

            if(json.stt == "OK"){
                setClientes( json.data );
            }else{
                Swal.fire('Consulta', json.msg, 'error');                
            }
            setIsLoading(false);
        })
        .catch( ({response, message}) =>{ 
            setIsLoading(false);
            Swal.fire('Consulta', message, 'error');
        }); 
    }
  
    return (
      <>
        <Loading isLoading={isLoading} />
        <div className="container">
            <h3>Consulta cliente ICG</h3>
            <hr/>

            <form className="row g-3">
                <div className="col-sm-6 col-md-2">
                    <label className="form-label">Tipo</label>
                    <select className="form-select" id='tipo' name='tipo' onChange={ onInputChange }>
                        <option value='N'>NIT</option>
                        <option value='T'>Telefono</option>
                    </select>
                </div>
                <div className="col-sm-6 col-md-8">
                    <label className="form-label">Buscador</label>
                    <input className="form-control" name='buscador' value={ buscador } onChange={ onInputChange }/>
                </div>
                <div className="col-sm-12 col-md-2 d-grid">
                    <label className="form-label">&nbsp;&nbsp;&nbsp;</label>
                    <button type="button" className="btn btn-primary btn-block" onClick={ handlerBuscar }><i className='fa-solid fa-search'></i></button>
                </div>
            </form>
            <hr/>
            {
                clientes?.map( cliente => (
                    <CardCliente key = { index++ }
                        { ...cliente } />
                ))
            }
        </div>
      </>
    )
  }
import React from 'react'

export const UsuarioDatos = () => {
  return (
    <form className="row g-3 my-1">

        <div className="col-sm-4">
            <label className="form-label">Nombres</label>
            <input type="text" className="form-control form-control-sm" id="inputPassword2" placeholder="Nombres" />
        </div>
        <div className="col-sm-4">
            <label className="form-label">Apellidos</label>
            <input type="text" className="form-control form-control-sm" id="inputPassword2" placeholder="Apellidos" />
        </div>
        <div className="col-sm-4">
            <label className="form-label">Telefono</label>
            <input type="number" className="form-control form-control-sm" id="inputPassword2" placeholder="Telefono" />
        </div>
        <div className="col-12">
            <label className="form-label">Correo</label>
            <input type="email" className="form-control form-control-sm" id="inputPassword2" placeholder="usuario@parma.gt" />
        </div>
        
        <hr />

        <div className="col-sm-4">
            <label className="form-label">Usuario</label>
            <input type="text" className="form-control form-control-sm" id="inputPassword2" placeholder="Usuario" />
        </div>
        <div className="col-sm-4">
            <label className="form-label">Contraseña</label>
            <input type="password" className="form-control form-control-sm" id="inputPassword2" placeholder="Contraseña" />
        </div>
        <div className="col-sm-4">
            <label className="form-label">Confirmar Contraseña</label>
            <input type="password" className="form-control form-control-sm" id="inputPassword2" placeholder="Confirmar Contraseña" />
        </div>

        <div className="col-sm-4">
            <label className="form-label">Fecha Expiracion Usuario</label>
            <input type="date" className="form-control form-control-sm" id="inputPassword2" placeholder="AAAA-MM-DD" />
        </div>
        <div className="col-sm-4">
            <label className="form-label">Fecha Expiracion Contraseña</label>
            <input type="date" className="form-control form-control-sm" id="inputPassword2" placeholder="AAAA-MM-DD" />
        </div>

    </form>
  )
}

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Container, TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Pagination, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import * as XLSX from 'xlsx';  // Importamos la librería XLSX

function ListaCaRep() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState(""); 
  const [filtroTexto, setFiltroTexto] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(50);
  const [codigoCA, setCodigoCA] = useState("1"); // Valor inicial para 'codigo_ca'

  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaRegistrosCAWEB",
        Datos_Recibidos: `{"FechaIni":"${fechaInicio}","Codigo_ca":"${codigoCA}"}`
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;

    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        let datosGeneradosDecodificados = [];
        try {
          const parsedData = JSON.parse(data.datos_Generados);
          datosGeneradosDecodificados = Array.isArray(parsedData.LISTAENTREGASCA) ? parsedData.LISTAENTREGASCA : [];
        } catch (error) {
          console.error('Error al decodificar datos_Generados:', error);
        }

        if (datosGeneradosDecodificados.length === 0) {
          console.warn('No se encontraron datos en LISTAENTREGASCA');
        }
        console.log(datosGeneradosDecodificados)
        setDatosGenerados(datosGeneradosDecodificados);
      })
      .catch(error => console.error('Error en la solicitud:', error));
  };

  useEffect(() => {
    fetchData();
  }, [fechaInicio, fechaFin, codigoCA]); // Asegúrate de que el código_CA se pase también

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const filteredData = datosGenerados.filter(item => {
    const searchValue = filtroTexto.toLowerCase();
    return (
      item['Centro de Acopio']?.toLowerCase().includes(searchValue) ||
      item['Codigo Proveedor']?.toLowerCase().includes(searchValue) ||
      item['Nombre Proveedor']?.toLowerCase().includes(searchValue)
    );
  });

  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);

  // Función para exportar a Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");
    
    // Genera el archivo Excel y lo descarga
    XLSX.writeFile(workbook, "Datos_Lista_CA.xlsx");
  };

  return (
    <Container >
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          id="filtroTexto"
          label="Buscar"
          value={filtroTexto}
          onChange={(e) => setFiltroTexto(e.target.value)}
          variant="outlined"
          style={{ marginBottom: '10px', width: '500px' }}
        />
        <TextField
          id="fechaInicio"
          label="Fecha de Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <FormControl variant="outlined" style={{ marginRight: '10px', width: '200px' }}>
          <InputLabel id="codigoCA">Centro de Acopio</InputLabel>
          <Select
            labelId="codigoCA"
            value={codigoCA}
            onChange={(e) => setCodigoCA(e.target.value)}
            label="Centro de Acopio"
          >
            <MenuItem value="1">Chimaltenango</MenuItem>
            <MenuItem value="2">Monjas</MenuItem>
            <MenuItem value="3">San Pedro Pinula</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={handleSearch}
          style={{ marginRight: '10px', height: '55px', width: '100px' }}
        >
          Buscar
        </Button>
        {/* Botón para exportar a Excel */}
        <Button
          variant="contained"
          onClick={exportToExcel}
          style={{ height: '55px', width: '150px' }}
        >
          Exportar a Excel
        </Button>
      </div>
      
      <Table>
  <TableHead>
    <TableRow>
      <TableCell>Centro de Acopio</TableCell>
      <TableCell>Código Proveedor</TableCell>
      <TableCell>Nombre Proveedor</TableCell>
      <TableCell>Fecha</TableCell>
      <TableCell>Hora</TableCell>
      <TableCell >
        NO.Vale
      </TableCell>
      <TableCell>KG</TableCell>
      <TableCell>L</TableCell>
      <TableCell>Temperatura</TableCell>
      <TableCell>Alcohol</TableCell>
      <TableCell >Usuario</TableCell>
      <TableCell>Observaciones</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {datosPaginados.map((item, index) => (
      <TableRow key={index}>
        <TableCell  style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all', overflowWrap: 'normal', width:'200px' }} >{item['Centro de Acopio']}</TableCell>
        <TableCell  style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all', overflowWrap: 'normal', width:'200px' }} >{item['Codigo Proveedor']}</TableCell>
        <TableCell  style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all', overflowWrap: 'normal', width:'200px' }} >{item['Nombre Proveedor']}</TableCell>
        <TableCell>{new Date(item['Fecha']).toLocaleDateString()}</TableCell>
        <TableCell>{item['Hora']}</TableCell>
        <TableCell style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all', overflowWrap: 'normal', width:'200px' }} >{item['Vale']}</TableCell>
        <TableCell>{item['KG']}</TableCell>
        <TableCell>{item['L']}</TableCell>
        <TableCell>{item['TempF']}</TableCell>
        <TableCell>{item['Alcohol']}</TableCell>
        <TableCell style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all', overflowWrap: 'normal', width:'200px' }} >{item['Usuarios']}</TableCell>
        <TableCell style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all', overflowWrap: 'normal', width:'200px' }} >{item['Observaciones']}</TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>


      
      <Pagination
        count={Math.ceil(filteredData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </Container>
  );
}

export default ListaCaRep;

import React from 'react'

export const EstadoRegistro = ({
    Estado
}) => {
  return (
    <div className='card-columns animate__animated animate__fideIn text-center'>
        {
            Estado == 1 
            ? <span className="badge bg-success" title='Activo'><i className="fa-solid fa-check"></i></span>
            : Estado == 2
                ? <span className="badge bg-warning text-white"  title='Inactivo'><i className="fa-solid fa-ban"></i></span>
                : <span className="badge bg-danger"  title='Anulado'><i className="fa-solid fa-times"></i></span>
        }
    </div>
  )
}

import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import { authReducer } from '../auth/authReducer';
import { comercialReducer } from '../auth/comercialReducer';
import { selectorReducer } from '../auth/selectorReducer';
import { seguridadReducer } from '../auth/seguridadReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    auth: authReducer,
    comercial: comercialReducer,
    selector: selectorReducer,
    seguridad: seguridadReducer
});

export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);

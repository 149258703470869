import React from 'react'
import { Routes, Route } from "react-router-dom";

import { DatoScreen } from '../componentes/view/operaciones/informacion_gps_transporte/DatoScreen';

export const OperacionRoutes = () => {

  return (
    <Routes>            
      <Route path="/dato_gps_transporte" element={ <DatoScreen /> } />
    </Routes>
  )
}

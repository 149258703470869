import React from 'react';
import { NavLink } from "react-router-dom";

export const EncabezadoTabla = ({ Titulo, setIsOpen }) => {
  let isVisible = 'd-none';

  const handlerReload = (e) => {
    window.location.reload();
  } 

  if(setIsOpen)
       isVisible = '';

  return (
    <>
        <h3>{ Titulo }
        <button type="button" className={`btn btn-success rounded-pill float-end me-2 ${isVisible}`}
              onClick={(e)=>setIsOpen(true)}
              title='Agregar registro'>
              <span className='fa-solid fa-plus'></span>
          </button>
          <button type="button" className="btn btn-primary rounded-pill float-end me-2"
              onClick={handlerReload}
              title='Recargar registro'>
              <span className='fa-solid fa-sync'></span>
          </button>          
        </h3>
        <hr /> 
    </>
  )
}

import React from 'react'
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

export const ExporXLS = ({ DataTabla, ArchivoNombre }) => {
    const archivoTipo = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const archivoExtension = '.xlsx';

    let isVisible = 'd-none';

    const onExportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(DataTabla);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const xlsBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([xlsBuffer], { type: archivoTipo });
        FileSaver.saveAs(data, ArchivoNombre + archivoExtension);
    };

    if(DataTabla)
       isVisible = '';

  return (
    <button type="button" className={`btn btn-success rounded-pill float-end me-2 ${isVisible}`}
        onClick={ onExportToExcel }
        title='Descarga XLS del visor previo'>
        <span className='fa-solid fa-file-excel'></span>
    </button>
  )
}

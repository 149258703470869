import React, {useEffect} from 'react'
import { useState } from 'react';

export const CardCliente = ({
    codigo,
    nit,
    cliente,
    comercio,
    direccion,
    entrega,
    producto
}) => {

    const [direcciones, setDirecciones] = useState([]);
    const [entregas, setEntregas] = useState([]);
    const [productos, setProductos] = useState([]);

    useEffect(() => {
      if(direccion){
        setDirecciones(JSON.parse(direccion));
      }

      if(entrega){
        setEntregas(JSON.parse(entrega));
      }

      if(producto){
        setProductos(JSON.parse(producto));
      }
    }, [direccion, entrega, producto]);

  return (
    <div className='card-columns animate__animated animate__fideIn'>
        <div className="card mb-3">
            <div className="card-header">
                <h5 className="card-title"><b>NIT:</b> {nit}
                    <button 
                        className="btn btn-primary rounded-pill float-end me-2" 
                        type="button" 
                        data-bs-toggle="collapse" 
                        data-bs-target={"#h_" + codigo} 
                        aria-expanded="false" 
                        aria-controls={"h_" + codigo}>
                        <i className='fa-solid fa-caret-down'></i>
                    </button>
                </h5>
                <p className="card-text"><b>Cliente:</b> {cliente}</p>
                <p className="card-text"><b>Comercio:</b> {comercio}</p>
            </div>
            <div className="collapse mt-3" id={"h_" + codigo}>
                <button 
                    className="btn btn-success rounded-pill float-end me-2 mb-2" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={"#b_" + codigo}
                    aria-expanded="false" 
                    aria-controls={"b_" + codigo}>
                    <i className='fa-solid fa-caret-down'></i>
                </button>
                
                {
                    (direcciones.length == 0) && <br/>
                }
                {
                    (direcciones.length == 0) && <br/>
                }

                {
                    direcciones?.map(direccion =>(
                        <div className="card-body" key={"body_" + codigo}>
                            <h5 className="card-title">Direcci&oacute;n envio</h5>
                            <ul>
                                <li key="l1"><b>Telefono 1:</b> {direccion.TelefonoPrincipal}</li>
                                <li key="l2"><b>Telefono 2:</b> {direccion.TelefonoAdicional}</li>
                                <li key="l3"><b>Direcci&oacute;n 1:</b> {direccion.DireccionPrincipal}</li>
                                <li key="l4"><b>Observaci&oacute;n:</b> {direccion.Observacion}</li>
                            </ul>                                    
                        </div>
                    ))
                }
                
                <div className="collapse mt-3" id={"b_" + codigo}>
                    <div className="card card-body">
                        <div className='table-responsive'>
                            <table className="table table-striped table-hover table-sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Tienda</th>
                                        <th>Vendedor</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Tipo</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        entregas?.map(entrega =>(
                                            <tr key={entrega.Numero}>
                                                <td>{entrega.Numero}</td>
                                                <td>{entrega.Tienda}</td>
                                                <td>{entrega.Vendedor}</td>
                                                <td>{entrega.Fecha}</td>
                                                <td>{entrega.Hora}</td>
                                                <td>{entrega.Entrega}</td>
                                                <td>{entrega.Total}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <hr/>
                            <table className="table table-striped table-hover table-sm">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Referencia</th>
                                        <th>Producto</th>
                                        <th>Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        productos?.map(producto =>(
                                            <tr key={producto.Numero + "_" + producto.Codigo}>
                                                <td>{producto.Numero}</td>
                                                <td>{producto.Referencia}</td>
                                                <td>{producto.Producto}</td>
                                                <td>{producto.Cantidad}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>                        
            </div>
        </div>
    </div>
  )
}

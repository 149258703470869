import React from 'react';

class DeliveryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { proveedor: 'Proveedor A', cantidad: '100 kg', temperatura: '70 F°', alcohol: '0.02%', fecha: '2024-03-04 10:00', entrega: 'Juan Pérez' },
        { proveedor: 'Proveedor B', cantidad: '150 kg', temperatura: '65 F°', alcohol: '0.03%', fecha: '2024-03-05 11:00', entrega: 'Ana Gómez' },
        // Más datos de ejemplo...
      ],
    };
  }

  render() {
    return (
      <div style={{ margin: '20px', overflowX: 'auto' }}> 
        <h2>Resumen de Entregas</h2>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
  <div style={{ width: '30%', textAlign: 'center', padding: '10px' }}>
    <div style={{ marginBottom: '5px' }}>Chimaltenango</div>
    <div style={{ background: '#ddd', borderRadius: '10px', padding: '3px' }}>
      <div style={{ background: '#FF5733', width: '70%', height: '10px', borderRadius: '10px' }}></div>
    </div>
    <div>10300kg</div>
  </div>
  <div style={{ width: '30%', textAlign: 'center', padding: '10px' }}>
    <div style={{ marginBottom: '5px' }}>Jalapa 1</div>
    <div style={{ background: '#ddd', borderRadius: '10px', padding: '3px' }}>
      <div style={{ background: '#33CFFF', width: '80%', height: '10px', borderRadius: '10px' }}></div>
    </div>
    <div>15500kg</div>
  </div>
  <div style={{ width: '30%', textAlign: 'center', padding: '10px' }}>
    <div style={{ marginBottom: '5px' }}>Jalapa 2</div>
    <div style={{ background: '#ddd', borderRadius: '10px', padding: '3px' }}>
      <div style={{ background: '#DAF7A6', width: '10%', height: '10px', borderRadius: '10px' }}></div>
    </div>
    <div>1600kg</div>
  </div>
</div>



        <h2>Tabla de Entregas</h2>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#04AA6D', color: 'white' }}>Proveedor</th>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#04AA6D', color: 'white' }}>Cantidad de entrega</th>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#04AA6D', color: 'white' }}>Temperatura</th>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#04AA6D', color: 'white' }}>Prueba de alcohol</th>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#04AA6D', color: 'white' }}>Fecha y hora</th>
              <th style={{ textAlign: 'left', padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#04AA6D', color: 'white' }}>Persona que entrega</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item, index) => (
              <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                <td style={{ textAlign: 'left', padding: '8px', border: '1px solid #ddd' }}>{item.proveedor}</td>
                <td style={{ textAlign: 'left', padding: '8px', border: '1px solid #ddd' }}>{item.cantidad}</td>
                <td style={{ textAlign: 'left', padding: '8px', border: '1px solid #ddd' }}>{item.temperatura}</td>
                <td style={{ textAlign: 'left', padding: '8px', border: '1px solid #ddd' }}>{item.alcohol}</td>
                <td style={{ textAlign: 'left', padding: '8px', border: '1px solid #ddd' }}>{item.fecha}</td>
                <td style={{ textAlign: 'left', padding: '8px', border: '1px solid #ddd' }}>{item.entrega}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default DeliveryTable;

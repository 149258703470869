import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../../hooks/useForm';
import axios from 'axios';
import Swal from 'sweetalert2'

import { Loading } from '../../../ui/Loading';

import { MisDatosModificar, MiCuentaClaveModificar, MiCuentaSAPClaveModificar } from '../../../actions/seguridad';
import { data } from 'jquery';

export const MiCuenta = () => {
  const dispatch = useDispatch();

  const { token, user } = useSelector(state => state.auth);
  const { miDatoModificar } = useSelector(state => state.seguridad);
  const [isLoading, setIsLoading] = useState(false);

  const [dataCuenta, setDataCuenta] = useState([]);
  const [dataPermiso, setDataPermiso] = useState([]);
  const [dataPerfil, setDataPerfil] = useState([]);
  const [dataUbicacion, setDataUbicacion] = useState([]);
  const [dataSucursal, setDataSucursal] = useState([]);
  const [dataSap, setDataSap] = useState([]);

  const { nombre, apellido, telefono, correo, usuario, usuario_sap, 
    clave, confirmar_clave, clave_sap, confirmar_clave_sap, 
    setFormState, onInputChange, onResetForm } = useForm({});

  let isUsuarioSAP = 'd-none';

  useEffect(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    setIsLoading(true);
      axios.defaults.headers.common['Authorization'] = "Bearer " + token;
      axios.get( `${ process.env.REACT_APP_API_CONFIGURACION }consulta/mi_cuenta/${ user.id_usuario }` )
      .then( response =>{
          let json = response.data;

          if(json.stt == "OK"){
            if(json.data.sap)
              setDataSap( JSON.parse( json.data.sap ) );
              
            setDataCuenta( JSON.parse( json.data.cuenta ) );
            setDataPermiso( JSON.parse( json.data.permiso ) );            
            setDataPerfil( JSON.parse( json.data.perfil ) );
            setDataUbicacion( JSON.parse( json.data.ubicacion ) );
            setDataSucursal( JSON.parse( json.data.sucursal ) );
          }else{
            Swal.fire('Consulta', json.msg, 'error');                
          }
          setIsLoading(false);
      })
      .catch( ({response, message}) =>{ 
        setIsLoading(false);
        Swal.fire('Consulta', message, 'error');
      }); 
  }, []);

  useEffect(() => {
    if(miDatoModificar){
      if(miDatoModificar.data > 0){
        Swal.fire('Mis Datos', 'Operación Exitosa.', 'info');
      }
    }
  }, [miDatoModificar])
  
  useEffect(() => {
    if(dataCuenta){
      if(dataCuenta.length > 0){ 
        setFormState({
          nombre: dataCuenta[0].Nombre,
          apellido: dataCuenta[0].Apellido,
          telefono: dataCuenta[0].Telefono,
          correo: dataCuenta[0].Correo,
          usuario: dataCuenta[0].Usuario,
          usuario_sap: (dataSap.length > 0 ? "Cuenta de SAP Asociada" : "No tienes una cuenta de SAP Asociado")
        });
      }
    }
  }, [dataCuenta]);

  /*Eventos para botones*/
  const handlerGuardarDato = (e) =>{
    e.preventDefault();
    let bandera = true;
    const regNumero = /^[0-9]{8}$/g;
    const regCorreo = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

    if(nombre.length < 2){
      Swal.fire('Mis Datos', 'Debe ingresar un nombre', 'warning');
      bandera = false;
    }else if(apellido.length < 2){
      Swal.fire('Mis Datos', 'Debe ingresar un apellido', 'warning');
      bandera = false;
    }
    
    if(telefono.length > 0){
      if(!regNumero.test(telefono)){
        Swal.fire('Mis Datos', 'El formato para el numero de telefono no es correcto y el tamaño es de 8 digitos.', 'warning');
        bandera = false;
      }
    }
    if(correo.length > 0){
      if(!regCorreo.test(correo)){
        Swal.fire('Mis Datos', 'El formato para el correo electronico no es correcto.', 'warning');
        bandera = false;
      }
    }

    if(bandera){
      dispatch( MisDatosModificar(token, user.id_usuario, nombre, apellido, telefono, correo, setIsLoading) );
    }
  }
  const handlerGuardarCuenta = (e) =>{
      e.preventDefault();

      if(clave.length < 6){
        Swal.fire('Mi Cuenta', 'La contraseña debe tener como minimo 6 caracteres.', 'warning');
      }else if(clave !== confirmar_clave){
        Swal.fire('Mi Cuenta', 'La contraseña y la confirmacion de contraseña no son iguales', 'warning');
      }else{
        dispatch( MiCuentaClaveModificar(token, user.id_usuario, clave, setIsLoading) );
      }
  }

  const handlerGuardarCuentaSAP = (e) => {
      e.preventDefault();

      if(clave_sap.length < 6){
        Swal.fire('Mi Cuenta', 'La contraseña debe tener como minimo 6 caracteres.', 'warning');
      }else if(clave_sap !== confirmar_clave_sap){
        Swal.fire('Mi Cuenta', 'La contraseña y la confirmacion de contraseña no son iguales', 'warning');
      }else{
        dispatch( MiCuentaSAPClaveModificar(token, user.id_usuario, clave_sap, setIsLoading) );
      }
  }
  
  //obtener los permisos especiales
  user.permiso.filter(item => item.Permiso === 'USUARIO_SAP').map(filteredName => {
    isUsuarioSAP = '';
  });

  return (
    <>
      <Loading isLoading={isLoading} />

      <div className='row'>
        <h4 className="card-title mb-4">{user.nombre}</h4>

        <div className='col-md-4'>
        
          <div className="card mb-3 shadow-sm bg-dark bg-gradient">
            <div className="card-body">

              <ul className="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <a className="nav-link link-light d-flex mb-0 active" href="#nav-setting-tab-1" data-bs-toggle="tab">
                    <i className="fa-solid fa-address-card fa-2x"></i>&nbsp;&nbsp;Mis Datos</a>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <a className="nav-link link-light d-flex mb-0" href="#nav-setting-tab-2" data-bs-toggle="tab"> 
                    <i className="fa-solid fa-user fa-2x"></i>&nbsp;&nbsp;Mi Cuenta</a>
                </li>
                <li className={`nav-item ${isUsuarioSAP}`} data-bs-dismiss="offcanvas">
                  <a className="nav-link link-light d-flex mb-0" href="#nav-setting-tab-3" data-bs-toggle="tab">
                    <i className="fa-solid fa-user-tie fa-2x"></i>&nbsp;&nbsp;Mi Cuenta SAP</a>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <a className="nav-link link-light d-flex mb-0" href="#nav-setting-tab-4" data-bs-toggle="tab">
                    <i className="fa-solid fa-building-user fa-2x"></i>&nbsp;&nbsp;Mi Ubicacion</a>
                </li>
                <li className="nav-item" data-bs-dismiss="offcanvas">
                  <a className="nav-link link-light d-flex mb-0" href="#nav-setting-tab-5" data-bs-toggle="tab">
                    <i className="fa-solid fa-user-shield fa-2x"></i>&nbsp;&nbsp;Mis Permisos</a>
                </li>
              </ul>

            </div>
          </div>

        </div>
        <div className='col'>

          <div className='tab-content py-0 mb-0'>

            <div id='nav-setting-tab-1' className='tab-pane active'>
              <div className="card mb-3 shadow-sm">
                <div className="card-body">
                  <h4 className="card-title mb-4"><i className="fa-solid fa-id-card"></i>&nbsp;&nbsp;Mis datos</h4>
                  <hr/>
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Nombre</label>
                      <input type="text" className="form-control" placeholder="Nombre" name="nombre" value={nombre || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Apellido</label>
                      <input type="text" className="form-control" placeholder="Apellido" name="apellido" value={apellido || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Telefono</label>
                      <input type="text" className="form-control" placeholder="00000000" name="telefono" value={telefono || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Correo Electronico</label>
                      <input type="email" className="form-control" placeholder="nombre@dominio.com" name="correo" value={correo || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3 d-flex justify-content-end">
                      <button type="button" className="btn btn-success" onClick={handlerGuardarDato}><i className="fa-solid fa-paper-plane"></i>&nbsp;&nbsp;Guardar</button>
                    </div>
                  </form>
                </div>
              </div>    
            </div>  

            <div id='nav-setting-tab-2' className='tab-pane'>
              <div className="card mb-3 shadow-sm">
                <div className="card-body">
                  <h4 className="card-title mb-4"><i className="fa-solid fa-user"></i>&nbsp;&nbsp;Mi cuenta</h4>
                  <hr/>
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Usuario</label>
                      <input type="text" className="form-control" placeholder="Usuario" readOnly='readonly' name="usuario" value={usuario || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Contraseña</label>
                      <input type="password" className="form-control" placeholder="********" name="clave"  value={clave || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Confirmar Contraseña</label>
                      <input type="password" className="form-control" placeholder="********" name="confirmar_clave"  value={confirmar_clave || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3 d-flex justify-content-end">
                      <button type="button" className="btn btn-success" onClick={handlerGuardarCuenta}><i className="fa-solid fa-paper-plane"></i>&nbsp;&nbsp;Guardar</button>
                    </div>
                  </form>
                </div>
              </div>    
            </div>  

            <div id='nav-setting-tab-3' className='tab-pane'>
              <div className="card mb-3 shadow-sm">
                <div className="card-body">
                  <h4 className="card-title mb-4"><i className="fa-solid fa-user-tie"></i>&nbsp;&nbsp;Mi cuenta SAP</h4>
                  <hr/>
                  <form>
                    <div className="mb-3">
                      <label className="form-label">Usuario</label>
                      <input type="text" className="form-control" placeholder="Usuario" readOnly='readonly' name="usuario_sap" value={usuario_sap || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Contraseña</label>
                      <input type="password" className="form-control" placeholder="********" name="clave_sap"  value={clave_sap || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Confirmar Contraseña</label>
                      <input type="password" className="form-control" placeholder="********" name="confirmar_clave_sap"  value={confirmar_clave_sap || ''} onChange={ onInputChange }/>
                    </div>
                    <div className="mb-3 d-flex justify-content-end">
                      <button type="button" className="btn btn-success" onClick={handlerGuardarCuentaSAP}><i className="fa-solid fa-paper-plane"></i>&nbsp;&nbsp;Guardar</button>
                    </div>                    
                  </form>
                  <hr className='bg-danger'/>
                  <h4 className="card-title mb-4"><i className="fa-solid fa-database"></i>&nbsp;&nbsp;Compañias asociadas</h4>
                  <hr/>
                  <ul className="list-group">
                  {                    
                    dataSap?.map(sap => (                    
                      <li className="list-group-item" key={sap.Id_Region}>{sap.Compania}</li>
                    ))         
                  }
                  </ul>
                </div>
              </div>    
            </div>

            <div id='nav-setting-tab-4' className='tab-pane'>
              <div className="card mb-3 shadow-sm">
                <div className="card-body">
                  <h4 className="card-title mb-4"><i className="fa-solid fa-building"></i>&nbsp;&nbsp;Mi ubicacion</h4>
                  <hr/>
                  <nav aria-label="breadcrumb">
                  {
                    dataUbicacion?.map(ubicacion => (
                      <ol className="breadcrumb" key={ubicacion.Id_Registro}>
                        <li className="breadcrumb-item" key={`${ubicacion.Id_Registro}-${ubicacion.Id_Region}`}>Region: {ubicacion.Region}</li>
                        <li className="breadcrumb-item" key={`${ubicacion.Id_Registro}-${ubicacion.Id_Area}`}>Area: {ubicacion.Area}</li>
                        <li className="breadcrumb-item" key={`${ubicacion.Id_Registro}-${ubicacion.Id_Departamento}`}>Departamento: {ubicacion.Departamento}</li>
                      </ol>
                    ))
                  }
                  </nav>

                  <hr className='bg-danger'/>
                  <h4 className="card-title mb-4"><i className="fa-solid fa-shop"></i>&nbsp;&nbsp;Salas / Distribuidoras asociadas</h4>
                  <hr/>
                  <ul className="list-group">
                  {
                    dataSucursal?.map(sucursal => (
                      <li className="list-group-item" key={sucursal.Id}>{`${sucursal.Codigo} - ${sucursal.Nombre}`}</li>
                    ))
                  }
                  </ul>
                </div>
              </div>    
            </div>

            <div id='nav-setting-tab-5' className='tab-pane'>
              <div className="card mb-3 shadow-sm">
                <div className="card-body">
                  <h4 className="card-title mb-4"><i className="fa-solid fa-user-lock"></i>&nbsp;&nbsp;Mis permisos</h4>
                  <hr/>
                  <ul className="list-group">
                  {
                    dataPermiso?.map(permiso => (
                      <li className="list-group-item" key={permiso.Id}>{permiso.Nombre}</li>
                    ))
                  }                  
                  </ul>
                  <hr className='bg-danger'/>
                  <h4 className="card-title mb-4"><i className="fa-solid fa-user-shield"></i>&nbsp;&nbsp;Roles asociadas</h4>
                  <hr/>
                  <ul className="list-group">
                  {
                    dataPerfil?.map(perfil => (
                      <li className="list-group-item" key={perfil.Id}>{perfil.Descripcion}</li>
                    ))
                  }                  
                  </ul>
                </div>
              </div>    
            </div>  

          </div>
          
        </div>
      </div>

    </>
  )
}

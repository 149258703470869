import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { useSelector } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2'

import { useForm } from '../../../../hooks/useForm';

import { Loading } from '../../../ui/Loading';
import { InputFilterTable } from '../../../elements/InputFilterTable';
import { PaginationTable } from '../../../elements/PaginationTable';

export const DatoScreen = () => {

    const { token, user } = useSelector(state => state.auth);
    const [isLoading, setIsLoading] = useState(false);

    const [data, setData] = useState([]);
    const colSpanTable = 14;

    const { fecha_1, fecha_2, formState, onInputChange } = useForm({
        fecha_1: "", fecha_2: ""
    });

    /*Encabezado de la tabla*/
    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "Id"
            },
            {
                Header: "Codigo Cliente",
                accessor: "Id_Cliente"
            },
            {
                Header: "Nombre Cliente",
                accessor: "Cliente"
            },
            {
                Header: "Ruta",
                accessor: "ID_Ruta"
            },
            {
                Header: "Tipo",
                accessor: "Tipo",
                Cell: ({ value, cell }) => {
                    return <>{ (value == "E") ? "Entrada": "Salida"}</>
                }
            },
            {
                Header: "Codigo Piloto",
                accessor: "Id_Piloto"
            },
            {
                Header: "Nombre Piloto",
                accessor: "Piloto"
            },
            {
                Header: "Vehiculo",
                accessor: "ID_Vehiculo"
            },
            {
                Header: "Dato Vehiculo",
                accessor: "Marca",
                Cell: ({ value, cell }) => {
                    return <>{ value + " " + cell.row.values.Modelo + " " + cell.row.values.Anio }</>
                }
            },
            {
                Header: "Modelo",
                accessor: "Modelo"
            },
            {
                Header: "Año",
                accessor: "Anio"
            },
            {
                Header: "Ubicacion",
                accessor: "Latitud",
                Cell: ({ value, cell }) => {
                    return <div className='d-flex flex-row'>
                        <a type='button' className='btn btn-sm btn-success rounded-pill mx-1'
                            href={`https://maps.google.com/maps?&z=15&mrt=yp&q=${value}+${cell.row.values.Longitud}`}
                            target='_blank' title='Ubicacion'>
                            <span className='fa-solid fa-map-pin'></span>
                        </a>
                        <OverlayTrigger trigger="click" placement="top" overlay={
                            <Popover id={`${cell.row.values.Id}`}>
                                <Popover.Header as="h3">{`${cell.row.values.Id_Cliente}`}</Popover.Header>
                                <Popover.Body>
                                {`${value},${cell.row.values.Longitud}`}
                                </Popover.Body>
                            </Popover>
                            }>
                            <button type='button' 
                                className='btn btn-sm btn-secondary rounded-pill mx-1'
                                title="GPS">
                                <span className='fa-solid fa-eye'></span>
                            </button>
                        </OverlayTrigger>                        
                    </div>
                }
            },
            {
                Header: "Longitud",
                accessor: "Longitud"
            },
            {
                Header: "Fecha Ruta",
                accessor: "Fecha_Ruta"
            }
        ]
    , []);

    /*Hook para crear la tabla*/
    const table = useTable({ columns, data, initialState: {
        hiddenColumns: ['Id', 'Modelo', 'Anio', 'Longitud'],
        pageSize: 100,
        pageIndex: 0
      } }, useGlobalFilter, useSortBy, usePagination);

    /*El restructuracion de parametros basados del objeto table*/
    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = table;

    useEffect(() => {
        setIsLoading(false);
      }, [setIsLoading]);

    const handlerBuscar = (e) => {
        e.preventDefault();

        setIsLoading(true);
        const datos = {
            id_usuario: user.id_usuario, fecha_1: fecha_1, fecha_2: fecha_2
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( `${ process.env.REACT_APP_API_OPERACIONES }consulta/listar_gps_transporte`, datos )
        .then( response =>{
            let json = response.data;

            if(json.stt == "OK"){
                setData( JSON.parse(json.data) );
            }else{
                Swal.fire('Consulta', json.msg, 'error');                
            }
            setIsLoading(false);
        })
        .catch( ({response, message}) =>{ 
            setIsLoading(false);
            Swal.fire('Consulta', message, 'error');
        }); 
    }

  return (
    <>
        <Loading isLoading={isLoading} />
        <div className="container">
            <h3>Consulta GPS Transporte</h3>
            <hr/>

            <form className="row g-3">
                <div className='col-sm-6 col-md-5'>
                    <label className='form-label'>Fecha Inicio</label>
                    <input type='date' className='form-control form-sm'
                        name='fecha_1' value={ fecha_1 } onChange={ onInputChange }/>
                </div>
                <div className='col-sm-6 col-md-5'>
                    <label className='form-label'>Fecha Fin</label>
                    <input type='date' className='form-control form-sm'
                        name='fecha_2' value={ fecha_2 } onChange={ onInputChange }/>
                </div>
                <div className="col-sm-12 col-md-2 d-grid">
                    <label className="form-label">&nbsp;&nbsp;&nbsp;</label>
                    <button type="button" className="btn btn-primary btn-block" onClick={ handlerBuscar }><i className='fa-solid fa-search'></i></button>
                </div>
            </form>
            <hr/>

            <div className='table-responsive' style={{minHeight: '500px'}}>
            <table
                    className='table table-striped table-hover table-bordered'
                    { ...getTableProps }>
                    <thead>
                        <tr>
                            <th colSpan={ colSpanTable }>
                                <InputFilterTable 
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter} />
                            </th>
                        </tr>
                        {
                            headerGroups.map( headerGroup => (
                                <tr { ...headerGroup.getHeaderGroupProps() }>
                                    {
                                        headerGroup.headers.map( columns => 
                                        (
                                            <th { ...columns.getHeaderProps(columns.getSortByToggleProps()) }
                                                className={
                                                    columns.isSorted 
                                                        ? columns.isSortedDesc
                                                            ? "desc"
                                                            : "asc"
                                                        : ""
                                                }>
                                                {
                                                    columns.render("Header")
                                                }
                                            </th>
                                        ))
                                    }
                                </tr>
                            ) ) 
                        }
                    </thead>
                    <tbody { ...getTableBodyProps() }>
                        {
                            page.map( row => {

                                prepareRow( row )
                                return  (
                                    <tr { ...row.getRowProps() }>
                                        {
                                            row.cells.map( cell => 
                                            (
                                                <td { ...cell.getCellProps() }>
                                                    {
                                                        cell.render("Cell")
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                )
                            } )
                        }

                        <tr className='table-secondary'>
                            <td colSpan={ colSpanTable }>
                                <PaginationTable 
                                    canPreviousPage = {canPreviousPage}
                                    canNextPage = {canNextPage}
                                    pageOptions = {pageOptions}
                                    pageCount = {pageCount}
                                    gotoPage = {gotoPage}
                                    nextPage = {nextPage}
                                    previousPage = {previousPage}
                                    setPageSize = {setPageSize}
                                    pageIndex = {pageIndex}
                                    pageSize = {pageSize}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
  )
}



export const types = {
  login: '[acceso] login',
  logout: '[acceso] logout',
  state: '[acceso] estado',

  uiStartLoading: '[ui] start loading',
  uiFinishtLoading: '[ui] finish loading',

  vale: '[ui] Vales',
  valeForm: '[ui] Formulario Vale',
  valeDetalle: '[ui] Vale Detalle',
  valeDetalleAgregar: '[ui] Vale Agregar Detalle',
  comercial: '[ui] comercial',
  producto: '[ui] producto',
  devolucion: '[ui] devolucion',
  motivoEliminar: '[ui] motivo eliminar',
  motivoFinalizar: '[ui] motivo finalizar',
  valeEliminar: '[ui] eliminar vale',
  valeFinalizar: '[ui] finalziar vale',
  valeLiquidar: '[ui] liquidar vale',
  valeRuta: '[ui] ruta programada vale',
  cliente: '[ui] cliente',
  procesoTracking: '[ui] proceso tracking',
  valeMotivoSinLiquidar: '[ui] vale motivo sin liquidar',
  pedidoEliminar: '[ui] eliminar pedido',

  pedido: '[ui] Pedido',
  pedidoForm: '[ui] Formulario Pedido',
  pedidoDetalle: '[ui] Pedido Detalle',
  pedidoDetalleAgregar: '[ui] pedido Agregar Detalle',
  pedidoSAP: '[ui] pedido transferido a SAP',
  pedidoComentario: '[ui] pedido agregar comentario para transferencia a SAP',

  usuarioColaborador: '[ui] usuario colaborador',
  colaboradorXClienteTabla: '[ui] colaborador por cliente',
  colaboradorXClienteLinea: '[ui] colaborador por cliente agregar linea',
  colaboradorXClienteEstado: '[ui] colaborador por cliente remover linea',

  miDatoModificar: '[ui] mis datos actualizar',
  miCuentaClaveModificar: '[ui] actualziar mi clave de acceso',
  miCuentaSAPClaveModificar: '[ui] actualizar mi clave de acceso SAP'
}

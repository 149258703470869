import React, { useState } from 'react';
import {
  Grid, TextField, Select, MenuItem, Button, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';

const Movimientos = () => {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Estado de filtros
  const [filtroProducto, setFiltroProducto] = useState('');
  const [filtroTienda, setFiltroTienda] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const { token, user } = useSelector(state => state.auth); 
  const usuario = user.usuario; // Extrae el nombre de usuario
  // Estado de paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const setTiendas = ["TZ10", "TPRR", "TSCR", "TCSV", "TMIR", "TXLA"];

  // Filtra las tiendas según el nombre de usuario
  const tiendasFiltradas = setTiendas.filter(tienda => 
    usuario && tienda.includes(usuario)
  );

  const fetchDataMovimientos = async () => {
    setLoading(true);
    setError(null);

    try {
      const datos = {
        Token: "LJKNFUH2FWEJBAEFFFFFKJVGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
        Ambiente: "DESA",
        Dispositivo: {
          Marca: "SAMSUNG",
          Modelo: "S22002B",
          Version: "Android 18.125",
          McAdress: null,
          PowerStatus: "84%",
          WifiStatus: "Conected to iphone de Mauricio",
          GPSStatus: "ON",
          GPS: "321|-485|321"
        },
        Usr: {
          Codigo: "",
          UserNickName: usuario // Usa el nombre de usuario
        },
        DatosMetodo: {
          NombreMetodo: "ListaReportesSalas",
          Datos_Recibidos: `{"Vista":"Movimientos","FechaIni":"${fechaInicio}","Tienda":"${filtroTienda}","Producto":"${filtroProducto}"}`
        }
      };

      const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
      const response = await fetch(fetchDataUrl);

      if (!response.ok) {
        throw new Error('Error al obtener los datos');
      }

      const data = await response.json();
      if (data && data.datos_Generados) {
        const datosGeneradosLimpiados = typeof data.datos_Generados === 'string' 
          ? JSON.parse(data.datos_Generados) 
          : data.datos_Generados;

        setDatosGenerados(datosGeneradosLimpiados); 
        console.log(datosGeneradosLimpiados)
      } else {
        console.error("No se recibieron datos generados válidos:", data);
        setDatosGenerados([]);
      }

    } catch (error) {
      console.error("Error al obtener datos:", error);
      setError('Error al obtener los datos');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchDataMovimientos(); 
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage); 
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10)); 
    setPage(0); 
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({
      head: [['Salas', 'DocDate', 'ItemCode', 'ItemName', 'Quantity', 'Movimiento', 'Comments']],
      body: datosGenerados.map(row => [
        row.Warehouse,
        row.DocDate,
        row.ItemCode,
        row.ItemName,
        row.Quantity,
        row.Movimiento,
        row.Comments,
        row.NumeroMov,
        row.Usuario
      ])
    });
    doc.save('movimientos.pdf');
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(datosGenerados);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Movimientos');
    XLSX.writeFile(workbook, 'movimientos.xlsx');
  };

  return (
    <div>
      <h1>Movimientos Por Productos</h1>
      <hr />
      {/* Formulario de filtros */}
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={2}>
          <TextField
            id="filtroProducto"
            label="Producto (Código o Nombre)"
            value={filtroProducto}
            onChange={(e) => setFiltroProducto(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            label="Tienda"
            value={filtroTienda}
            onChange={(e) => setFiltroTienda(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Todas las tiendas</MenuItem>
            {/* Si hay tiendas filtradas, muéstralas; si no, muestra todas */}
            {(tiendasFiltradas.length > 0 ? tiendasFiltradas : setTiendas).map((tienda, index) => (
              <MenuItem key={index} value={tienda}>{tienda}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="fechaInicio"
            label="Fecha de Inicio"
            type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="fechaFin"
            label="Fecha de Fin"
            type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" onClick={handleSearch}>
            Buscar
          </Button>
          <Button variant="contained" onClick={exportToPDF} style={{ marginLeft: "4px", marginRight: "4px" }} color='warning'>
            PDF
          </Button>
          <Button variant="contained" onClick={exportToExcel} color='success'>
            Excel
          </Button>
        </Grid>
      </Grid>

      {/* Tabla de resultados */}
      {loading ? (
        <p>Cargando...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sala</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Codigo</TableCell>
                  <TableCell>Descripcion</TableCell>
                  <TableCell>Cantidad</TableCell>
                  <TableCell>Movimiento</TableCell>
                  <TableCell>Comentario</TableCell>
                  <TableCell>Numero de Movimiento</TableCell>
                  <TableCell>Usuario</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datosGenerados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.Warehouse}</TableCell>
                    <TableCell>{row.DocDate}</TableCell>
                    <TableCell>{row.ItemCode}</TableCell>
                    <TableCell>{row.ItemName}</TableCell>
                    <TableCell>{row.Quantity}</TableCell>
                    <TableCell>{row.Movimiento}</TableCell>
                    <TableCell>{row.Comments}</TableCell>
                    <TableCell>{row.NumeroMov}</TableCell> 
                    <TableCell>{row.Usuario}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={datosGenerados.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </div>
  );
};

export default Movimientos;

import React, { useEffect, ChangeEvent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from '../../../../../hooks/useForm';
import axios from 'axios';
import Swal from 'sweetalert2';

import { Loading } from '../../../../ui/Loading';
import { EncabezadoFormulario } from '../../../../elements/EncabezadoFormulario';
import { pedidoLoadForm, pedidoFechaComentario, pedidoDetalleLoad, pedidoDetalleAgregar, pedidoDetalleEliminar, pedidoDetalleCantidad } from '../../../../actions/comercial';
import { productoSelectorLoad, clienteSelectorLoad } from '../../../../actions/selector';

export const FormularioPedidoEditar = ({Id_Pedido, Cliente_Nombre, onBuscarFiltro, setIsOpenEditor} ) => {

    const dispatch = useDispatch();

    const { token, user } = useSelector(state => state.auth);
    const { pedidoEncabezado, pedidoDetalle, idPedidoDetalle } = useSelector(state => state.comercial);
    const { productoData, clienteData } = useSelector(state => state.selector);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ file, setFile ] = useState();
    const [ options, setOptions ] = useState([]);
    const [ optionsCliente, setOptionsCliente ] = useState([]);
    const [ producto, setProducto ] = useState(null);
    const [ selectorCliente, setSelectorCliente ] = useState(null);

    const { cliente, fecha_entrega, comentario, cantidad, hora_inicio, hora_fin, formState, onInputChange, setFormState, onResetForm } = useForm({
        cantidad: '', fecha_entrega: '', comentario: ''
    });
    
    useEffect(() => {
        dispatch( pedidoLoadForm( token, user.id_usuario, Id_Pedido, setIsLoading ) );
    }, []);

    useEffect(() => {
        if(pedidoEncabezado){
            pedidoEncabezado.map(({ ID_Cliente, Fecha_Entrega, Comentario, Hora_Inicio, Hora_Fin }) => {
                setFormState({
                    ...formState,
                    cliente: ID_Cliente,
                    fecha_entrega: Fecha_Entrega,
                    comentario: Comentario,
                    hora_inicio: Hora_Inicio,
                    hora_fin: Hora_Fin
                });
                dispatch( clienteSelectorLoad( token, user.id_usuario, setIsLoading ) );
                dispatch( productoSelectorLoad( token, user.id_usuario, 'N', ID_Cliente, setIsLoading ) );
            });
        }
    }, [pedidoEncabezado]);
    
    useEffect(() => {
        if(clienteData){
            let lista = [];
            clienteData?.map((item) => {
                lista.push({ values: item.Id, label: `${item.Id} - ${item.Nombre}` });
            });
            setOptionsCliente(lista);
            setSelectorCliente(formState.cliente);
        }
    }, [clienteData]);

    useEffect(() => {
        if(selectorCliente?.values){
            setFormState({
                ...formState,
                cliente: selectorCliente?.values
            });
        }
    }, [selectorCliente]);

    useEffect(() => {
        if(productoData){
            let lista = [];
            productoData?.map((item) => {
                lista.push({ values: item.Id, label: `${item.Id} - ${item.Nombre}` });
            });
            setOptions(lista);
        }
    }, [productoData]);
    
    useEffect(() => {
      if(idPedidoDetalle){
        dispatch( pedidoDetalleLoad( token, user.id_usuario, Id_Pedido, setIsLoading ) );
        setFormState({
            ...formState,
            cantidad: ''
        });
        onBuscarFiltro();
      }
    }, [idPedidoDetalle]);

    const onNuevoRegistro = (e) => {
        e.preventDefault();
        dispatch( pedidoDetalleAgregar(
            token, user.id_usuario, Id_Pedido, producto?.values, cantidad, setIsLoading
        ));
    }

    const onEliminarRegistro = (e, id_detalle) => {
        e.preventDefault();

        dispatch( pedidoDetalleEliminar(
            token, user.id_usuario, Id_Pedido, id_detalle, setIsLoading
        ));
    }

    const onCambiarCantidaRegistro = async(e, id_detalle, cantidad) => {
        e.preventDefault();

        const { value: cantidad_detalle } = await Swal.fire({
            title: 'Pedido',
            input: 'number',
            inputLabel: 'Ingrese cantidad a cambiar',
            inputPlaceholder: '0',
            inputValue: cantidad,
            showCancelButton: true,
            allowOutsideClick: false,
            inputValidator: (value) => {
                if (!value) {
                    return 'Debe ingresar una cantidad'
                }
                if (parseInt(value) < 1) {
                    return 'La cantidad ingresada debe ser mayor a cero'
                }
            }
        });
        if(cantidad_detalle){      
            dispatch( pedidoDetalleCantidad(
                token, user.id_usuario, Id_Pedido, id_detalle, cantidad_detalle, setIsLoading
            ));
        }
    }

    const onGuadarEncabezado = (e) => {
        e.preventDefault();

        dispatch( pedidoFechaComentario(
            token, user.id_usuario, Id_Pedido, cliente, fecha_entrega, comentario, hora_inicio, hora_fin, setIsLoading
        ));
    }

    const handlerFile = (e) => {
        if(e.target.files){
            setFile(e.target.files[0]);
        }
    }

    const onUploadFile = (e) => {
        e.preventDefault();

        if(!file){
            return;
        }
        setIsLoading(true);

        let extension = file.type.split("/");

        const datos = {
            id_usuario: user.id_usuario, id_pedido: Id_Pedido, imagen: file, extension: extension[1]
        }
        
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('extension', extension[1]);
        formData.append('id_usuario', user.id_usuario);
        formData.append('id_pedido', Id_Pedido);
        
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( `${ process.env.REACT_APP_API_COMERCIAL }upload/pedido_orden_compra`, formData )
        .then( response =>{
            let json = response.data;

            if(json.stt != 'OK'){
                Swal.fire('Pedido', json.msg, 'warning');
            }else{
                Swal.fire('Pedido', json.msg, 'info');
            }
            
            setIsLoading(false);
        })
        .catch( ({message}) =>{
            setIsLoading(false);
            Swal.fire('Pedido', message, 'error');            
        });
    }

    return (
    <>
        <Loading isLoading={isLoading} />
        <div className="container">
            <EncabezadoFormulario 
                Titulo = {`Modificar detalle pedido #${ Id_Pedido }`} 
                setIsOpen = { setIsOpenEditor }/>

            <div className="g-3">
                <div>
                    <div className='col-12'>
                        <label className="form-label">Cliente</label>
                        <Select isClearable 
                            defaultValue={ formState.cliente || 'Select' }
                            className='form-sm' id='cliente' name='cliente'
                            onChange={ setSelectorCliente } placeholder={ Cliente_Nombre }
                            options={ optionsCliente }/>
                    </div>
                </div>
                <div className='row my-3'>
                    <div className="col-md-3">
                        <label className="form-label">Fecha Entrega</label>
                        <input type='text' className="form-control form-control-sm text-center" 
                            name='fecha_entrega' value={ (fecha_entrega || '') } onChange={ onInputChange } />
                    </div>
                    <div className="col-md-9">
                        <label className="form-label">Comentario</label>
                        <input type='text' className="form-control form-control-sm text-center" 
                        name='comentario' value={ (comentario || '') } onChange={ onInputChange }/>
                    </div>
                </div>
                <div className='row my-3'>
                    <div className="col-md-3">
                        <label className="form-label">Hora Inicio</label>
                        <input type='time' className="form-control form-control-sm text-center" 
                            name='hora_inicio' value={ (hora_inicio || '') } onChange={ onInputChange } />
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Hora Fin</label>
                        <input type='time' className="form-control form-control-sm text-center" 
                        name='hora_fin' value={ (hora_fin || '') } onChange={ onInputChange }/>
                    </div>
                    <div className="col-12 col-md-1 mt-3 mt-md-0">
                        <label className="form-label">&nbsp;&nbsp;</label>
                        <button type="button" 
                            className="btn btn-success btn-sm btn-block rounded-pill" 
                            style={{width: '100%'}}
                            onClick={ onGuadarEncabezado }>
                            <i className="fa-solid fa-save"></i>
                        </button>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="g-3">
                <div className='row'>
                    <div className="col">
                        <label className="form-label">Cargar orden de compra</label>
                        <input type='file' className="form-control form-control-sm" name='archivo_oc' onChange={ handlerFile }/>
                    </div>
                    <div className='col-12 col-md-1 mt-3 mt-md-0'>
                        <label className="form-label">&nbsp;&nbsp;</label>
                        <button type='button' 
                            className='btn btn-success btn-sm btn-block rounded-pill' 
                            style={{width: '100%'}} 
                            title='Agregar registro al detalle'
                            onClick={ onUploadFile }>
                            <span className='fa-solid fa-upload'></span>
                        </button>
                    </div>
                </div>
            </div>

            <hr className='bg-danger'/>

            <form className="g-3">
                <div className='row'>
                    <div className='col-8 col-md-9'>
                        <Select isClearable
                            className='form-sm' id='producto' name='producto'
                            onChange={ setProducto } placeholder={ 'Seleccione un producto' }
                            options={ options }/>
                    </div>
                    <div className='col-4 col-md-2 mt-3 mt-md-0'>
                        <input type='text' className='form-control form-control-md text-center' placeholder='Cantidad' 
                            name='cantidad' value={ cantidad } onChange={ onInputChange }/>
                    </div>
                    <div className='col-12 col-md-1 mt-3 mt-md-0'>
                        <button type='button' 
                                className='btn btn-success btn-sm btn-block rounded-pill' 
                                style={{width: '100%'}} 
                                title='Agregar registro al detalle'
                                onClick={ onNuevoRegistro }>
                            <span className='fa-solid fa-add'></span>
                        </button>
                    </div>
                </div>
            </form>

            <hr className='bg-danger'/>

            <div className="table-responsive">
                <table className='table table-sm table-striped table-bordered'>
                    <thead>
                        <tr>
                            <th>Accion&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th>Codigo</th>
                            <th>Producto</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead> 
                    <tbody>
                    {
                    pedidoDetalle?.map(({ID_Detalle, ID_Producto, Producto, Cantidad}) => (
                        <tr key={ID_Detalle}>
                            <td>
                                <button type='button' className='btn btn-sm btn-danger rounded-pill mx-1'
                                    onClick={ (e) => onEliminarRegistro(e, ID_Detalle) }>
                                    <span className='fa-solid fa-trash'></span>
                                </button>
                                <button type='button' className='btn btn-sm btn-primary rounded-pill mx-1'
                                    onClick={ (e) => onCambiarCantidaRegistro(e, ID_Detalle, Cantidad) }>
                                    <span className='fa-solid fa-edit'></span>
                                </button>
                            </td>
                            <td>{ID_Producto}</td>
                            <td>{Producto}</td>
                            <td>{Cantidad}</td>
                        </tr>  
                        ))
                    }  
                    </tbody>               
                </table>
            </div>
        </div>
    </>
    )
}
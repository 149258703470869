import axios from 'axios';
import Swal from 'sweetalert2'

import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";

export const productoSelectorLoad = (token, id_usuario, precios, id_cliente, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario, precios: precios, id_cliente: id_cliente }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'ventas/listar_productos', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Producto', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato =  JSON.parse(json.data);
                    dispatch({ 
                        type: types.producto, 
                        selector: dato
                    });                    
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Producto', message, 'error');
                }
            });       
    }
}

export const devolucionSelectorLoad = (token, id_usuario, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'selector/listar_devolucion', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Devolucion', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato =  JSON.parse(json.data);
                    dispatch({ 
                        type: types.devolucion, 
                        selector: dato
                    }); 
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Devolucion', message, 'error');
                }
            });       
    }
}

export const motivoEliminarLoad = (token, id_usuario, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'selector/listar_motivo_eliminacion', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Motivo', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato =  JSON.parse(json.data);
                    dispatch({ 
                        type: types.motivoEliminar, 
                        selector: dato
                    }); 
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Motivo', message, 'error');
                }
            });      
    }
}

export const motivoFinalizarLoad = (token, id_usuario, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'selector/listar_motivo_finalizacion', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Motivo', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato =  JSON.parse(json.data);
                    dispatch({ 
                        type: types.motivoFinalizar, 
                        selector: dato
                    }); 
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Motivo', message, 'error');
                }
            });       
    }
}

export const clienteSelectorLoad = (token, id_usuario, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'ventas/listar_clientes', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Cliente', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato =  JSON.parse(json.data);
                    dispatch({ 
                        type: types.cliente, 
                        selector: dato
                    }); 
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Cliente', message, 'error');
                }
            });        
    }
}

export const procesoTracikngSelectorLoad = (token, id_usuario, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'selector/listar_proceso_tracking', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Tracking', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato =  JSON.parse(json.data);
                    dispatch({ 
                        type: types.procesoTracking, 
                        selector: dato
                    }); 
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Tracking', message, 'error');
                }
            });       
    }
}

export const colaboradorSelectorLoad = (token, id_usuario, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.get( process.env.REACT_APP_API_CONFIGURACION + 'consulta/listar_usuario_activo/' + id_usuario)
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Colaborador', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato =  JSON.parse(json.data);
                    dispatch({ 
                        type: types.usuarioColaborador, 
                        selector: dato
                    }); 
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Colaborador', message, 'error');
                }
            });        
    }
}
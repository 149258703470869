import React, { useState } from 'react';
import { Button, TextField, Select, MenuItem } from '@mui/material';

const CrearHelado = () => {
  const [codigo, setCodigo] = useState('');
  const [nombre, setNombre] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [estado, setEstado] = useState('');
  const [ptpr, setPtpr] = useState('');
  const [fecha, setFecha] = useState(new Date().toISOString().split('T')[0]); // Fecha actual en formato yyyy-mm-dd 
  const [datosGenerados, setDatosGenerados] = useState([]);


  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaCRUDHelados",
        Datos_Recibidos: JSON.stringify({
          Tabla: "hdd.hl_helados",
          TipoOperacion: "Insert",
          hl_nombre: "Helado de Fresa",
          hl_descripcion: "Helado con sabor a fresa natural",
          hl_estado: 1,
          hl_codus_crea: 101,
          hl_codus_mod: 101,
          hl_ptpr: "PT-123456",
          hl_fecha: "2024-09-30"
        })
      }
    };
  
    console.log(datos);
  
    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
    
    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
        console.log(datosGeneradosDecodificados);
        setDatosGenerados(datosGeneradosDecodificados);
      })
      .catch(error => console.error('Error:', error));
  };
  
  

  const handleGuardar = () => {
    // Aquí es donde normalmente enviarías los datos a la API o base de datos
    const nuevoHelado = {
      hl_codigo: codigo,
      hl_nombre: nombre,
      hl_descripcion: descripcion,
      hl_estado: estado,
      hl_codus_crea: 'usuario_creador',  // Este valor lo puedes manejar según tu lógica de usuario
      hl_codus_mod: 'usuario_modificador',  // Lo mismo aquí
      hl_ptpr: ptpr,
      hl_fecha: fecha
    };
    console.log('Nuevo Helado:', nuevoHelado);

    // Realiza aquí la petición para guardar los datos
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Creación de Helado</h1>

      <div style={{ marginBottom: '20px' }}>
        <TextField
          label="Código del Helado"
          value={codigo}
          onChange={(e) => setCodigo(e.target.value)}
          fullWidth
        />
      </div>

      <div style={{ marginBottom: '20px' }}>
        <TextField
          label="Nombre del Helado"
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          fullWidth
        />
      </div>

      <div style={{ marginBottom: '20px' }}>
        <TextField
          label="Descripción"
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          fullWidth
        />
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Select
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Selecciona un estado</MenuItem>
          <MenuItem value="Activo">Activo</MenuItem>
          <MenuItem value="Inactivo">Inactivo</MenuItem>
        </Select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <TextField
          label="Precio (PTPR)"
          type="number"
          value={ptpr}
          onChange={(e) => setPtpr(e.target.value)}
          fullWidth
        />
      </div>

      <div style={{ marginBottom: '20px' }}>
        <TextField
          label="Fecha"
          type="date"
          value={fecha}
          onChange={(e) => setFecha(e.target.value)}
          fullWidth
        />
      </div>

      <Button variant="contained" onClick={fetchData} fullWidth>
        Guardar
      </Button>
    </div>
  );
};

export default CrearHelado;
    
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import axios from 'axios';
import Swal from 'sweetalert2'

import { useForm } from '../../../../../hooks/useForm';

import { Loading } from '../../../../ui/Loading';
import { InputFilterTable } from '../../../../elements/InputFilterTable';
import { PaginationTable } from '../../../../elements/PaginationTable';
import { ExporXLS } from '../../../../elements/ExporXLS';

export const MarcajeScreen = () => {

  const { token, user } = useSelector(state => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const { fecha_inicio, fecha_fin, formState, onInputChange } = useForm({});

  const [data, setData] = useState([]);

  const colSpanTable = 10;

  /*Encabezado de la tabla*/
  const columns = useMemo(
    () => [
        {
          Header: "#",
          accessor: "ID_Registro"
        },
        {
          Header: "Fecha",
          accessor: "Fecha"
        },
        {
          Header: "Hora",
          accessor: "Hora"
        },
        {
          Header: "Colaborador",
          accessor: "Colaborador"
        },
        {
          Header: "Codigo Cliente",
          accessor: "ID_Cliente"
        },
        {
          Header: "Cliente",
          accessor: "Cliente"
        },
        {
          Header: "Latitud",
          accessor: "Latitud"
        },
        {
          Header: "Longitud",
          accessor: "Longitud"
        },
        {
          Header: "Estado",
          accessor: "Estado"
        },
        {
          Header: "Motivo",
          accessor: "Motivo"
        }
    ]
, []);

/*Hook para crear la tabla*/
const table = useTable({ columns, data, initialState: {
    hiddenColumns: [],
    pageSize: 50,
    pageIndex: 0
  } }, useGlobalFilter, useSortBy, usePagination);

/*El restructuracion de parametros basados del objeto table*/
const {
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
} = table;

  useEffect(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const handlerBuscar = (e) => {
      e.preventDefault();

      if(!fecha_inicio){
        Swal.fire('Marcaje', 'Debe ingresar una fecha inicio.', 'warning');
        return;
      }
      if(fecha_inicio.length !== 10){
          Swal.fire('Marcaje', 'Debe ingresar una fecha inicio.', 'warning');
          return;
      }
      if(!fecha_fin){
        Swal.fire('Marcaje', 'Debe ingresar una fecha fin.', 'warning');
        return;
      }
      if(fecha_fin.length !== 10){
          Swal.fire('Marcaje', 'Debe ingresar una fecha fin.', 'warning');
          return;
      }

      setIsLoading(true);
      setData( [] );
      const datos = {
        id_usuario: user.id_usuario, fecha_1: fecha_inicio, fecha_2: fecha_fin
      }
      axios.defaults.headers.common['Authorization'] = "Bearer " + token;
      axios.post( `${ process.env.REACT_APP_API_COMERCIAL }consulta/listar_marcaje`, datos )
      .then( response =>{
          let json = response.data;

          if(json.stt == "OK"){
              setData( JSON.parse( json.data ) );
          }else{
              Swal.fire('Consulta', json.msg, 'error');                
          }
          setIsLoading(false);
      })
      .catch( ({response, message}) =>{ 
          setIsLoading(false);
          Swal.fire('Consulta', message, 'error');
      }); 
  }

  return (
    <>
        <Loading isLoading={isLoading} />
        <div className="container">
          <h3>
            Marcaje entrada y salida
            <ExporXLS DataTabla = { data } ArchivoNombre = { "Marcaje entrada y salida" }/>
          </h3>
          <hr/>

          <form className="row g-3">
            <div className="col-sm-6 col-md-5">
              <label className="form-label">Fecha Inicio</label>
              <input type="date" className="form-control form-sm text-center" name='fecha_inicio' value={ fecha_inicio } onChange={ onInputChange }/>
            </div>
            <div className="col-sm-6 col-md-5">
              <label className="form-label">Fecha Fin</label>
              <input type="date" className="form-control form-sm text-center" name='fecha_fin' value={ fecha_fin } onChange={ onInputChange }/>
            </div>
            <div className="col-sm-12 col-md-2 d-grid">
              <label className="form-label">&nbsp;&nbsp;&nbsp;</label>
              <button type="button" className="btn btn-primary btn-block" onClick={ handlerBuscar }><i className='fa-solid fa-search'></i></button>
            </div>
          </form>
          <hr/>
            
          <div className='table-responsive' style={{minHeight: '500px'}}>
            <table
              className='table table-striped table-hover table-bordered'
              { ...getTableProps }>
              <thead>
                <tr>
                  <th colSpan={ colSpanTable }>
                    <InputFilterTable 
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter} />
                  </th>
                </tr>
                {
                  headerGroups.map( headerGroup => (
                    <tr { ...headerGroup.getHeaderGroupProps() }>
                      {
                        headerGroup.headers.map( columns => 
                        (
                          <th { ...columns.getHeaderProps(columns.getSortByToggleProps()) }
                            className={
                              columns.isSorted 
                                ? columns.isSortedDesc
                                  ? "desc"
                                  : "asc"
                                : ""
                            }>
                            {
                              columns.render("Header")
                            }
                          </th>
                        ))
                      }
                    </tr>
                  ) ) 
                }
              </thead>
              <tbody { ...getTableBodyProps() }>
                {
                  page.map( row => {

                    prepareRow( row )
                    return  (
                      <tr { ...row.getRowProps() }>
                        {
                          row.cells.map( cell => 
                          (
                            <td { ...cell.getCellProps() }>
                              {
                                cell.render("Cell")
                              }
                            </td>
                          ))
                        }
                      </tr>
                    )
                  } )
                }

                <tr className='table-secondary'>
                  <td colSpan={ colSpanTable }>
                    <PaginationTable 
                      canPreviousPage = {canPreviousPage}
                      canNextPage = {canNextPage}
                      pageOptions = {pageOptions}
                      pageCount = {pageCount}
                      gotoPage = {gotoPage}
                      nextPage = {nextPage}
                      previousPage = {previousPage}
                      setPageSize = {setPageSize}
                      pageIndex = {pageIndex}
                      pageSize = {pageSize}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
  )
}

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2'

import { CardAppStore } from '../card/CardAppStore';

export const AppStoreList = ({
    setIsLoading
}) => {

    let index = 0;
    const [appstore, setAppstore] = useState([]);
    const { token, user } = useSelector(state => state.auth);

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( `${ process.env.REACT_APP_API_APPSTORE }app/listar_apps`, { id_usuario: user.id_usuario } )
        .then( response =>{
            let json = response.data;

            if(json.stt == "OK"){
                setAppstore( JSON.parse(json.data) );
            }else{
                Swal.fire('AppStore', json.msg, 'error');                
            }
            setIsLoading(false);
        })
        .catch( ({message}) =>{
            setIsLoading(false);
            Swal.fire('AppStore', message, 'error');            
        });
    }, []);
    
    return (
        <div className='card-columns animate__animated animate__fideIn'>
            {
                appstore.map( fila => (
                    <CardAppStore
                        key = { index++ }
                        { ...fila }
                        setIsLoading = { setIsLoading }
                    />
                ))
            }
        </div>
    )
}

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Swal from 'sweetalert2';

import { Loading } from '../../../ui/Loading';
import { EstadoRegistro } from '../../../elements/EstadoRegistro';
import { InputFilterTable } from '../../../elements/InputFilterTable';
import { PaginationTable } from '../../../elements/PaginationTable';
import { EncabezadoTablaBuscador } from '../../../elements/EncabezadoTablaBuscador';
import { IndicadorBodega } from '../../../elements/IndicadorBodega';
import { AccionValePermisos } from './AccionValePermisos';

import { valeLoad, valeEliminar, valeFinalizar, valeLiquidar, valeRuta } from '../../../actions/comercial';
import { motivoEliminarLoad, motivoFinalizarLoad } from '../../../actions/selector';
import { useForm } from '../../../../hooks/useForm';
import { BodyFiltroVale } from './BodyFiltroVale';
import { FormularioVale } from './FormularioVale';
import { FormularioValeEditar } from './FormularioValeEditar';

export const ValeScreen = () => {
    const dispatch = useDispatch();
    
    const [ isOpenEliminar, setIsOpenEliminar ] = useState(false);
    const [ isOpenFinalizar, setIsOpenFinalizar ] = useState(false);
    const [ isOpenRuta, setIsOpenRuta ] = useState(false);
    const [ isOpenVisor, setIsOpenVisor ] = useState(false);
    const [ isOpenEditor, setIsOpenEditor ] = useState(false);
    const [ isOpenLiquidar, setIsOpenLiquidar ] = useState(false);
    const selectorEliminar = useRef();
    const selectorFinalizar = useRef();
    const { fecha_ruta, comentario, onInputChange, onResetForm } = useForm({ 
        fecha_ruta: moment().add(1, 'days').format('YYYY-MM-DD'),
        comentario: ''
    });
    const [ idDetalle, setIdDetalle ] = useState(-1);
    const { token, user } = useSelector(state => state.auth);
    const { valeTabla, idVale, motivoSinLiquidar } = useSelector(state => state.comercial);
    const { motivoEliminarData, motivoFinalizarData } = useSelector(state => state.selector);
    const [ isLoading, setIsLoading ] = useState(false);    
    const [data, setData] = useState([]);    

    const { id_rastreo } = useParams();
    const [ formFiltro, setFormFiltro ] = useState({
        vale: '', 
        fecha_carga_1: '', 
        fecha_carga_2: '', 
        fecha_ruta_1: '', 
        fecha_ruta_2: '', 
        cliente: '-1', 
        rastreo: (id_rastreo? id_rastreo: '-1')
    });
    const colSpanTable = 12;

    /*Encabezado de la tabla*/
    const columns = useMemo(
        () => [
            {
                Header: "#",
                accessor: "ID_Devolucion",
                Cell: ({value, cell}) => {
                    return <AccionValePermisos 
                        Id = { value }
                        Id_Vale = { cell.row.values.ID_Vale }   
                        Cliente = { cell.row.values.Cliente }
                        Id_Rastreo = { cell.row.values.Id_Rastreo }  
                        Fecha = { cell.row.values.Fecha }
                        Fecha_Ruta = { cell.row.values.Fecha_Ruta }
                        handlerEliminar = { handlerEliminar }
                        handlerFinalizar = { handlerFinalizar }
                        handlerLiquidar = { handlerLiquidar }
                        handlerRuta = { handlerRuta }                        
                        setIsLoading = { setIsLoading }
                        setIdDetalle = { setIdDetalle }
                        setIsOpenVisor = { setIsOpenVisor }
                        setIsOpenEditor = { setIsOpenEditor }/>
                }
            },
            {
                Header: "No. Vale",
                accessor: "ID_Vale"
            },
            {
                Header: "Colaborador",
                accessor: "Colaborador"
            },
            {
                Header: "Telefono",
                accessor: "Telefono"
            },
            {
                Header: "Codigo Cliente",
                accessor: "ID_Cliente"
            },
            {
                Header: "Cliente",
                accessor: "Cliente"
            },
            {
                Header: "Observacion",
                accessor: "Observacion"
            },
            {
                Header: "Fecha Carga",
                accessor: "Fecha",
                Cell: ({ value, cell }) => {
                    return <>
                        {`${value} ${cell.row.values.Hora}`}
                    </>
                }
            },
            {
                Header: "Hora",
                accessor: "Hora"
            },
            {
                Header: "Fecha Validado",
                accessor: "Fecha_Validado"
            },
            {
                Header: "Fecha Ruta",
                accessor: "Fecha_Ruta"
            },            
            {
                Header: "Tracking",
                accessor: "Tracking"
            },
            {
                Header: "Bodega",
                accessor: "Bodega",
                Cell: ({ value, cell }) => {
                    return <IndicadorBodega Bodega ={value}/>
                }
            },
            {
                Header: "Estado",
                accessor: "Estado",
                Cell: ({ value, cell }) => {
                    return <EstadoRegistro Estado ={value}/>
                }
            },
            {
                Header: "Id Rastreo",
                accessor: "Id_Rastreo"
            }
        ]
    , []);

    /*Hook para crear la tabla*/
    const table = useTable({ columns, data, initialState: {
        hiddenColumns: ['Hora', 'Estado', 'Id_Rastreo'],
        pageSize: 50,
        pageIndex: 0
      } }, useGlobalFilter, useSortBy, usePagination);

    /*El restructuracion de parametros basados del objeto table*/
    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = table;

    useEffect(() => {
        dispatch( motivoEliminarLoad(token, user.id_usuario, setIsLoading) );
        dispatch( motivoFinalizarLoad(token, user.id_usuario, setIsLoading) );
    }, []);

    useEffect(() => {
        if(motivoEliminarData && motivoFinalizarData){
            onBuscarFiltro();
        }
    }, [motivoEliminarData, motivoFinalizarData]);

    useEffect(() => {
      if(idVale){
        setIsOpenEliminar(false);
        setIsOpenFinalizar(false);
        setIsOpenRuta(false);
        setIsOpenVisor(false);
        setIsOpenEditor(false);
        setIsOpenLiquidar(false);
        const tablaData = data.filter((row) => row.ID_Devolucion !== idVale);
        setData( tablaData );
      }
    }, [idVale]);   
    
    useEffect(() => {
        if(motivoSinLiquidar){
            const data = JSON.parse(motivoSinLiquidar.data);
            if(data.length > 0){
                Swal.fire('Vale', data[0].Detalle, 'info');
            }else{
                Swal.fire('Vale', 'No hay motivo definido', 'info');
            }
        }
    }, [motivoSinLiquidar]);
    
    useMemo(() => {
        if(valeTabla){
            setData( valeTabla ); 
        }
    }, [valeTabla]);

    /*Eventos para botones*/
    const handlerEliminar = (e, id) =>{
        e.preventDefault();
        setIdDetalle(id);
        setIsOpenEliminar(true);
    }
    const handlerFinalizar = (e, id) =>{
        e.preventDefault();
        setIdDetalle(id);
        setIsOpenFinalizar(true);
    }
    const handlerLiquidar = (e, id) =>{
        e.preventDefault();
        setIdDetalle(id);
        setIsOpenLiquidar(true);
    }

    const handlerRuta = (e, id) => {
        e.preventDefault();
        setIdDetalle(id);
        setIsOpenRuta(true);
    }

    const onEliminarVale = (e) =>{
        e.preventDefault();
        
        dispatch( valeEliminar(token, user.id_usuario, idDetalle, selectorEliminar.current.value, 3, setIsLoading) );
        onResetForm();
    }

    const onFinalizarVale = (e) =>{
        e.preventDefault();
        
        dispatch( valeFinalizar(token, user.id_usuario, idDetalle, selectorFinalizar.current.value, setIsLoading) );
        onResetForm();
    }

    const onLiquidarVale = (e) =>{
        e.preventDefault();
        
        dispatch( valeLiquidar(token, user.id_usuario, idDetalle, comentario, setIsLoading) );
        onResetForm();
    }

    const onRutaVale = (e) =>{
        e.preventDefault();
        
        dispatch( valeRuta(token, user.id_usuario, idDetalle, fecha_ruta, setIsLoading) );
        onResetForm();
    }

    const onBuscarFiltro = (e, setIsOpenFiltro) =>{
        if(e){
            e.preventDefault();
            setIsOpenFiltro(false);
        }
        
        dispatch( valeLoad( token, user.id_usuario, -1, formFiltro.rastreo, 0, 
            formFiltro.vale, formFiltro.cliente, formFiltro.fecha_carga_1, formFiltro.fecha_carga_2, 
            formFiltro.fecha_ruta_1, formFiltro.fecha_ruta_2, setIsLoading ) );
    }

    return (
    <>
        <Loading isLoading={isLoading} />
        <div className="container">
            <EncabezadoTablaBuscador Titulo='Lista de vales' 
                BodyModal = { <BodyFiltroVale 
                    setIsLoading={ setIsLoading } 
                    setFormFiltro = { setFormFiltro }
                    formFiltro = { formFiltro }/> }
                    onBuscarFiltro = { onBuscarFiltro }
                    DataTabla = { data }/>
            <div className='table-responsive' style={{minHeight: '500px'}}>
                <table
                    className='table table-striped table-hover table-bordered'
                    { ...getTableProps }>
                    <thead>
                        <tr>
                            <th colSpan={ colSpanTable }>
                                <InputFilterTable 
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter} />
                            </th>
                        </tr>
                        {
                            headerGroups.map( headerGroup => (
                                <tr { ...headerGroup.getHeaderGroupProps() }>
                                    {
                                        headerGroup.headers.map( columns => 
                                        (
                                            <th { ...columns.getHeaderProps(columns.getSortByToggleProps()) }
                                                className={
                                                    columns.isSorted 
                                                        ? columns.isSortedDesc
                                                            ? "desc"
                                                            : "asc"
                                                        : ""
                                                }>
                                                {
                                                    columns.render("Header")
                                                }
                                            </th>
                                        ))
                                    }
                                </tr>
                            ) ) 
                        }
                    </thead>
                    <tbody { ...getTableBodyProps() }>
                        {
                            page.map( row => {

                                prepareRow( row )
                                return  (
                                    <tr { ...row.getRowProps() }>
                                        {
                                            row.cells.map( cell => 
                                            (
                                                <td { ...cell.getCellProps() }>
                                                    {
                                                        cell.render("Cell")
                                                    }
                                                </td>
                                            ))
                                        }
                                    </tr>
                                )
                            } )
                        }

                        <tr className='table-secondary'>
                            <td colSpan={ colSpanTable }>
                                <PaginationTable 
                                    canPreviousPage = {canPreviousPage}
                                    canNextPage = {canNextPage}
                                    pageOptions = {pageOptions}
                                    pageCount = {pageCount}
                                    gotoPage = {gotoPage}
                                    nextPage = {nextPage}
                                    previousPage = {previousPage}
                                    setPageSize = {setPageSize}
                                    pageIndex = {pageIndex}
                                    pageSize = {pageSize}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
                        
        <Modal isOpen={isOpenEliminar}>
            <ModalHeader>Vale #{idDetalle}</ModalHeader>
            <ModalBody>
                <label>Selecione un motivo</label>
                <select className='form-select' ref={selectorEliminar}>
                    {
                        motivoEliminarData?.map((item)=>(
                            <option key={item.Id} value={item.Id}>{item.Nombre}</option>
                        ))
                    }
                </select>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-primary" onClick={onEliminarVale}>Enviar</button>
                <button type="button" className="btn btn-secondary" onClick={(e)=>setIsOpenEliminar(false)}>Cerrar</button>                    
            </ModalFooter>
        </Modal>

        <Modal isOpen={isOpenFinalizar}>
            <ModalHeader>Vale #{idDetalle}</ModalHeader>
            <ModalBody>
                <label>Selecione un motivo</label>
                <select className='form-select' ref={selectorFinalizar}>
                    {
                        motivoFinalizarData?.map((item)=>(
                            <option key={item.Id} value={item.Id}>{item.Nombre}</option>
                        ))
                    }
                </select>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-primary" onClick={onFinalizarVale}>Enviar</button>
                <button type="button" className="btn btn-secondary" onClick={(e)=>setIsOpenFinalizar(false)}>Cerrar</button>                    
            </ModalFooter>
        </Modal> 

        <Modal isOpen={isOpenLiquidar}>
            <ModalHeader>Vale #{idDetalle}</ModalHeader>
            <ModalBody>
                <label>Ingrese un comentario</label>
                <textarea 
                    className='form-control' 
                    rows='3' 
                    name='comentario' 
                    value={comentario}
                    onChange={ onInputChange }/>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-primary" onClick={onLiquidarVale}>Enviar</button>
                <button type="button" className="btn btn-secondary" onClick={(e)=>setIsOpenLiquidar(false)}>Cerrar</button>                    
            </ModalFooter>
        </Modal> 

        <Modal isOpen={isOpenRuta}>
            <ModalHeader>Vale #{idDetalle}</ModalHeader>
            <ModalBody>
                <label>Fecha programada</label>
                <input type='date' className='form-control text-center'
                    name='fecha_ruta' 
                    value={ fecha_ruta } 
                    onChange={ onInputChange }/>
            </ModalBody>
            <ModalFooter>
                <button type="button" className="btn btn-primary" onClick={onRutaVale}>Enviar</button>
                <button type="button" className="btn btn-secondary" onClick={(e)=>setIsOpenRuta(false)}>Cerrar</button>                    
            </ModalFooter>
        </Modal>

        <Modal isOpen={isOpenVisor} fullscreen>
            <ModalBody>
                <FormularioVale Id_Devolucion = { idDetalle } setIsOpenVisor = { setIsOpenVisor }/>
            </ModalBody>
        </Modal>

        <Modal isOpen={isOpenEditor} fullscreen>
            <ModalBody>
                <FormularioValeEditar Id_Devolucion = { idDetalle } setIsOpenEditor = { setIsOpenEditor } />
            </ModalBody>
        </Modal>    
    </>
    )
}
import axios from 'axios';
import Swal from 'sweetalert2'

import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";

export const valeLoad = (token, id_usuario, id_devolucion, id_rastreo, sap, vale, cliente, 
        fecha_carga_1, fecha_carga_2, fecha_ruta_1, fecha_ruta_2, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        
        const datos = { id_usuario: id_usuario, id_devolucion: id_devolucion, id_rastreo: id_rastreo, sap: sap, vale:vale,
                        cliente: cliente, fecha_carga_1: fecha_carga_1, fecha_carga_2: fecha_carga_2, 
                        fecha_ruta_1: fecha_ruta_1, fecha_ruta_2: fecha_ruta_2 }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'consulta/listar_vales', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = JSON.parse(json.data);  
                    //console.log(dato);                  
                    dispatch({ 
                        type: types.vale, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });        
    }
}

export const valeDetalleLoad = (token, id_usuario, id_devolucion, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario, id_devolucion: id_devolucion }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'consulta/listar_detalle_vale', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = JSON.parse(json.data);  
                    //console.log(dato);                  
                    dispatch({ 
                        type: types.valeDetalle, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });        
    }
}

export const valeLoadForm = (token, id_usuario, id_devolucion, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario, id_devolucion: id_devolucion }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'consulta/vale_dato', datos )
            .then( response => {
                let json = response.data;
                
                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = {
                        encabezado: JSON.parse(json.data.encabezado),
                        detalle: JSON.parse(json.data.detalle)
                    }
                    console.log(dato);
                    dispatch({ 
                        type: types.valeForm, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });        
    }
}

export const valeDetalleAgregar = (token, id_usuario, id_devolucion, producto, cantidad,
                                    vencimiento, lote, motivo, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );

        /*Validando los datos de parametros*/
        if(!producto){
            Swal.fire('Vale', 'Debe seleccionar un producto', 'warning');
            return;
        }
        if(producto == '-1'){
            Swal.fire('Vale', 'Debe seleccionar un producto', 'warning');
            return;
        }
        if(motivo == '-1'){
            Swal.fire('Vale', 'Debe seleccionar un motivo', 'warning');
            return;
        }
        if(cantidad.length < 1){
            Swal.fire('Vale', 'Debe ingresar una cantidad', 'warning');
            return;
        }
        if(parseInt(cantidad) < 1){
            Swal.fire('Vale', 'La cantidad ingresada debe ser mayor a cero', 'warning');
            return;
        }

        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_devolucion: id_devolucion,
            producto: producto, cantidad: cantidad, vencimiento: vencimiento,
            lote: lote, id_motivo: motivo
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/crear/agregar_vale_detalle', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.valeDetalleAgregar, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });
    }
}

export const valeDetalleEliminar = (token, id_usuario, id_devolucion, id_detalle, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_devolucion: id_devolucion, id_detalle: id_detalle
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/eliminar/vale_detalle', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.valeDetalleAgregar, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });
    }
}

export const valeDetalleCantidad = (token, id_usuario, id_devolucion, id_detalle, cantidad, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_devolucion: id_devolucion, id_detalle: id_detalle, cantidad: cantidad
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/cambiar/vale_detalle_cantidad', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.valeDetalleAgregar, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });
    }
}

export const valeEliminar = (token, id_usuario, id_devolucion, id_motivo, estado, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_devolucion: id_devolucion, id_motivo: id_motivo, estado: estado
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/cambiar/vale_eliminar', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.valeEliminar, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });
    }
}

export const valeFinalizar = (token, id_usuario, id_devolucion, id_motivo, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_devolucion: id_devolucion, id_motivo: id_motivo
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/cambiar/vale_finalizar', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.valeFinalizar, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });
    }
}

export const valeLiquidar = (token, id_usuario, id_devolucion, comentario, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_devolucion: id_devolucion, comentario: comentario
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/cambiar/vale_liquidar', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.valeFinalizar, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });
    }
}

export const valeRuta = (token, id_usuario, id_devolucion, fecha_ruta, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        if(!fecha_ruta){
            Swal.fire('Vale', 'Debe ingresar una fecha programada.', 'warning');
            return;
        }
        if(fecha_ruta.length !== 10){
            Swal.fire('Vale', 'Debe ingresar una fecha programada valida.', 'warning');
            return;
        }

        const datos = { 
            id_usuario: id_usuario, id_devolucion: id_devolucion, fecha_ruta: fecha_ruta
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/cambiar/vale_ruta', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.valeFinalizar, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });
    }
}

export const valeMotivoSinLiquidar = (token, id_usuario, id_devolucion, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_devolucion: id_devolucion
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_OPERACIONES + 'consulta/obtener_motivo_no_liquidado_vale', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({
                        type: types.valeMotivoSinLiquidar, 
                        data: dato,
                        ref: new Date
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Vale', message, 'error');
                }
            });
    }
}

export const pedidoLoad = (token, id_usuario, id_pedido, sap, cliente, 
        fecha_1, fecha_2, fecha_sap_1, fecha_sap_2, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        
        const datos = { id_usuario: id_usuario, id_pedido: id_pedido, sap: sap, 
                        cliente: cliente, fecha_1: fecha_1, fecha_2: fecha_2, 
                        fecha_sap_1: fecha_sap_1, fecha_sap_2: fecha_sap_2 }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'consulta/listar_pedidos', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Pedido', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = JSON.parse(json.data);  
                    //console.log(dato);                  
                    dispatch({ 
                        type: types.pedido, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Pedido', message, 'error');
                }
            });        
    }
}

export const pedidoLoadForm = (token, id_usuario, id_pedido, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario, id_pedido: id_pedido }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'consulta/pedido_dato', datos )
            .then( response => {
                let json = response.data;
                
                if(json.stt != 'OK'){
                    Swal.fire('Vale', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = {
                        encabezado: JSON.parse(json.data.encabezado),
                        detalle: JSON.parse(json.data.detalle)
                    }
                    //console.log(dato);
                    dispatch({ 
                        type: types.pedidoForm, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Pedido', message, 'error');
                }
            });        
    }
}

export const pedidoDetalleLoad = (token, id_usuario, id_pedido, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        const datos = { id_usuario: id_usuario, id_pedido: id_pedido }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'consulta/listar_detalle_pedido', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Pedido', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = JSON.parse(json.data);  
                    //console.log(dato);                  
                    dispatch({ 
                        type: types.pedidoDetalle, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Pedido', message, 'error');
                }
            });        
    }
}

export const pedidoDetalleAgregar = (token, id_usuario, id_pedido, producto, cantidad, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );

        /*Validando los datos de parametros*/
        if(!producto){
            Swal.fire('Pedido', 'Debe seleccionar un producto', 'warning');
            return;
        }
        if(producto == '-1'){
            Swal.fire('Pedido', 'Debe seleccionar un producto', 'warning');
            return;
        }
        if(cantidad.length < 1){
            Swal.fire('Pedido', 'Debe ingresar una cantidad', 'warning');
            return;
        }
        if(parseInt(cantidad) < 1){
            Swal.fire('Pedido', 'La cantidad ingresada debe ser mayor a cero', 'warning');
            return;
        }

        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_pedido: id_pedido,
            producto: producto, cantidad: cantidad
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/crear/agregar_pedido_detalle', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Pedido', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.pedidoDetalleAgregar, 
                        data: dato,
                        ref: new Date
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Pedido', message, 'error');
                }
            });
    }
}

export const pedidoDetalleCantidad = (token, id_usuario, id_pedido, id_detalle, cantidad, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_pedido: id_pedido, id_detalle: id_detalle, cantidad: cantidad
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/cambiar/pedido_detalle_cantidad', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Pedido', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.pedidoDetalleAgregar, 
                        data: dato,
                        ref: new Date
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Pedido', message, 'error');
                }
            });
    }
}

export const pedidoDetalleEliminar = (token, id_usuario, id_pedido, id_detalle, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_pedido: id_pedido, id_detalle: id_detalle
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/eliminar/pedido_detalle', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Pedido', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.pedidoDetalleAgregar, 
                        data: dato,
                        ref: new Date
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Pedido', message, 'error');
                }
            });
    }
}

export const pedidoFechaComentario = (token, id_usuario, id_pedido, cliente, fecha, comentario, hora_inicio, hora_fin, setIsLoading) => {
    return (dispatch) => {
        /*Validando los datos de parametros*/
        if(fecha.length !== 10){
            Swal.fire('Pedido', 'Debe Ingresar una fecha valida', 'warning');
            return;
        }
        if(comentario.length > 120){
            Swal.fire('Pedido', 'El tamaño maximo es de 120 caracteres de longitud', 'warning');
            return;
        }
        if(!hora_inicio){
            Swal.fire('Pedido', 'Debe ingresar una hora inicio', 'warning');
            return;
        }
        if(!hora_fin){
            Swal.fire('Pedido', 'Debe ingresar una hora fin', 'warning');
            return;
        }

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_pedido: id_pedido, id_cliente: cliente, fecha_entrega: fecha, comentario: comentario, 
            hora_inicio: hora_inicio, hora_fin: hora_fin
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/cambiar/pedido_fecha_comentario', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Pedido', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.pedidoDetalleAgregar, 
                        data: dato,
                        ref: new Date
                    });

                    Swal.fire('Pedido', json.msg, 'info');
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Pedido', message, 'error');
                }
            });
    }
}

export const pedidoEliminar = (token, id_usuario, id_pedido, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_pedido: id_pedido
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/cambiar/pedido_eliminar', datos )
            .then( response => {
                let json = response.data;
                if(json.stt != 'OK'){
                    Swal.fire('Pedido', json.msg, 'warning');
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.pedidoEliminar, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);
                console.log(response)
                console.log(message)
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Pedido', message, 'error');
                }
            });
    }
}

export const pedidoTransferirSAP = (token, id_usuario, id_pedido, comentario, cliente, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, id_pedido: id_pedido, comentario: comentario
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/crear/orden_venta', datos )
            .then( response => {
                let json = response.data;
                if(json.stt != 'OK' && json.stt != 'COMENTARIO'){
                    //Swal.fire('Pedido', `${cliente} ${json.msg}`, 'warning');
                    Swal.fire({
                        title: 'Pedido',
                        html: `<p><b>${ cliente }</b><br/> ${ json.msg }</p>`,
                        icon: 'warning'
                    });
                }else if(json.stt == 'COMENTARIO'){
                    dispatch({ 
                        type: types.pedidoComentario, 
                        data: id_pedido,
                        msg: json.msg
                    });
                }else{
                    const dato = json.data;
                    dispatch({ 
                        type: types.pedidoSAP, 
                        data: id_pedido,
                        ref: new Date
                    });
                    Swal.fire('Pedido', `Pedido no. [${id_pedido}] fue transferido exitosamente, no. documento SAP [${dato}]`, 'info');
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{ 
                setIsLoading(false);

                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Pedido', message, 'error');
                }
            });
    }
}

export const colaboradorXClienteLoad = (token, id_usuario, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        
        const datos = { id_usuario: id_usuario }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'consulta/listar_usuario_x_cliente', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Colaborador por cliente', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;  
                    //console.log(dato);                  
                    dispatch({ 
                        type: types.colaboradorXClienteTabla, 
                        data: dato
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{
                setIsLoading(false);
                //console.log(response, message);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Colaborador por cliente', message, 'error');
                }
            });        
    }
}

export const agregarTiendaColaborador = (token, id_usuario, id_colaborador, id_cliente, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        
        const datos = { id_usuario: id_usuario, id_colaborador: id_colaborador, id_cliente: id_cliente }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/crear/asigna_tienda_colaborador', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Colaborador por cliente', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;  
                    //console.log(dato);                  
                    dispatch({ 
                        type: types.colaboradorXClienteLinea, 
                        data: dato,
                        ref: new Date
                    });
                    Swal.fire('Colaborador por cliente', json.msg, 'warning');
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{
                setIsLoading(false);
                //console.log(response, message);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Colaborador por cliente', message, 'error');
                }
            });        
    }
}

export const removerTiendaColaborador = (token, id_usuario, id_colaborador, id_cliente, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);
        
        const datos = { id_usuario: id_usuario, id_colaborador: id_colaborador, id_cliente: id_cliente }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_COMERCIAL + 'registro/eliminar/asignado_tienda_colaborador', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Colaborador por cliente', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;  
                    //console.log(dato);                  
                    dispatch({ 
                        type: types.colaboradorXClienteEstado, 
                        data: dato,
                        ref: new Date
                    });
                    Swal.fire('Colaborador por cliente', json.msg, 'warning');
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{
                setIsLoading(false);
                //console.log(response, message);
                if(response?.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Colaborador por cliente', message, 'error');
                }
            });        
    }
}
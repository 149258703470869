import React from 'react'
import { Routes, Route } from "react-router-dom";

import { PedidoScreen } from '../componentes/view/comercial/traslado/pedido/PedidoScreen';
import { MarcajeScreen } from '../componentes/view/comercial/reporte/marcaje/MarcajeScreen';
import { ValeScreen } from '../componentes/view/comercial/vales/ValeScreen';
import { EmbajadorScreen } from '../componentes/view/comercial/asignacion/embajador/EmbajadorScreen';
import ListaOM from '../componentes/view/comercial/om/listOm';
import DeliveryTable from '../componentes/view/comercial/om/listaCa'; 
import DashboardOM from '../componentes/view/comercial/om/detalleom';
import ValesReports from '../componentes/view/comercial/reporte/vales/ValesReports';

export const ComercialRoutes = () => {

  return (
    <Routes>
      <Route path="traslado/pedido" element={ <PedidoScreen /> } />
      <Route path="reporte/marcaje" element={ <MarcajeScreen /> } />
      <Route path="reporte/vales" element={ <ValesReports/> } />
      <Route path="vales" element={ <ValeScreen /> } />
      <Route path="vales/:id_rastreo" element={ <ValeScreen /> } />
      <Route path="tienda/embajador" element={ <EmbajadorScreen /> } />
      <Route path="om" element={ <ListaOM /> } /> 
      <Route path="ca" element={ <DeliveryTable /> } />
      <Route path="detalleom/:orderId" element={ <DashboardOM /> } />


      
    </Routes>
  )
}

import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table';

export const InputFilterTable = ({ 
    preGlobalFilteredRows, globalFilter, setGlobalFilter 
}) => {

    const totalAvailable = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);

    const onFilterChange = useAsyncDebounce(
        (value) => setGlobalFilter(value || undefined),
        200
    );

    const handleInputChange = (e) => {
        setValue(e.target.value);
        onFilterChange(e.target.value);
    };
     

  return (
    <div className="row col-12">
        <label className="col-2 align-text-bottom form-label">Filtro:&nbsp;</label>
        <div className="col-10">
            <input
                className = 'form-control form-control-sm text-center'
                type = { 'text' }
                maxLength = { 100 }
                value = { value || "" }
                onChange = { handleInputChange }
                placeholder = {`${totalAvailable} registros disponibles...`}
            />
        </div>
    </div>
  )
}

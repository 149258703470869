import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Pagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ButtonGroup
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

function InvoiceForProductDay() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [filtroTexto, setFiltroTexto] = useState("");
  const [filtroTienda, setFiltroTienda] = useState(""); // Para el filtro de tienda
  const [tiendas, setTiendas] = useState([]); // Lista de tiendas
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(50);

  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaReportesSalas",
        Datos_Recibidos: `{"Vista":"ReporteVentas", "FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}` // Puedes ajustar el método según lo que necesites
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;

    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        try {
          if (data && data.datos_Generados) {
            const datosGeneradosLimpiados = data.datos_Generados.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
            const datosGeneradosDecodificados = JSON.parse(datosGeneradosLimpiados);
            setDatosGenerados(datosGeneradosDecodificados);
            console.log(datosGeneradosDecodificados);
          } else {
            console.error("No se recibieron datos generados válidos:", data);
          }
        } catch (error) {
          console.error("Error al decodificar los datos generados:", error);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
    // Simulación de carga de tiendas desde la API o un array local
    setTiendas([ " ","TZ10", "TPRR", "TSCR", "TCSV","TMIR","TXLA"]); // Aquí podrías realizar otra petición para obtener las tiendas
  }, [fechaInicio, fechaFin]);

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const limpiarJSON = (jsonString) => {
    return jsonString.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
  };

  // Filtrar los datos según el texto de búsqueda y la tienda seleccionada
  const filteredData = datosGenerados.flatMap(item => {
    try {
      const detalle = JSON.parse(limpiarJSON(item.Detalle)).DETALLE || [];
      return detalle.filter(prod => {
        const searchValue = filtroTexto.toLowerCase();
        const filtroPorTienda = filtroTienda ? item.Sala === filtroTienda : true; // Filtro de tienda
        return (
          (prod.ItemCode.toLowerCase().includes(searchValue) ||
          prod.Dscription.toLowerCase().includes(searchValue)) &&
          filtroPorTienda && // Verifica el filtro de tienda
          (prod.Price > 0) // Excluye los productos con costo 0
        );
      }).map(prod => {
        const partesFecha = item.DocDate.split('/');
        const fechaFormateada = `${partesFecha[0]}/${partesFecha[1]}/${partesFecha[2]}`;

        return {
          Caja: item.Caja,
          Fecha: fechaFormateada,
          NumFactura: item.DocNum,
          nit: item.nit,
          Cliente: item.Cliente,
          Codigo: prod.ItemCode,
          Descripcion: prod.Dscription,
          UnidadesVendidas: prod.Cantidad,
          Costo: prod.Price || 0,
          Estado: item.Estado,
          Tienda: item.Sala // Asegúrate de que el objeto tenga la propiedad 'Tienda'
        };
      });
    } catch (error) {
      console.error("Error al procesar el detalle:", error);
      return [];
    }
  });

  // Paginación
  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);

  // Función para generar y descargar PDF
  const exportToPDF = () => {
    const doc = new jsPDF();

    doc.text("REPORTE PRODUCTOS POR FECHA", 20, 10);

    doc.autoTable({
      head: [['Caja', 'Fecha', 'Factura', 'NIT', 'Cliente', 'Código', 'Descripción', 'Unidades Vendidas', 'Costo', 'Estado']],
      body: filteredData.map(row => [
        row.Caja,
        row.Fecha,
        row.NumFactura,
        row.nit,
        row.Cliente,
        row.Codigo,
        row.Descripcion,
        row.UnidadesVendidas,
        row.Costo,
        row.Estado
      ]),
    });

    doc.save('facturas.pdf');
  };

  // Función para exportar a Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ReporteProductos");
    XLSX.writeFile(workbook, 'facturas.xlsx');
  };

  return (
    <Container>
      <h1> FACTURAS </h1>
      <hr />
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          id="filtroTexto"
          label="Buscar"
          value={filtroTexto}
          onChange={(e) => setFiltroTexto(e.target.value)}
          variant="outlined"
          style={{ width: '400px' }}
        />
        
        <FormControl variant="outlined" style={{ width: '150px' }}>
    <InputLabel id="filtro-tienda-label">Tienda</InputLabel>
    <Select
      labelId="filtro-tienda-label"
      value={filtroTienda}
      onChange={(e) => setFiltroTienda(e.target.value)}
      displayEmpty
      renderValue={(selected) => (selected === "" ? "Todas las tiendas" : selected)}
    >
      <MenuItem value="">
        <em>Todas las tiendas</em>
      </MenuItem>
      {tiendas.map((tienda, index) => (
        <MenuItem key={index} value={tienda}>
          {tienda}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

        <TextField
          id="fechaInicio"
          label="Fecha Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '200px', marginLeft: '10px' }}
        />

        <TextField
          id="fechaFin"
          label="Fecha Fin"
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: '200px', marginLeft: '10px' }}
        />

        <Button variant="contained" onClick={handleSearch} style={{ marginLeft: '10px' }}>Buscar</Button>
        <ButtonGroup variant="contained" style={{ marginLeft: '10px' }}>
          <Button onClick={exportToPDF} color='warning' > PDF</Button>
          <Button onClick={exportToExcel} style={{ marginLeft: '10px' }} color='success'> Excel</Button>
        </ButtonGroup>
      </div>

      <Table>
        <TableHead>
          <TableRow>
          <TableCell>Tienda</TableCell>
            <TableCell>Caja</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>NumFactura</TableCell>
            <TableCell>NIT</TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Código</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Unidades Vendidas</TableCell>
            <TableCell>Costo</TableCell>
            <TableCell>Estado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosPaginados.map((item, index) => (

            <TableRow key={index}>
               <TableCell>{item.Tienda}</TableCell> {/* Agregar la columna Tienda */}
              <TableCell>{item.Caja}</TableCell>
              <TableCell>{item.Fecha}</TableCell>
              <TableCell>{item.NumFactura}</TableCell>
              <TableCell>{item.nit}</TableCell>
              <TableCell>{item.Cliente}</TableCell>
              <TableCell>{item.Codigo}</TableCell>
              <TableCell>{item.Descripcion}</TableCell>
              <TableCell>{item.UnidadesVendidas}</TableCell>
              <TableCell>{item.Costo}</TableCell>
              <TableCell>{item.Estado}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        count={Math.ceil(filteredData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
        color="primary"
      />
    </Container>
  );
}

export default InvoiceForProductDay;

import axios from 'axios';
import Swal from 'sweetalert2'

import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";

export const startLogin = (usuario, clave, setIsLoading, setIsDisableLogin) => {

    return (dispatch) => {

        dispatch( startLoading() );  

        const datos = { usuario: usuario, clave: clave, id_sistema: process.env.REACT_APP_SISTEMA };
        axios.post( process.env.REACT_APP_API_LOGIN + 'autenticar', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Login', json.msg, 'warning');
                    setIsLoading(false);
                    setIsDisableLogin(false);
                }else{
                    const fecha = new Date();

                    const auth = {
                        user: {
                            id_usuario: json.data.id_usuario,
                            usuario:  json.data.usuario,
                            nombre: json.data.nombre_corto,
                            menu: JSON.parse( json.menu ),
                            rol: JSON.parse( json.rol ),
                            permiso: JSON.parse( json.permiso ),
                            avatar: json.data.avatar
                        },
                        token: json.token,
                        isLoggedIn: true,
                        expiracion: fecha.setDate(fecha.getDate() + 1)
                    };
                    localStorage.setItem( 'auth', JSON.stringify( auth ));
                    localStorage.setItem( 'logged', true );
                    
                    dispatch( login( auth ) );
                }
            })
            .catch( ({message}) =>{ 
                setIsLoading(false);
                setIsDisableLogin(false);
                Swal.fire('Login', message, 'error');
            });
    }
}

export const login = (datos) => ({
    type: types.login, 
    auth: datos
});

export const startRenovar = (token, usuario) => {
    return (dispatch) => {
        dispatch( startLoading() );
        
        const datos = { usuario: usuario }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_LOGIN + 'autenticar/renovar', datos )
            .then( response => {
                let json = response.data;

                if(json.stt != 'OK'){
                    Swal.fire('Sesión Finalizada', json.msg, 'warning');
                    startLogout();
                }else{
                    const fecha = new Date();
                    const dato = json;
                    const auth = JSON.parse(localStorage.getItem( 'auth' ));
                    //console.log(dato.msg);
                    auth.expiracion = fecha.setDate(fecha.getDate() + 1);
                    auth.token = dato.token;

                    localStorage.setItem( 'auth', JSON.stringify( auth ));
                    dispatch( login( auth ) );
                }
            })
            .catch( ({response, message}) =>{
                if(response.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning')
                    .then((result) => {
                        dispatch( startLogout(usuario) );
                    });
                }else{
                    Swal.fire('Sesión', message, 'error');
                }                
            }); 
    }
}

export const startLogout = (usuario, setIsLoading) => {
    return (dispatch) => {
        dispatch( finishLoading() );

        localStorage.clear();
        dispatch( logout() );
    }
}

export const logout = () => ({
    type: types.logout
});
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, startRenovar } from '../componentes/actions/auth';

export const useCheckAuth = () => {
  
    const initLogged = () => {
        return JSON.parse(localStorage.getItem( 'logged' )) || false;
    }
    
    const initAuth = () => {
        return JSON.parse(localStorage.getItem( 'auth' )) || {};
    }

    const dispatch = useDispatch();
    dispatch( login( initAuth() ) );
    const [isLoggedIn, setIsLoggedIn] = useState( initLogged() );
    const { token, expiracion, user } = initAuth();

    useEffect(() => {
        if(!isLoggedIn) return
        setIsLoggedIn( true );

        if(expiracion < new Date()){   
            dispatch( startRenovar(token, user.usuario) );
        }
    }, [dispatch, isLoggedIn]);
    
    return {
        isLoggedIn
    }
}

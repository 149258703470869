import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

import { Loading } from '../../../ui/Loading';
import { EncabezadoFormulario } from '../../../elements/EncabezadoFormulario';
import { UsuarioDatos } from './UsuarioDatos';
import { UsuarioPerfil } from './UsuarioPerfil';
import { UsuarioSucursal } from './UsuarioSucursal';
import { UsuarioUbicacion } from './UsuarioUbicacion';

export const UsuarioFormulario = () => {

  const { token, user } = useSelector(state => state.auth);
  const [ isLoading, setIsLoading ] = useState(false); 
  const { id, modo } = useParams();

  console.log(id, modo);

  return (
    <>
        <Loading isLoading={isLoading} />
        <div className="container">
            <EncabezadoFormulario Titulo={'Formulario de Usuario'} />       

            <ul className="nav nav-tabs" id="tab-usuario" role="tablist">
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="datos-tab" data-bs-toggle="tab" data-bs-target="#datos" type="button" role="tab" aria-controls="datos" aria-selected="true">
                  <i className='fa-solid fa-id-card'></i> Datos Generales
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="perfil-tab" data-bs-toggle="tab" data-bs-target="#perfil" type="button" role="tab" aria-controls="perfil" aria-selected="true">
                  <i className='fa-solid fa-user'></i> Perfil
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="sucursal-tab" data-bs-toggle="tab" data-bs-target="#sucursal" type="button" role="tab" aria-controls="sucursal" aria-selected="true">
                  <i className='fa-solid fa-industry'></i> Sucursal/Distribuidora
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="ubicacion-tab" data-bs-toggle="tab" data-bs-target="#ubicacion" type="button" role="tab" aria-controls="ubicacion" aria-selected="true">
                  <i className="fa-solid fa-street-view"></i> Ubicación
                </button>
              </li>
            </ul>

            <div className="tab-content" id="ta-user-content">
              <div className="tab-pane fade show active" id="datos" role="tabpanel" aria-labelledby="datos-tab">
                <UsuarioDatos className='mx-5 px-5'/>
              </div>
              <div className="tab-pane fade" id="perfil" role="tabpanel" aria-labelledby="perfil-tab">
                <UsuarioPerfil className='mx-5 px-5'/>
              </div>
              <div className="tab-pane fade" id="sucursal" role="tabpanel" aria-labelledby="sucursal-tab">
                <UsuarioSucursal className='mx-5 px-5'/>
              </div>
              <div className="tab-pane fade" id="ubicacion" role="tabpanel" aria-labelledby="ubicacion-tab">
                <UsuarioUbicacion className='mx-5 px-5'/>
              </div>
            </div>
        </div>
    </>
  )
}

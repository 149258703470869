import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import Swal from 'sweetalert2'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { Loading } from '../../../../ui/Loading';

import { clienteSelectorLoad, colaboradorSelectorLoad } from '../../../../actions/selector';
import { colaboradorXClienteLoad, agregarTiendaColaborador, removerTiendaColaborador } from '../../../../actions/comercial';
import { CardEmbajador } from './CardEmbajador';
import { EncabezadoTabla } from '../../../../elements/EncabezadoTabla';

export const EmbajadorScreen = () => {
  const dispatch = useDispatch();

  const selectorCliente = useRef();
  const selectorColaborador = useRef();

  const { token, user } = useSelector(state => state.auth);
  const { clienteData, usuarioColaboradorData } = useSelector(state => state.selector);
  const { colaboradorXClienteTabla, colaboradorXClienteLinea, colaboradorXClienteEstado } = useSelector(state => state.comercial);
  const [ options, setOptions ] = useState([]);
  const [ cliente, setCliente ] = useState(null);
  const [ optionsColaborador, setOptionsColaborador ] = useState([]);
  const [ usuarioColaborador, setUsuarioColaborador ] = useState(null);
  const [ colaborador, setColaborador ] = useState(null);
  const [ tienda, setTienda ] = useState(null);
  const [data, setData] = useState([]);
  const [nuevo, setNuevo] = useState([]);
  const [ tituloColaborador, setTituloColaborador ] = useState('');
  const [buscar, setBuscar] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [ isOpenTienda, setIsOpenTienda ] = useState(false);
  const [ isOpen, setIsOpen ] = useState(false);

  useEffect(() => {
    dispatch( clienteSelectorLoad( token, user.id_usuario, setIsLoading ) );
    dispatch( colaboradorSelectorLoad( token, user.id_usuario, setIsLoading ) );
    dispatch( colaboradorXClienteLoad( token, user.id_usuario, setIsLoading ) );
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    if(clienteData){
        let lista = [];
        clienteData?.map((item) => {
            lista.push({ values: item.Id, label: `${item.Id} - ${item.Nombre}` });
        });
        setOptions(lista);
    }
}, [clienteData]);

useEffect(() => {
  if(usuarioColaboradorData){
      let lista = [];
      usuarioColaboradorData?.map((item) => {
          lista.push({ values: item.Id, label: `${item.Id} - ${item.Nombre_Completo}` });
      });
      setOptionsColaborador(lista);
  }
}, [usuarioColaboradorData]);

useEffect(() => {
  if(colaboradorXClienteTabla){
    setData(colaboradorXClienteTabla);
  }
}, [colaboradorXClienteTabla]);

useEffect(() => {
  if(colaboradorXClienteLinea){
    if(nuevo){
      let tablaData = colaboradorXClienteTabla.filter((row) => row.id == usuarioColaborador.values);
      if(tablaData.length > 0){
        let clienteData = JSON.parse( tablaData[0].cliente );
        clienteData.push( colaboradorXClienteLinea.data );
        tablaData[0].cliente = JSON.stringify( clienteData );
        setData( tablaData );
      }else{
        let clienteData = { Codigo_Cliente: cliente.values, Cliente: (cliente.label).split('-')[1] }
        let clienteArray = [];
        clienteArray.push(clienteData);
        const nuevaData = { 
          id: usuarioColaborador.values, 
          colaborador: (usuarioColaborador.label).split('-')[1],
          cliente: JSON.stringify(clienteArray)
        };

        tablaData = colaboradorXClienteTabla;
        tablaData.push(nuevaData);
        setData(tablaData);
      }
      selectorColaborador.current.clearValue();
    }else{
      let tablaData = colaboradorXClienteTabla.filter((row) => row.id == colaborador.id);
      const clienteData = JSON.parse( tablaData[0].cliente );
      clienteData.push( colaboradorXClienteLinea.data );
      tablaData[0].cliente = JSON.stringify( clienteData );
      setData( tablaData );
    }
    selectorCliente.current.clearValue();
  }
}, [colaboradorXClienteLinea]);

useEffect(() => {
  if(colaboradorXClienteEstado){
    //console.log(colaborador, ' => ', tienda );
    let tablaData = colaboradorXClienteTabla.filter((row) => row.id == colaborador);
    const clienteJson = JSON.parse( tablaData[0].cliente );
    //console.log(clienteJson);
    let clienteData = clienteJson.filter((row) => row.Codigo_Cliente !== tienda);
    //console.log(clienteData);
    tablaData[0].cliente = JSON.stringify( clienteData );

    setData( tablaData );
    onHandleBuscar({ target: {name:'', value: buscar} });
  }
}, [colaboradorXClienteEstado])


  const onBuscarTienda = (e, id_colaborador, colaborador) => {
    e.preventDefault();
    //console.log(id_usuario);
    setTituloColaborador(colaborador);
    setColaborador(id_colaborador);
    setIsOpenTienda(true);
  }

  const onAgregarTienda = (e, nuevo) => {
    e.preventDefault();
    //console.log(nuevo);
    setNuevo(nuevo);
    if(nuevo){
      dispatch( agregarTiendaColaborador( token, user.id_usuario, usuarioColaborador?.values, cliente?.values, setIsLoading ) );
    }else{
      dispatch( agregarTiendaColaborador( token, user.id_usuario, colaborador?.id, cliente?.values, setIsLoading ) );
    }
  }

  const onRemoverTienda = (e, id_colaborador, id_cliente) => {
    e.preventDefault();
    //console.log(colaborador, ' => ', cliente);
    setColaborador(id_colaborador);
    setTienda(id_cliente);
    dispatch( removerTiendaColaborador( token, user.id_usuario, id_colaborador, id_cliente, setIsLoading ) );
  }

  const onHandleBuscar = ({ target }) => {
    const { name, value } = target;
    const tablaData = colaboradorXClienteTabla.filter((row) => row.colaborador.toUpperCase().includes(value.toUpperCase()));
    setData( tablaData );
    setBuscar(value);
  }

  const onCloseModal = (e) => {
    e.preventDefault();
    setIsOpenTienda(false);
    setIsOpen(false);
    onHandleBuscar({ target: {name:'', value: buscar} });
  }

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="container">
        <EncabezadoTabla
          Titulo={'Asignación de embajadores a tienda'} 
          setIsOpen={ setIsOpen }/>

        <form className="row g-3">
          <div className="col-12">
            <label className="form-label">Buscador embajador</label>
            <div className="input-group mb-3">
              <span className="input-group-text"><i className='fa-solid fa fa-search'></i></span>
              <input className="form-control" name='buscador' value={buscar} onChange={ onHandleBuscar } autoComplete='off'/>
            </div>
          </div>
        </form>
        <hr/>
        
        <CardEmbajador
          dataColaborador = { data }
          onBuscarTienda = { onBuscarTienda }
          onRemoverTienda = { onRemoverTienda }/>
      </div>

      <Modal isOpen={isOpenTienda}>
        <ModalHeader>{ tituloColaborador }</ModalHeader>
        <ModalBody>
          <div className='col-12 col-md-12'>
            <label className='form-label'>Tienda / Cliente</label>
            <Select isClearable
              ref={selectorCliente}
              className='form-sm' id='cliente' name='cliente'
              onChange={ setCliente } placeholder={ 'Seleccione un cliente' }
              options={ options }/>
          </div>
        </ModalBody>
        <ModalFooter>
            <button type="button" className="btn btn-primary" onClick={ (e) => onAgregarTienda(e, false) }>Enviar</button>
            <button type="button" className="btn btn-secondary" onClick={ onCloseModal }>Cerrar</button>                    
        </ModalFooter>
      </Modal>

      <Modal isOpen={isOpen}>
        <ModalHeader>Asignación nueva</ModalHeader>
        <ModalBody>
          <div className='col-12 col-md-12'>
            <label className='form-label'>Colaborador</label>
            <Select isClearable
              ref={selectorColaborador}
              className='form-sm' id='colaborador' name='colaborador'
              onChange={ setUsuarioColaborador } placeholder={ 'Seleccione un usuario' }
              options={ optionsColaborador }/>
          </div>
          <div className='col-12 col-md-12'>
            <label className='form-label'>Tienda / Cliente</label>
            <Select isClearable
              ref={selectorCliente}
              className='form-sm' id='cliente' name='cliente'
              onChange={ setCliente } placeholder={ 'Seleccione un cliente' }
              options={ options }/>
          </div>
        </ModalBody>
        <ModalFooter>
            <button type="button" className="btn btn-primary" onClick={ (e) => onAgregarTienda(e, true) }>Enviar</button>
            <button type="button" className="btn btn-secondary" onClick={ onCloseModal }>Cerrar</button>                    
        </ModalFooter>
      </Modal>
    </>
  )
}

import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

export const PrivateRoutes = ({ children }) => {

  const { pathname, search } = useLocation();
  const { isLoggedIn } = useSelector( state => state.auth );

  localStorage.setItem( 'lastPath', pathname + search );
  //console.log('private router =>', localStorage.getItem('lastPath'));
  
  return isLoggedIn
    ? children
    : <Navigate to="/login" />
}

import React, { useState } from 'react';
import { Button, TextField, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const HeladosPage = () => {
  const [tienda, setTienda] = useState('');
  const [maquina, setMaquina] = useState('');
  const [tolba1, setTolba1] = useState('');
  const [tolba2, setTolba2] = useState('');
  const [data, setData] = useState([]);

  const handleGuardar = () => {
    const nuevaFila = {
      fecha: new Date().toLocaleDateString(),
      tienda,
      tolba1,
      tolba2,
      mix: `${tolba1}-${tolba2}`
    };
    setData([...data, nuevaFila]);
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Creación de Helados</h1>

      <div style={{ marginBottom: '20px' }}>
        <Select
          value={tienda}
          onChange={(e) => setTienda(e.target.value)}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Selecciona una tienda</MenuItem>
          <MenuItem value="Tienda 1">Tienda 1</MenuItem>
          <MenuItem value="Tienda 2">Tienda 2</MenuItem>
          {/* Agrega más tiendas según sea necesario */}
        </Select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Select
          value={maquina}
          onChange={(e) => setMaquina(e.target.value)}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Selecciona una máquina</MenuItem>
          <MenuItem value="Maquina 1">Máquina 1</MenuItem>
          <MenuItem value="Maquina 2">Máquina 2</MenuItem>
          {/* Agrega más máquinas según sea necesario */}
        </Select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Select
          value={tolba1}
          onChange={(e) => setTolba1(e.target.value)}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Tolba 1</MenuItem>
          <MenuItem value="Helado 1">Helado 1</MenuItem>
          <MenuItem value="Helado 2">Helado 2</MenuItem>
        </Select>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Select
          value={tolba2}
          onChange={(e) => setTolba2(e.target.value)}
          displayEmpty
          fullWidth
        >
          <MenuItem value="">Tolba 2</MenuItem>
          <MenuItem value="Helado 1">Helado 1</MenuItem>
          <MenuItem value="Helado 2">Helado 2</MenuItem>
        </Select>
      </div>

      <Button variant="contained" onClick={handleGuardar} fullWidth>
        Guardar
      </Button>

      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Tienda</TableCell>
            <TableCell>Helado 1</TableCell>
            <TableCell>Helado 2</TableCell>
            <TableCell>Mix</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.fecha}</TableCell>
              <TableCell>{row.tienda}</TableCell>
              <TableCell>{row.tolba1}</TableCell>
              <TableCell>{row.tolba2}</TableCell>
              <TableCell>{row.mix}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default HeladosPage;

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import axios from 'axios';
import Swal from 'sweetalert2';

import { Loading } from '../../../ui/Loading';
import { EstadoRegistro } from '../../../elements/EstadoRegistro';
import { InputFilterTable } from '../../../elements/InputFilterTable';
import { PaginationTable } from '../../../elements/PaginationTable';
import { EncabezadoTabla } from '../../../elements/EncabezadoTabla';
import { ButtonLinkEditar } from '../../../elements/ButtonLinkEditar';
import { ButtonLinkVisor } from '../../../elements/ButtonLinkVisor';

export const UsuarioScreen = () => {

    const { token, user } = useSelector(state => state.auth);
    const [ isLoading, setIsLoading ] = useState(false);    
    const [data, setData] = useState([]);
    const colSpanTable = 8;

    /*Encabezado de la tabla*/
    const columns = useMemo(
        () => [
            {
                Header: "Accion",
                Cell: ({ value, cell }) => { 
                    return (<div style={{ width: 85, textAlign: 'center' }}>
                        <ButtonLinkEditar Href={`/seguridad/usuario/${ cell.row.values.Id }/U`} />
                        <ButtonLinkVisor Href={`/seguridad/usuario/${ cell.row.values.Id }/S`} />
                    </div>)
                }
            },
            {
                Header: "#",
                accessor: "Id"
            },
            {
                Header: "Usuario",
                accessor: "Usuario"
            },
            {
                Header: "Colaborador",
                accessor: "Nombre_Completo"
            },
            {
                Header: "Telefono",
                accessor: "Telefono"
            },
            {
                Header: "Correo",
                accessor: "Correo"
            },
            {
                Header: "Fecha Alta",
                accessor: "Fecha_Creacion"
            },
            {
                Header: "Estado",
                accessor: "Estado",
                Cell: ({ value, cell }) => {
                    return <EstadoRegistro Estado ={value}/>
                }
            }
        ]
    , []);

    /*Hook para crear la tabla*/
    const table = useTable({ columns, data, initialState: {
        pageSize: 10,
        pageIndex: 0
      } }, useGlobalFilter, useSortBy, usePagination);

    /*El restructuracion de parametros basados del objeto table*/
    const {
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { globalFilter },
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = table;

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.get( `${ process.env.REACT_APP_API_LOGIN }seguridad/listar_usuario/${ user.id_usuario }`)
        .then( response =>{
            let json = response.data;

            if(json.stt == "OK"){
                setData( JSON.parse(json.data) );
            }else{
                Swal.fire('Usuario', json.msg, 'error');                
            }
            setIsLoading(false);
        })
        .catch( ({message}) =>{
            setIsLoading(false);
            Swal.fire('Usuario', message, 'error');            
        });
      }, []);

  return (
    <>
        <Loading isLoading={isLoading} />
        <div className="container">
            <EncabezadoTabla Titulo='Lista de Usuarios' Href='/seguridad/usuario/I' />

            <div className='table-responsive'>
                <table
                    className='table table-striped table-hover table-bordered'
                    { ...getTableProps }>
                    <thead>
                        <tr>
                            <th colSpan={ colSpanTable }>
                                <InputFilterTable 
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter} />
                            </th>
                        </tr>
                        {
                            headerGroups.map( headerGroup => (
                                <tr { ...headerGroup.getHeaderGroupProps() }>
                                    {
                                        headerGroup.headers.map( columns => (
                                            <th { ...columns.getHeaderProps(columns.getSortByToggleProps()) }
                                                className={
                                                    columns.isSorted 
                                                        ? columns.isSortedDesc
                                                            ? "desc"
                                                            : "asc"
                                                        : ""
                                                }>
                                                {
                                                    columns.render("Header")
                                                }
                                            </th>
                                        ) )
                                    }
                                </tr>
                            ) ) 
                        }
                    </thead>
                    <tbody { ...getTableBodyProps() }>
                        {
                            page.map( row => {

                                prepareRow( row )
                                return  (
                                    <tr { ...row.getRowProps() }>
                                        {
                                            row.cells.map( cell => (
                                                <td { ...cell.getCellProps() }>
                                                    {
                                                        cell.render("Cell")
                                                    }
                                                </td>
                                            ) )
                                        }
                                    </tr>
                                )
                            } )
                        }

                        <tr className='table-secondary'>
                            <td colSpan={ colSpanTable }>
                                <PaginationTable 
                                    canPreviousPage = {canPreviousPage}
                                    canNextPage = {canNextPage}
                                    pageOptions = {pageOptions}
                                    pageCount = {pageCount}
                                    gotoPage = {gotoPage}
                                    nextPage = {nextPage}
                                    previousPage = {previousPage}
                                    setPageSize = {setPageSize}
                                    pageIndex = {pageIndex}
                                    pageSize = {pageSize}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
  )
}
import React from 'react'

export const EncabezadoFormulario = ({
    Titulo, btnAdicional, setIsOpen
}) => {
    let isVisible = 'd-none';
    let btnTitulo = 'Adicional';
    let btnIcono = 'fa-question';
    let btnColor = 'btn-success';
    let btnCall = null;

    if(btnAdicional){
        isVisible = '';
        btnTitulo = btnAdicional.titulo;
        btnIcono = btnAdicional.icono;
        btnColor = btnAdicional.color;
        btnCall = btnAdicional.call;
    }

    const handlerClose = (e) => {
        //window.history.back();
        setIsOpen(false);
    }

  return (
    <>
        <h3>{ Titulo }
            <button 
                type="button" 
                className="btn-close float-end" 
                aria-label="Close" 
                onClick={ handlerClose } 
                title='Cerrar formulario'></button>
            <button type="button" className={`btn ${btnColor} rounded-pill float-end me-2 ${isVisible}`}
              onClick={ btnCall }
              title={ btnTitulo }>
              <span className={`fa-solid ${btnIcono}`}></span>
          </button>
        </h3>
        <hr />
    </>
  )
}

import React from 'react'
import { Routes, Route, BrowserRouter } from "react-router-dom";

import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import { AccesoRoutes } from './AccesoRoutes';
import { LayoutRoutes } from './LayoutRoutes';

import { Ambiente } from '../componentes/ui/Ambiente';
import { useCheckAuth } from '../hooks/useCheckAuth';

export const AppRouter = () => {

    const { isLoggedIn } = useCheckAuth();

  return (
    <BrowserRouter>
        <Ambiente />   
        <Routes>
            <Route path= '/login/*' element={ 
                <PublicRoutes>
                    <AccesoRoutes />
                </PublicRoutes> 
            }/>
            <Route path= '/*' element={
                <PrivateRoutes>
                    <LayoutRoutes/>                    
                </PrivateRoutes>
            } />
        </Routes>
    </BrowserRouter>
  )
}
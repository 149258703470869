import React, {useState, useEffect, useMemo} from 'react'

export const TablaCliente = ({
    Id_Usuario, dataCliente, onRemoverTienda, setPermisos
}) => {
    const [tablaCliente, setTablaCliente] = useState()
    let isVisible = 'd-none';

    if(setPermisos)
        isVisible = '';

    useEffect(() => {
        setTablaCliente( dataCliente );
    }, [dataCliente]);

    const onHandleBuscar = ({ target }) =>{
        const { name, value } = target;
        //console.log(value);
        const tablaData = dataCliente.filter((row) => row.Cliente.toUpperCase().includes(value.toUpperCase()));
        setTablaCliente(tablaData);
    }

  return (
    <>
        <div className="input-group">
            <span className="input-group-text"><i className='fa-solid fa fa-search'></i></span>
            <input className="form-control" name='buscador' onChange={ onHandleBuscar } autoComplete='off'/>
        </div>
        <table className="table table-hover table-sm mb-0">
            <thead className="table-light">
                <tr>
                <th>Codigo</th>
                <th>Tienda</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {
                    tablaCliente?.map(({Codigo_Cliente, Cliente}) => (
                        <tr key={Codigo_Cliente}>
                            <td>{Codigo_Cliente}</td>
                            <td>{Cliente}</td>
                            <td>
                                <button 
                                    className={`btn btn-danger btn-sm rounded-pill float-end me-2 mb-2 ${isVisible}`}
                                    type="button" onClick={ (e) => onRemoverTienda(e, Id_Usuario, Codigo_Cliente) }>
                                <i className='fa-solid fa-minus'></i>
                                </button>
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    </>
  )
}

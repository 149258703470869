import React from 'react'

export const UsuarioUbicacion = () => {
  return (
    <div className='my-1'>
        <table>
            <thead>
                <th></th>
                <th>Id</th>
                <th>Nombre</th>
            </thead>
            <tbody>
                
            </tbody>
        </table>
    </div>
  )
}

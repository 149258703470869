import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2'

import { pedidoEliminar, pedidoTransferirSAP } from '../../../../actions/comercial';

export const AccionPedidoPermisos = ({
    Id, Cliente, Fecha, SAP, setIdDetalle, ClienteNombre, setCliente, 
    setIsLoading, setIsOpenVisor, setIsOpenEditor, setIsOpenComentario
}) => {
  const dispatch = useDispatch();
  const { token, user } = useSelector(state => state.auth);  
  const navegate = useNavigate(); 

  let isVisible = 'd-none';
  let isVisibleSap = 'd-none';
  let usuario_sap = false;
  let digitador_pedido = false;

  //obtener los permisos especiales
  user.permiso.filter(item => item.Permiso === 'ROOT').map(filteredName => {
    usuario_sap = true;
    digitador_pedido =true;
  });

  user.permiso.filter(item => item.Permiso === 'SUPER_TMK').map(filteredName => {
    digitador_pedido =true;
  });

  user.permiso.filter(item => item.Permiso === 'DIGITA_PEDIDO').map(filteredName => {
    digitador_pedido =true;
  });

  user.permiso.filter(item => item.Permiso === 'USUARIO_SAP').map(filteredName => {
    usuario_sap = true;
  });

  /* Acciones para realizar por permisos especiales*/
  if(usuario_sap && SAP == 0){
    isVisibleSap = '';
  }

  if(digitador_pedido && SAP == 0){
    isVisible = '';
  }
  
  const handlerDesgargar = (e) =>{
    e.preventDefault();

    setIsLoading(true);
    const datos = { 
      id_usuario: user.id_usuario, id_pedido: Id
    }
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    axios.post( `${ process.env.REACT_APP_API_COMERCIAL }consulta/descargar_orden_compra`, datos )
    .then( response =>{
        let json = response.data;

        if(json.stt == "OK"){
          let imagen = JSON.parse(json.data);
          const url = `data:${ (imagen[0].Extension == 'png'? 'data:image/png' : 'data:application/pdf') };base64,${ imagen[0].Imagen }`;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
              "download",
              `${ Cliente }_${ Id }_${ Fecha }.${ imagen[0].Extension }`
          );
          document.body.appendChild(link);
          link.click();

          link.parentNode.removeChild(link);
        }else{
            Swal.fire('Pedido', json.msg, 'error');                
        }
        setIsLoading(false);
    })
    .catch( ({message}) =>{
        setIsLoading(false);
        Swal.fire('Pedido', message, 'error');            
    });
  }

  const handlerVisor = (e) =>{
    e.preventDefault();
    setIdDetalle(Id);
    setIsOpenVisor(true);
  }

  const handlerEditar = (e) =>{
    e.preventDefault();
    setIdDetalle(Id);
    setCliente(ClienteNombre);
    setIsOpenEditor(true);
  }

  const handlerEliminar = (e) =>{ 
    e.preventDefault();    
    dispatch( pedidoEliminar(token, user.id_usuario, Id, setIsLoading) );
  }

  const handlerEnviarSAP = (e) =>{
    e.preventDefault();
    setCliente(Cliente);
    //console.log(Id);
    dispatch( pedidoTransferirSAP(token, user.id_usuario, Id, "", ClienteNombre, setIsLoading) );
  }

  return (
    <div className="dropdown">
      <a 
        href='#' 
        data-bs-toggle="dropdown" 
        aria-expanded="false">
        { Id }
      </a>
      <ul className="dropdown-menu">
        <li><a className="dropdown-item" onClick={ handlerDesgargar } href="#"><i className="fa-solid fa-download"></i> Descargar</a></li>
        <li><a className="dropdown-item" onClick={ handlerVisor } href="#"><i className="fa-solid fa-eye"></i> Visor</a></li> 
        <li className={ isVisibleSap }>
          <hr className='mb-1 mt-1'/>
          <a className="dropdown-item" onClick={ handlerEnviarSAP } href="#"><i className="fa-solid fa-shuffle"></i> Enviar a SAP</a>
        </li>
        <li className={ isVisible }>
        <hr className='mb-1 mt-1'/>
          <a className="dropdown-item" onClick={ handlerEditar } href="#"><i className="fa-solid fa-pen-to-square"></i> Modificar</a>
        </li>
        <li className={ isVisible }><a className="dropdown-item" onClick={ handlerEliminar } href="#"><i className="fa-solid fa-trash-can"></i> Eliminar</a></li>
      </ul>     
    </div>
  )
}

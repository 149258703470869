import React from 'react'
import { Routes, Route } from "react-router-dom";

import { UsuarioScreen } from '../componentes/view/seguridad/usuario/UsuarioScreen';
import { UsuarioFormulario } from '../componentes/view/seguridad/usuario/UsuarioFormulario';
import { MiCuenta } from '../componentes/view/seguridad/mi_perfil/MiCuenta';

export const SeguridadRoutes = () => {

  return (
    <Routes>            
      <Route path="/mi/perfil" element={ <MiCuenta /> } />
      <Route path="/usuario" element={ <UsuarioScreen /> } />
      <Route path="/usuario/:id/:modo" element={ <UsuarioFormulario /> } />
      <Route path="/usuario/:modo" element={ <UsuarioFormulario /> } />
    </Routes>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { useForm } from '../../../hooks/useForm';
import { startLogin } from '../../actions/auth';
import { Loading } from '../../ui/Loading';
import './login.css'

export const LoginScreen = () => {

  const navegate = useNavigate();
  const dispatch = useDispatch();
  const [ Imagen, setImagen ] = useState(1);
  const { isLoggedIn } = useSelector(state => state.auth);
  const [isVisiblePass, setIsVisiblePass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableLogin, setIsDisableLogin] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    setIsDisableLogin(false);
  }, [setIsLoading, setIsDisableLogin])

  useEffect(() => {
    setImagen( Math.floor(Math.random() * 3) + 1 );
  }, [setImagen])
  

  const {usuario, clave, onInputChange} = useForm({
    usuario: '', clave: ''
  });

  const handleLogin = () => {

    if (usuario.length < 1 || clave.length < 1) {
      Swal.fire('Login', 'usuario y contraseña son requeridos', 'warning');
    } else {
      setIsLoading(true);
      setIsDisableLogin(true);
      dispatch(startLogin(usuario, clave, setIsLoading, setIsDisableLogin));

      if (isLoggedIn) {
        const lastPath = localStorage.getItem('lastPath') || '/';
        //console.log('login screen =>', localStorage.getItem('lastPath'));

        setIsLoading(false);
        setIsDisableLogin(false);
        navegate(lastPath, {
          replace: true
        });
      }
    }
  }

  const handleVisiblePass = () => {
    if (isVisiblePass) {
      document.getElementById('icono_pw').className = 'fas fa-eye';
      document.getElementById('clave').type = 'text';
      setIsVisiblePass(false);
    } else {
      document.getElementById('icono_pw').className = 'fas fa-eye-slash';
      document.getElementById('clave').type = 'password';
      setIsVisiblePass(true);
    }
  }

  return (
    <>
      <section>
        <Loading isLoading={isLoading} />
        <div className="container-fluid">
          <div className="row">
            <div id='bg-img-cover' className="col-xl-7 order-1 b-center bg-size" 
              style={ {backgroundImage: 'url(../assets/images/bg/login/' + Imagen + '.jpg)'} }></div>
            <div className="col-xl-5 p-0">
              <div className="login-card">
                <form className="theme-form login-form needs-validation">
                  <h4>Bienvenid@</h4>
                  <h5>Inicio de sesi&oacute;n</h5>
                  <div className="form-group">
                    <label>Usuario</label>
                    <div className="input-group"><span className="input-group-text">
                      <i className="fa-solid fa-user"></i></span>
                      <input
                        name="usuario"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={usuario}
                        onChange={onInputChange}
                        placeholder="Usuario" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Contrase&ntilde;a</label>
                    <div className="input-group"><span className="input-group-text">
                      <i className="fa-solid fa-lock"></i></span>
                      <input
                        id="clave"
                        name="clave"
                        type="password"
                        className="form-control"
                        autoComplete="off"
                        value={clave}
                        onChange={onInputChange}
                        placeholder="Contraseña" />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="button"
                      onClick={handleLogin}
                      value="Iniciar sesión"
                      className="btn btn-success w-100"
                      disabled={isDisableLogin} />
                  </div>
                  <div className="login-social-title">
                    <h5></h5>
                  </div>
                  <br/><br/>
                  <p>Fabrica de Productos Lacteos Parma, S.A.</p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

import React from 'react'
import '../style/loading.css'

export const Loading = ( { isLoading } ) => {
  
  return (

    <div className={'loader-container ' + (isLoading? 'd-block': 'd-none')}>
        <div className="loader"></div>
        <div className="loader2"></div>
    </div>

  )
}

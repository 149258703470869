import axios from 'axios';
import Swal from 'sweetalert2'

import { types } from "../types/types";
import { finishLoading, startLoading } from "./ui";

export const MisDatosModificar = (token, id_usuario, nombre, apellido, telefono, correo, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario,
            nombre: nombre, apellido: apellido, telefono: telefono, correo: correo
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_CONFIGURACION + 'registro/actualizar/mis_datos', datos )
            .then( response => {
                let json = response.data;
                
                if(json.stt != 'OK'){
                    Swal.fire('Mis Datos', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;

                    dispatch({ 
                        type: types.miDatoModificar, 
                        data: dato,
                        ref: new Date
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{
                setIsLoading(false);
                if(response.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Mis Datos', message, 'error');
                }
            });
    }
}

export const MiCuentaClaveModificar = (token, id_usuario, clave, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, clave: clave
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_CONFIGURACION + 'registro/actualizar/mi_cuenta_clave', datos )
            .then( response => {
                let json = response.data;
                
                if(json.stt != 'OK'){
                    Swal.fire('Mis Datos', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    
                    dispatch({ 
                        type: types.miDatoModificar, 
                        data: dato,
                        ref: new Date
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{
                setIsLoading(false);
                if(response.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Mis Datos', message, 'error');
                }
            });
    }
}

export const MiCuentaSAPClaveModificar = (token, id_usuario, clave_sap, setIsLoading) => {
    return (dispatch) => {

        dispatch( startLoading() );
        setIsLoading(true);

        const datos = { 
            id_usuario: id_usuario, clave: clave_sap
        }
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( process.env.REACT_APP_API_CONFIGURACION + 'registro/actualizar/mi_cuenta_clave_sap', datos )
            .then( response => {
                let json = response.data;
                
                if(json.stt != 'OK'){
                    Swal.fire('Mis Datos', json.msg, 'warning');
                    setIsLoading(false);
                }else{
                    const dato = json.data;
                    
                    dispatch({ 
                        type: types.miDatoModificar, 
                        data: dato,
                        ref: new Date
                    });
                }
                setIsLoading(false);
            })
            .catch( ({response, message}) =>{
                setIsLoading(false);
                if(response.status == 401){
                    Swal.fire('Sesión', "La sesión a expirado, debe iniciar nuevamente", 'warning');
                }else{
                    Swal.fire('Mis Datos', message, 'error');
                }
            });
    }
}
import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Pagination, MenuItem, Select, ButtonGroup, CircularProgress } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';

function SaleProductsDay() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [filtroTexto, setFiltroTexto] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(50);
  const [comboValue, setComboValue] = useState(""); // Para el combo box
  const [loading, setLoading] = useState(false); // Estado de cargando
  const { token, user } = useSelector(state => state.auth);

  const tiendas = [
    { id: 1, nombre: 'TZ10' },
    { id: 2, nombre: 'TPRR' },
    { id: 3, nombre: 'TSCR' },
    { id: 4, nombre: 'TCSV' },
    { id: 5, nombre: 'TMIR' },
    { id: 6, nombre: 'TXLA' }
  ];

  const tiendasFiltradas = tiendas.filter(tienda => user.usuario.toLowerCase().includes(tienda.nombre.toLowerCase()));

  const fetchData = () => {
    setLoading(true); // Activar el estado de cargando
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "2155",
        UserNickName: user.usuario
      },
      DatosMetodo: {
        NombreMetodo: "ListaReportesSalas",
        Datos_Recibidos: `{"Vista":"ReporteVentas", "FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    };
    
    console.log(datos);
    
    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
    
    fetch(fetchDataUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error en la respuesta: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        try {
          console.log(data);
          const datosGeneradosLimpiados = data.datos_Generados.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
          const datosGeneradosDecodificados = JSON.parse(datosGeneradosLimpiados);
  
          console.log(datosGeneradosDecodificados);
          setDatosGenerados(datosGeneradosDecodificados);
        } catch (error) {
          console.error("Error al decodificar los datos generados:", error);
        } finally {
          setLoading(false); // Desactivar el estado de cargando
        }
      })
      .catch(error => {
        console.error('Error en la petición:', error);
        setLoading(false); // Desactivar el estado de cargando en caso de error
      });
  };
  

  const handleSearch = () => {
    if (!fechaInicio || !fechaFin) {
      alert("Por favor selecciona ambas fechas.");
      return;
    }
    if (new Date(fechaInicio) > new Date(fechaFin)) {
      alert("La fecha de inicio no puede ser mayor a la fecha de fin.");
      return;
    }
    fetchData();
  };
  

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const limpiarJSON = (jsonString) => {
    if (typeof jsonString === 'string') {
      return jsonString.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
    }
    return ""; // Devuelve una cadena vacía si el valor no es un string válido
  };
  

  const agruparProductos = (productos) => {
    const productosAgrupados = {};

    productos.forEach(prod => {
      if (prod.Monto > 0) {
        if (!productosAgrupados[prod.Codigo]) {
          productosAgrupados[prod.Codigo] = { ...prod };
        } else {
          productosAgrupados[prod.Codigo].UnidadesVendidas += prod.UnidadesVendidas;
          productosAgrupados[prod.Codigo].Monto += prod.Monto;
        }
      }
    });

    return Object.values(productosAgrupados);
  };

  const esJSONValido = (jsonString) => {
    try {
      if (typeof jsonString === 'string' && jsonString.trim() !== '') {
        JSON.parse(jsonString);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
  
  const filteredData = datosGenerados.flatMap(item => {
    try {
      // Verificamos si Detalle está definido y es un JSON válido
      if (!item.Detalle || !esJSONValido(item.Detalle)) {
        console.error('Detalle inválido o vacío para el siguiente item:', item);
        return []; // Retorna un array vacío si no es válido
      }
  
      // Si el JSON es válido, procedemos a parsearlo
      const detalle = JSON.parse(limpiarJSON(item.Detalle)).DETALLE || [];
      const productosFiltrados = detalle.filter(prod => {
        const searchValue = filtroTexto.toLowerCase();
        return (
          prod.ItemCode.toLowerCase().includes(searchValue) ||
          prod.Dscription.toLowerCase().includes(searchValue)
        );
      }).map(prod => {
        const partesFecha = item.DocDate.split('/');
        const fechaFormateada = `${partesFecha[0]}/${partesFecha[1]}/${partesFecha[2]}`;
        return {
          Caja: item.Caja,
          Codigo: prod.ItemCode,
          Descripcion: prod.Dscription,
          UnidadesVendidas: prod.Cantidad,
          Monto: prod.Total_Neto,
          Fecha: fechaFormateada,
          Sala: item.Sala
        };
      });
  
      const productosFiltradosPorSala = productosFiltrados.filter(prod => {
        if (!comboValue) return true;
        return prod.Sala === comboValue;
      });
  
      return agruparProductos(productosFiltradosPorSala);
  
    } catch (error) {
      console.error("Error al procesar el detalle:", error);
      return [];
    }
  });
  

  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("REPORTE VENTAS POR PRODUCTO POR DIA", 20, 10);
    doc.autoTable({
      head: [['Caja', 'Código de Producto', 'Descripción', 'Unidades Vendidas', 'Monto', 'Fecha']],
      body: filteredData.map(row => [
        row.Caja,
        row.Codigo,
        row.Descripcion,
        row.UnidadesVendidas,
        row.Monto.toFixed(2),
        new Date(row.Fecha).toLocaleDateString()
      ]),
    });
    doc.save('ventas-por-producto.pdf');
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ReporteVentas");
    XLSX.writeFile(workbook, 'ventas-por-producto.xlsx');
  };

  return (
    <Container>
      <h1>VENTAS POR PRODUCTO</h1>
      <hr />
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          id="filtroTexto"
          label="Buscar"
          value={filtroTexto}
          onChange={(e) => setFiltroTexto(e.target.value)}
          variant="outlined"
          style={{ width: '400px' }}
        />

        <Select
          value={comboValue}
          onChange={(e) => setComboValue(e.target.value)}
          displayEmpty
          style={{ width: '150px' }}
        >
          {tiendasFiltradas.length === 0 && <MenuItem value="">Todas las Tiendas</MenuItem>}
          {tiendasFiltradas.length > 0 ? (
            tiendasFiltradas.map(tienda => (
              <MenuItem key={tienda.id} value={tienda.nombre}>{tienda.nombre}</MenuItem>
            ))
          ) : (
            tiendas.map(tienda => (
              <MenuItem key={tienda.id} value={tienda.nombre}>{tienda.nombre}</MenuItem>
            ))
          )}
        </Select>

        <TextField
          id="fechaInicio"
          label="Fecha de Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          style={{ marginLeft: '10px' }}
        />
        <TextField
          id="fechaFin"
          label="Fecha de Fin"
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          style={{ marginLeft: '10px' }}
        />
        <Button
          onClick={handleSearch}
          variant="contained"
          color="primary"
          style={{ marginLeft: '10px' }}
        >
          Buscar
        </Button>
        <ButtonGroup style={{ marginLeft: '10px' }}>
          <Button onClick={exportToPDF} variant="contained" color="warning" style={{marginLeft: "4px", marginRight: "4px"}}> PDF</Button>
          <Button onClick={exportToExcel} variant="contained" color="success"> Excel</Button>
        </ButtonGroup>
      </div>

      {loading ? ( // Mostrar el loader si está cargando
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
          <p style={{ marginLeft: '10px' }}>Cargando...</p>
        </div>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Caja</TableCell>
                <TableCell>Código de Producto</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Unidades Vendidas</TableCell>
                <TableCell>Monto</TableCell>
                <TableCell>Fecha</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datosPaginados.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.Caja}</TableCell>
                  <TableCell>{row.Codigo}</TableCell>
                  <TableCell>{row.Descripcion}</TableCell>
                  <TableCell>{row.UnidadesVendidas}</TableCell>
                  <TableCell>{row.Monto}</TableCell>
                  <TableCell>{row.Fecha}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(filteredData.length / elementosPorPagina)}
            page={paginaActual}
            onChange={handlePageChange}
            color="primary"
            style={{ marginTop: '20px', justifyContent: 'center', display: 'flex' }}
          />
        </>
      )}
    </Container>
  );
}

export default SaleProductsDay;

import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Pagination, MenuItem, Select, ButtonGroup } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

function ProductForDate() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [filtroTexto, setFiltroTexto] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(50);
  const [comboValue, setComboValue] = useState("");

  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaReportesSalas",
        Datos_Recibidos: `{"Vista":"ReporteVentas", "FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    }; 

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;

    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        try {
          const datosGeneradosLimpiados = data.datos_Generados.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
          const datosGeneradosDecodificados = JSON.parse(datosGeneradosLimpiados); 
          setDatosGenerados(datosGeneradosDecodificados); 
          console.log(datosGeneradosDecodificados);
        } catch (error) {
          console.error("Error al decodificar los datos generados:", error);
        }
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, [fechaInicio, fechaFin]);

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const limpiarJSON = (jsonString) => {
    return jsonString.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
  };

  const filteredData = datosGenerados.flatMap(item => {
    try {
      const detalle = JSON.parse(limpiarJSON(item.Detalle)).DETALLE || [];
      return detalle.filter(prod => {
        const searchValue = filtroTexto.toLowerCase();
        console.log("Combo Value:", comboValue);
        console.log("Item Sala:", item.Sala);
        
        // Asegúrate de que item.Sala sea un número o conviértelo al tipo correcto
        const isTiendaValid = comboValue ? item.Sala.toString() === comboValue : true; // Filtra por sala
  
        return (
          (prod.ItemCode.toLowerCase().includes(searchValue) || prod.Dscription.toLowerCase().includes(searchValue)) &&
          isTiendaValid
        );
      }).map(prod => {
        const partesFecha = item.DocDate.split('/');
        const fechaFormateada = `${partesFecha[0]}/${partesFecha[1]}/${partesFecha[2]}`;
  
        return {
          Caja: item.Caja,
          Codigo: prod.ItemCode,
          Descripcion: prod.Dscription,
          UnidadesVendidas: prod.Cantidad,
          Costo: prod.Costo || 0,
          Fecha: fechaFormateada,
          Sala: item.Sala
        };
      });
    } catch (error) {
      console.error("Error al procesar el detalle:", error);
      return [];
    }
  });
  

  // Agrupar y sumar unidades vendidas por código
  const groupedData = filteredData.reduce((acc, curr) => {
    const existing = acc.find(item => item.Codigo === curr.Codigo);
    if (existing) {
      console.log(existing);
  
      // Convertir a número antes de sumar
      existing.UnidadesVendidas = (parseFloat(existing.UnidadesVendidas) || 0) + (parseFloat(curr.UnidadesVendidas) || 0);
    } else {
      // Convertir a número al agregar un nuevo producto
      acc.push({ ...curr, UnidadesVendidas: parseFloat(curr.UnidadesVendidas) || 0 });
    }
    return acc;
  }, []);
  

  // Paginación
  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = groupedData.slice(indexPrimerElemento, indexUltimoElemento);

  // Función para generar y descargar PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
  
    // Configura el encabezado del PDF
    doc.text("REPORTE PRODUCTOS POR FECHA", 20, 10);
  
    // Configura la tabla con autoTable
    doc.autoTable({
      head: [['Caja', 'Código de Producto', 'Descripción', 'Unidades Vendidas', 'Fecha']],
      body: datosPaginados.map(row => [
        row.Caja,
        row.Codigo,
        row.Descripcion,
        row.UnidadesVendidas,
        row.Sala,
        new Date(row.Fecha).toLocaleDateString()
      ]),
    });
  
    // Descarga el PDF
    doc.save('consumo-productos.pdf');
  };

  // Función para exportar a Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(datosPaginados);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ReporteProductos");
    XLSX.writeFile(workbook, 'consumo-productos.xlsx');
  };

  return (
    <Container>
      <h1>Consumo por Producto </h1> 
      <hr />
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          id="filtroTexto"
          label="Buscar"
          value={filtroTexto}
          onChange={(e) => setFiltroTexto(e.target.value)}
          variant="outlined"
          style={{ width: '400px' }}
        />
        
        <Select
          value={comboValue}
          onChange={(e) => setComboValue(e.target.value)}
          displayEmpty
          style={{ width: '150px', marginLeft: '10px' }}
        >
          <MenuItem value="">Todas las Tiendas</MenuItem>
          <MenuItem value={"TZ10"}>TZ10</MenuItem>
          <MenuItem value={"TPRR"}>TPRR</MenuItem>
          <MenuItem value={"TSCR"}>TSCR</MenuItem>
          <MenuItem value={"TCSV"}>TCSV</MenuItem>
          <MenuItem value={"TMIR"}>TMIR</MenuItem>
          <MenuItem value={"TXLA"}>TXLA</MenuItem>
        </Select>

        <TextField
          id="fechaInicio"
          label="Fecha de Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          style={{ marginLeft: '10px' }}
        />
        <TextField
          id="fechaFin"
          label="Fecha de Fin"
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          style={{ marginLeft: '10px' }}
        />
        <ButtonGroup>
          <Button variant="contained" onClick={handleSearch} style={{ marginLeft: '10px', height: '55px', width: '100px' }}>
            Buscar
          </Button> 

          <Button variant="contained" onClick={exportToPDF} style={{ marginRight: '4px', marginLeft: '4px' }} color='warning'>
            PDF
          </Button>
          <Button variant="contained" onClick={exportToExcel} color='success'>
            Excel
          </Button> 
        </ButtonGroup>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Caja</TableCell>
            <TableCell>Código de Producto</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Unidades Vendidas</TableCell>
            <TableCell>Fecha</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosPaginados.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.Caja}</TableCell>
              <TableCell>{item.Codigo}</TableCell>
              <TableCell>{item.Descripcion}</TableCell>
              <TableCell>{item.UnidadesVendidas}</TableCell>
              <TableCell>{item.Fecha}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        count={Math.ceil(groupedData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px' }}
      />
    </Container>
  );
}

export default ProductForDate;

import React from 'react'
import { Provider } from 'react-redux';

import { store } from './componentes/store/store'
import { AppRouter } from './routes/AppRouter'

export const AppParma = () => {

  return (
    <Provider store={ store }>
      <AppRouter />
    </Provider>
  );
}

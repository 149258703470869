import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

const EntregasInv = () => {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [filtroTexto, setFiltroTexto] = useState('');
  const [filtroTienda, setFiltroTienda] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const setTiendas = ["TZ10", "TPRR", "TSCR", "TCSV", "TMIR", "TXLA"];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const fetchData = async () => {
    try {
      const datos = {
        Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
        Ambiente: "DESA",
        Dispositivo: {
          Marca: "SAMSUNG",
          Modelo: "S22002B",
          Version: "Android 18.125",
          McAdress: null,
          PowerStatus: "84%",
          WifiStatus: "Conected to iphone de Mauricio",
          GPSStatus: "ON",
          GPS: "321|-485|321"
        },
        Usr: {
          Codigo: "",
          UserNickName: "pierril"
        },
        DatosMetodo: {
          NombreMetodo: "ListaReportesSalas",
          Datos_Recibidos: `{"Vista":"EntregasPendientes", "FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
        }
      };

      const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
      const response = await fetch(fetchDataUrl);
      if (!response.ok) {
        throw new Error('Error al obtener los datos');
      }
      const data = await response.json();

      if (data && data.datos_Generados) {
        try {
          const datosGeneradosLimpiados = typeof data.datos_Generados === 'string' 
            ? JSON.parse(data.datos_Generados) 
            : data.datos_Generados;
          setDatosGenerados(datosGeneradosLimpiados);
        } catch (error) {
          console.error("Error al parsear datos generados:", error);
          setDatosGenerados([]);
        }
      } else {
        console.error("No se recibieron datos generados válidos:", data);
        setDatosGenerados([]);
      }
      
    } catch (error) {
      console.error("Error al obtener datos:", error);
      setDatosGenerados([]);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Entregas Pendientes', 14, 16);
    
    autoTable(doc, {
      head: [['Fecha', 'Tienda', 'Serie', 'Doc Entry Fact', 'Doc Num Fact', 'Trans ID', 'Doc Entry Entrega', 'Doc Num Entrega']],
      body: datosGenerados.filter(item => {
        const textoCoincide = item.Tienda.toLowerCase().includes(filtroTexto.toLowerCase());
        const tiendaCoincide = filtroTienda ? item.Tienda === filtroTienda : true;
        const fechaCoincide = (fechaInicio && fechaFin) ? 
          new Date(item.DocDate) >= new Date(fechaInicio) && new Date(item.DocDate) <= new Date(fechaFin) : true;
        return textoCoincide && tiendaCoincide && fechaCoincide;
      }).map(row => [
        new Date(row.DocDate).toLocaleDateString(),
        row.Tienda,
        row.Series,
        row.DocEntry_Fact,
        row.DocNum_Fact,
        row.TransId,
        row.DocEntry_Entrega,
        row.DocNum_Entrega
      ]),
    });

    doc.save('entregas_pendientes.pdf');
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(datosGenerados.filter(item => {
      const textoCoincide = item.Tienda.toLowerCase().includes(filtroTexto.toLowerCase());
      const tiendaCoincide = filtroTienda ? item.Tienda === filtroTienda : true;
      const fechaCoincide = (fechaInicio && fechaFin) ? 
        new Date(item.DocDate) >= new Date(fechaInicio) && new Date(item.DocDate) <= new Date(fechaFin) : true;
      return textoCoincide && tiendaCoincide && fechaCoincide;
    }));
    
    XLSX.utils.book_append_sheet(wb, ws, 'Entregas Pendientes');
    XLSX.writeFile(wb, 'entregas_pendientes.xlsx');
  };

  useEffect(() => {
    fetchData(); 
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Resetear a la primera página al cambiar el número de filas por página
  };

  const filteredData = datosGenerados.filter(item => {
    const textoCoincide = item.Tienda.toLowerCase().includes(filtroTexto.toLowerCase());
    const tiendaCoincide = filtroTienda ? item.Tienda === filtroTienda : true;
    const fechaCoincide = (fechaInicio && fechaFin) ? 
      new Date(item.DocDate) >= new Date(fechaInicio) && new Date(item.DocDate) <= new Date(fechaFin) : true;
    return textoCoincide && tiendaCoincide && fechaCoincide;
  });

  return (
    <div>

      <h1>Entregas</h1> 
      <hr />
      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={2}>
          <TextField
            id="filtroTexto"
            label="Buscar"
            value={filtroTexto}
            onChange={(e) => setFiltroTexto(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            label="Tienda"
            value={filtroTienda}
            onChange={(e) => setFiltroTienda(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Todas las tiendas</MenuItem>
            {setTiendas.map((tienda, index) => (
              <MenuItem key={index} value={tienda}>{tienda}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="fechaInicio"
            label="Fecha de Inicio"
            type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="fechaFin"
            label="Fecha de Fin"
            type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" onClick={handleSearch}>
            Buscar
          </Button>
          <Button variant="contained" onClick={exportToPDF} style={{ marginLeft: "4px", marginRight: "4px" }} color='warning'>
            PDF
          </Button>
          <Button variant="contained" onClick={exportToExcel} color='success'>
            Excel
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Tienda</TableCell>
              <TableCell>Serie</TableCell>
              <TableCell>Doc Entry Fact</TableCell>
              <TableCell>Doc Num Fact</TableCell>
              <TableCell>Trans ID</TableCell>
              <TableCell>Doc Entry Entrega</TableCell>
              <TableCell>Doc Num Entrega</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <TableRow key={index}>
                <TableCell>{new Date(row.DocDate).toLocaleDateString()}</TableCell>
                <TableCell>{row.Tienda}</TableCell>
                <TableCell>{row.Series}</TableCell>
                <TableCell>{row.DocEntry_Fact}</TableCell>
                <TableCell>{row.DocNum_Fact}</TableCell>
                <TableCell>{row.TransId}</TableCell>
                <TableCell>{row.DocEntry_Entrega}</TableCell>
                <TableCell>{row.DocNum_Entrega}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default EntregasInv;

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de haber importado Bootstrap CSS
import { useParams } from 'react-router-dom'; 
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2'

const DashboardOM = () => { 

    let { orderId } = useParams(); 
    const [orderData, setOrderData] = useState(null); 
    const [comboData1, setComboData1] = useState(null); 
    const [comboData2, setComboData2] = useState(null); 
    const [comboData3, setComboData3] = useState(null); 
    const [tipoPago, setTipoPago] = useState('');
    const [banco, setBanco] = useState('');
    const [metodo, setMetodo] = useState(''); // Para determinar si es transferencia o depósito
    const [numeroReferencia, setNumeroReferencia] = useState(''); 
    const [codigoAutorizacion, setCodigoAutorizacion] = useState('');
    const [monto, setMonto] = useState('');
    const [usuario, setUsuario] = useState(''); 
    const [base64Image, setBase64Image] = useState(null); 
    const [fileExtension, setFileExtension] = useState(null); 
    const [codigoabonoimg, setCodigoabonoimg] = useState(null);  
    const [metodoPago, setMetodoPago] = useState('');
    const dispatch = useDispatch();
    const { token, user } = useSelector(state => state.auth);  
    const navegate = useNavigate(); 
  
    useEffect(() => {
      const fetchData = async () => { 
          console.log(user); 

          try {
              const datosMetodo1 = {
                  NombreMetodo: "BuscarInfoOMEspecifico",
                  Datos_Recibidos: `{"FECHAINI":"06/05/2024","FECHAFIN":"06/05/2024","CODOV":"${orderId}"}`
              };
  
              const datos1 = {
                  Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
                  Ambiente: "DESA",
                  Dispositivo: {
                      Marca: "SAMSUNG",
                      Modelo: "S22002B",
                      Version: "Android 18.125",
                      McAdress: null,
                      PowerStatus: "84%",
                      WifiStatus: "Conected to iphone de Mauricio",
                      GPSStatus: "ON",
                      GPS: "321|-485|321"
                  },
                  Usr: {
                      Codigo: "",
                      UserNickName: "pierril"
                  },
                  DatosMetodo: datosMetodo1
              };
  
              const datosMetodo2 = {
                  NombreMetodo: "ListaCombosAbono",
                  Datos_Recibidos: "",
                  Datos_Generados: ""
              };
  
              const datos2 = {
                  Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
                  Ambiente: "DESA",
                  Dispositivo: {
                      Marca: "SAMSUNG",
                      Modelo: "S22002B",
                      Version: "Android 18.125",
                      McAdress: "22222",
                      PowerStatus: "84%",
                      WifiStatus: "Conected to iphone de Mauricio",
                      GPSStatus: "ON",
                      GPS: "321|-485|321"
                  },
                  Usr: {
                      Codigo: "",
                      UserNickName: "pierril"
                  },
                  DatosMetodo: datosMetodo2
              };
  
              const fetchDataUrl1 = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos1))}`;
              const fetchDataUrl2 = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos2))}`;
  
              const [response1, response2] = await Promise.all([
                  fetch(fetchDataUrl1),
                  fetch(fetchDataUrl2)
              ]);
  
              const data1 = await response1.json();
              const data2 = await response2.json();
  
              const parsedData1 = await JSON.parse(data1.datos_Generados);
              const parsedData2 = await JSON.parse(data2.datos_Generados);
  
              console.log(parsedData1);
              console.log(parsedData2);
  
              setOrderData(parsedData1); 
              
              // Puedes manejar los datos según sea necesario 

              const abonoOptions = parsedData2.LISTACOMBO.filter(item => item.TipoCombo === 'Abono');

              const bancoOptions = parsedData2.LISTACOMBO.filter(item => item.TipoCombo === 'Banco');  

              const medioPagoOptions = parsedData2.LISTACOMBO.filter(item => item.TipoCombo === 'MedioPago');  
              

              setComboData1(abonoOptions); 
              setComboData2(bancoOptions); 
              setComboData3(medioPagoOptions); 
              
              console.log(medioPagoOptions);// Almacena los combos en otro estado
  
          } catch (error) {
              console.error('Error fetching data:', error);
          }
      };
  
      fetchData();
  }, [orderId]); 

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Extraer la extensión del archivo
      const extension = file.name.split('.').pop();
      setFileExtension(extension); // Guarda la extensión en el estado

      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result); // Guarda la imagen en base64
      };

      reader.readAsDataURL(file); // Lee el archivo como base64
    }
  };
  const handleSubmit = () => { 
    const codigoOrderId = parseInt(orderId.replace(/\D/g, ''), 10); 

    console.log(codigoOrderId)
    if (base64Image) {
      console.log("Imagen en Base64:", base64Image); 
      console.log('extension of base;', fileExtension)
      // Aquí puedes enviar la imagen a tu API o procesarla como necesites.
    } else {
      console.log("No se ha cargado ninguna imagen");
    }


    const idusuarioenvia = user.id_usuario 

    console.log(idusuarioenvia)

    console.log(metodoPago);  
    let metodoPagoEntero = parseInt(metodoPago, 10);

    const datosRecibidos = {
      AcovTipoabono:  parseInt(tipoPago, 10), // Aquí seleccionas el valor de tipo de abono
      AcovTipoReferencia: metodoPagoEntero,
      AcovNoReferencia: numeroReferencia,
      AcovCodov: codigoOrderId, // Código de orden (ejemplo)
      AcovMonto: parseFloat(monto),
      AcovValidado: 1,
      AcovUsuario:  parseInt(idusuarioenvia,10),
      AcovCodBanco: parseInt(banco,10) 
    };
console.log(datosRecibidos)
    const payload = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      User: {
        Codigo: "2165",
        UserNickName: "pierril"
      },
      Metodo: {
        NombreMetodo: "CrearAbono",
        Datos_Recibidos: JSON.stringify(datosRecibidos),
        Datos_Generados: ""
      }
    }; 

    console.log(payload)

    fetch('https://appcorp.parma.com.gt/API/ReceptorDatos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);  
        
        const datosGenerados = JSON.parse(data.datos_Generados); // Convierte la cadena a un array de objetos

        if (datosGenerados && datosGenerados.length > 0) {
            const codigoAbono = datosGenerados[0].CodigoAbono; // Extraer el CodigoAbono del primer objeto
            console.log("Código del Abono:", codigoAbono);   
            setCodigoabonoimg(codigoAbono)  

            if(codigoAbono){
              handleSumbmitAffter(codigoAbono);
            }else{
              console.log('no se pudo')
            }
            
            
            // Imprimir el código del abono
        } else {
            console.log("No se encontró ningún Código de Abono.");
        }

        
        

        // Aquí podrías agregar lógica para manejar la respuesta
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }; 

  const handleSumbmitAffter = (codigoAbono) => {
    console.log(codigoAbono); 

    const soloBase64 = base64Image.split('base64,')[1].trim();

    console.log(soloBase64);
  
    const datosRecibidos = {
      Codigo: codigoAbono.toString(), // Usamos el código pasado como argumento
      Imagen: soloBase64, 
      Extension: fileExtension
    };
  
    console.log(datosRecibidos);
  
    const payload = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      User: {
        Codigo: "2165",
        UserNickName: "pierril"
      },
      Metodo: {
        NombreMetodo: "CargarImagenAbono",
        Datos_Recibidos: JSON.stringify(datosRecibidos),
        Datos_Generados: ""
      }
    };
  
    console.log(payload);
  
    fetch('https://appcorp.parma.com.gt/API/ReceptorDatos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data); 
        alert('ABONO CREADO EXITOSAMENTE, N0. ABONO ' + codigoAbono)
        // Aquí podrías agregar lógica para manejar la respuesta
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  return (
    <div className="container">
      <header className="p-3 border-bottom">
        {/* Contenido del encabezado */}
      </header>

      {/* Detalles de la orden y otro contenido aquí */}
      <div className="p-3 border-bottom d-flex justify-content-between align-items-center">
        <h5 className="card-title"><b>No de orden: {orderId}</b></h5>
        <button 
        type="button" 
        className="btn btn-danger btn-lg" 
        data-bs-toggle="modal" 
        data-bs-target="#exampleModal3"
        style={{ fontSize: '1.2rem' }} 
        >
        <i className="fas fa-money-bill-wave"></i> Pago Anticiapdo
      </button>
      </div>

      <div className="card" style={{ width: '100%' }}>
        <div className="card-body">
          <h5 className="card-title">Datos Generales</h5>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <h6 className="card-subtitle mb-2 text-muted">Datos del Cliente</h6>
                {orderData && orderData[0] && orderData[0].Cliente && (
                  <p className="card-text">
                    <b>Nombre:</b> {orderData[0].Cliente.Nombre} <br/>
                    <b>Contacto Créditos:</b> {orderData[0].Cliente.ContactoCreditos}<br/>
                    <b>Contacto Ventas:</b> {orderData[0].Cliente.ContactoVentas} <br/>
                  </p>
                )}
              </div>
              <div className="col-md-6">
                <h6 className="card-subtitle mb-2 text-muted">Datos de Envío</h6>
                {orderData && orderData[0] && orderData[0].Cliente && (
                  <p className="card-text">
                    <b>Dirección Fiscal:</b> {orderData[0].Cliente.DireccionRS} <br/>
                    <b>Dirección Entrega:</b> {orderData[0].Cliente.DireccionEN}<br/>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
              {/* Modal */}
              <div className="modal fade" id="exampleModal3" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Pago</h5>
        <button 
          type="button" 
          className="btn-close" 
          data-bs-dismiss="modal" 
          aria-label="Close">
        </button>
      </div>
      <div className="modal-body">
        {/* Campo 1: Valor a pagar (Combobox) */}
        <div className="mb-3">
          <label htmlFor="tipoPago" className="form-label">Valor a Pagar</label>
          <select
            id="tipoPago"
            className="form-select"
            value={tipoPago}
            onChange={(e) => setTipoPago(e.target.value)}
          >
            <option value="">Seleccione...</option>
            {comboData1 && comboData1.length > 0 ? (
              comboData1.map((abono) => (
                <option key={abono.Codigo} value={abono.Codigo}>
                  {abono.Descripcion}
                </option>
              ))
            ) : (
              <option value="">Cargando opciones...</option>
            )}
          </select>
        </div>

        {/* Campo 2: Banco (Combobox) */}
        <div className="mb-3">
          <label htmlFor="Banco" className="form-label">Banco</label>
          <select
            id="banco"
            className="form-select"
            value={banco}
            onChange={(e) => setBanco(e.target.value)}
          >
            <option value="">Seleccione...</option>
            {comboData2 && comboData2.length > 0 ? (
              comboData2.map((banco) => (
                <option key={banco.Codigo} value={banco.Codigo}>
                  {banco.Descripcion}
                </option>
              ))
            ) : (
              <option value="">Cargando opciones...</option>
            )}
          </select>
        </div>

        {/* Nuevo Combobox para método de pago */}
        <div className="mb-3">
          <label htmlFor="metodoPago" className="form-label">Método de Pago</label>
          <select
            id="metodoPago"
            className="form-select"
            value={metodoPago}
            onChange={(e) => setMetodoPago(e.target.value)}
          >
            <option value="">Seleccione...</option>
            {comboData3 && comboData3.length > 0 ? (
              comboData3.map((metodo) => (
                <option key={metodo.Codigo} value={metodo.Codigo}>
                  {metodo.Descripcion}
                </option>
              ))
            ) : (
              <option value="">Cargando opciones...</option>
            )}
          </select>
        </div>

        {/* Campos adicionales dependiendo del método de pago seleccionado */}
        {metodoPago && (
          <>
            <div className="mb-3">
              <label htmlFor="numeroReferencia" className="form-label">Número de Referencia</label>
              <input 
                type="text" 
                className="form-control" 
                id="numeroReferencia" 
                value={numeroReferencia} 
                onChange={(e) => setNumeroReferencia(e.target.value)}
              />
            </div>
           
            <div className="mb-3">
              <label htmlFor="monto" className="form-label">Monto</label>
              <input 
                type="text" 
                className="form-control" 
                id="monto" 
                value={monto} 
                onChange={(e) => setMonto(e.target.value)}
              />
            </div>
          </>
        )}

        {/* Cargar Imagen */}
        <div className="mb-3">
          <label htmlFor="imagen" className="form-label">Cargar Imagen</label>
          <input 
            type="file" 
            className="form-control" 
            id="imagen" 
            onChange={handleImageChange} 
          />
        </div>
      </div>
      <div className="modal-footer">
        <button 
          type="button" 
          className="btn btn-secondary" 
          data-bs-dismiss="modal">
          Cerrar
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
          Enviar
        </button>
      </div>
    </div>
  </div>
</div> 

       
       {
/*
      
  <div className="row text-center">
 <div className="col">
    <div className="card shadow-sm border-success">
      <div className="card-header bg-success text-white">
        Pedido
      </div>
      <div className="card-body ">
        <div className="row">
          321654
          Activo
          05/08/2023
        </div>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card shadow-sm border-info">
      <div className="card-header bg-info text-white">
        Orden de Venta
      </div>
      <div className="card-body ">
        <div className="row ">
          987654
          Activo
          05/08/2023
        </div>
      </div>
    </div>
  </div>
  
  <div className="col">
    <div className="card shadow-sm border-danger">
      <div className="card-header bg-danger text-white">
        Picking
      </div>
      <div className="card-body ">
        <div className="row ">
          847
          En Proceso
          23/08/2023
        </div>
      </div>
    </div>
  </div>
  <div className="col">
    <div className="card shadow-sm border-primary">
      <div className="card-header bg-primary text-white">
        Entrega
      </div>
      <div className="card-body ">
        <div className="row ">
          ######
          Pendiente
          24/08/2023
        </div>
      </div>
    </div>
  </div>
  
  <div className="col">
    <div className="card shadow-sm border-success">
      <div className="card-header bg-success text-white">
        Factura
      </div>
      <div className="card-body ">
        <div className="row ">
          ######
          Pendiente
          ##/##/####
        </div>
      </div>
    </div>
  </div>
 
  <div className="col"></div>
  <div className="col"></div>
</div>  */}  

<br />
<div className="row text-center">
  <div className="container">
    <div className="row">
      {orderData && orderData[0] && orderData[0].Documentos.map((documento, index) => (
        <div className="col" key={index}>
          <div className={`card shadow-sm border-${documento.Color}`}>
            <div className={`card-header bg-${documento.Color} text-black`}>
              {documento.Documento}
            </div>
            <div className="card-body">
              <div className="row">
                <p><b>{documento.Referencia}</b></p>
                <p>{documento.Comentarios}</p>
                <p>{documento.FechaHora}</p>
              </div>
              {/* Condición para mostrar el botón */}
              {documento.TextoBoton === 'VER' && (
                <a
                  href={documento.ValorBoton}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {documento.TextoBoton}
                </a>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
</div>



</div>
<br />
<br />
<div className="row text-center">
  { /*<div className="col-md-2">
    <div className="card shadow-sm border-warning">
      <div className="card-header bg-warning text-white">
        Orden de Compra
      </div>
      <div className="card-body">
        <div className="row">
          FEJBB3216
          03/08/2023
        </div>
      </div>
    </div>
  </div>*/} 
  {/*
  <div className="col-md-1"></div>
  <div className="col-md-9">
    <div className="card shadow-sm border-default">
      <div className="card-header bg-default">
        Notificación
      </div>
      <div className="card-body text-start">
        <div className="row">
          <div className="col-md-10">Proximamente.....</div>
          <button type="button" className="col-md-2 btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">----</button>
        </div>
 
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Correo Enviado</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>Correo Ejemplo.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</div> 
<hr />
<div className="row">
      { /*<div className="col-md-6">
        <div className="card" style={{ width: '100%' }}>
          <div className="card-body">
            <h5 className="card-title">Mercancía/Mercadería</h5>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Unidades</th>
                  <th>Descripción</th>
                  <th>Precio Unitario</th>
                  <th>Total Unitario</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Mozza fresca bola tarro (+-13 G) 400 g.</td>
                  <td>Q. 57.75</td>
                  <td>Q. 57.75</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Helado Wafle grande vainilla</td>
                  <td>Q. 12</td>
                  <td>Q. 36</td>
                </tr>
                <tr>
                  <td colSpan="3" className="table-active text-end">TOTAL</td>
                  <td>Q. 93.75</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
</div> */} 
<div className="col-md-6">
  <div className="card" style={{ width: '100%' }}>
    <div className="card-body">
      <h5 className="card-title">Mercancía/Mercadería</h5>
      <table className="table table-striped table-hover">
        <thead>
          <tr> 
            <th>Codigo</th>
            <th>Descripción</th>
            <th>Precio Unitario</th> 
            <th>Cnt. Cot.</th> 
            <th>Cnt. Pedido</th> 
            <th>Cnt. OV.</th> 
            <th>Cnt. Pk.</th> 
            <th>Cnt. Fact.</th> 
           

          { // <th>Total </th>
          }
          </tr>
        </thead>
        <tbody>
          {orderData && orderData[0] && orderData[0].Productos.map((producto, index) => (
            <tr key={index}>
              <td>{producto.Codigo}</td>
              <td>{producto.Descripcion}</td>
              <td>Q. {parseFloat(producto.Precio).toFixed(2)}</td> 
              <td>{producto.CantidadCoti}</td>
              <td>{producto.CantidadPed}</td>
              <td>{producto.CantidadOV}</td>
              <td>{producto.CantidadPik}</td>
              <td>{producto.CantidadFactura}</td> 
              

             {
             // <td>Q. {(parseFloat(producto.CantidadOV) * parseFloat(producto.Precio)).toFixed(2)}</td>
             } 
            </tr>
          ))}
        { /* <tr>
            <td colSpan="3" className="table-active text-end">TOTAL</td>
            {orderData && orderData[0] && orderData[0].Productos && (
  <td>Q. {orderData[0].Productos.reduce((total, producto) => total + (parseFloat(producto.CantidadOV) * parseFloat(producto.Precio)), 0).toFixed(2)}</td>
)}

          </tr> */
          }
        </tbody>
      </table>
    </div>
  </div>
</div>


     {/* <div className="col-md-6">
        <div className="card" style={{ width: '100%' }}>
          <div className="card-body">
            <h5 className="card-title">Bitácora</h5>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Paso</th>
                  <th>Descripción</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Se recibe la información para generar la Orden</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Se validan datos primarios</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Se verifican datos para envío de correo</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Se envió correo a "luis.e.pierri.sanchez@gmail.com"</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Se registra apertura de link enviado</td>
                </tr>
                <tr>
                  <td colSpan="2" className="table-active">Tiempo del proceso:</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
</div> */}   
<div className="col-md-6">
  <div className="card" style={{ width: '100%' }}>
    <div className="card-body">
      <h5 className="card-title">Bitácora</h5>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Paso</th>
            <th>Descripción</th> 
            <th>Fecha</th> 

          </tr>
        </thead>
        <tbody>
          {orderData && orderData[0] && orderData[0].Bitacora.map((documento, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{documento.Descripcion}</td> 
              <td>{documento.Fecha}</td> 

            </tr>
          ))}
          <tr>
            <td colSpan="2" className="table-active">Tiempo del proceso:</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>  

  <br />
  <br />
  <div className="col">
          <div className="card mb-12 rounded-3 shadow-sm border-success">
            <div className="card-header bg-success text-white">
              Pago 
            </div>
            <div className="card-body">
              <div className="row">
                {/* Espacio intencionalmente en blanco para demostración */}
                <br /><br /><br /><br /><br /><br /><br /><br />
              </div>
            </div>
          </div>
        </div>

</div>


    </div>
    <hr />
      <div className="row row-cols-1 row-cols-md-2 mb-2 text-center">
     
      {  /*<div className="col">
          <div className="col-md-6">
            <a href="https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=8C19F9E0-F190-4E24-A53B-8BFD8769B48A" className="btn btn-info" target="_blank" rel="noopener noreferrer">Ver Factura</a>
          </div> 
        </div>*/ }
      </div>
    </div>
  );
}

export default DashboardOM;

import React from 'react'
import { Routes, Route } from "react-router-dom";

import { AppstoreScreen } from '../componentes/view/servicio/appstore/AppstoreScreen';

export const ServicioRoutes = () => {

  return (
    <Routes>            
      <Route path="/appstore" element={ <AppstoreScreen /> } />
    </Routes>
  )
}

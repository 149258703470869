import React from 'react';
import { NavLink } from "react-router-dom";

export const ButtonLinkEditar = ({ Href }) => {
  return (
    <NavLink type='button' className='btn btn-sm btn-warning text-white rounded-pill mx-1'
        to={Href}
        title='Editar registro'>
        <span className='fa-solid fa-edit'></span>
    </NavLink>
  )
}

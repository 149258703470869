import { types } from '../types/types'

export const authReducer = ( state = {}, action ) => {
    switch ( action.type ){
        case types.login:
            return {
                ...state,
                ...JSON.parse(localStorage.getItem( 'auth' ))
            }
        case types.logout:
            return { 
                isLoggedIn: false
            }
        default:
            return state;
    }
}

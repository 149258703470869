import React from 'react'

export const IndicadorBodega = ({
    Bodega
}) => {
  return (
    <div className='card-columns animate__animated animate__fideIn text-center'>
        {
            Bodega == 'DGUA' 
            ? <span className="badge bg-primary" title='Bodega'>{ Bodega }</span>
            : Bodega == 'DMIR'
                ? <span className="badge bg-warning text-white"  title='Bodega'>{ Bodega }</span>
                : <span className="badge bg-success"  title='Bodega'>{ Bodega }</span>
        }
    </div>
  )
}

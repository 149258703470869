import React, { useState, useEffect } from 'react';
import { Modal, Box, Button } from '@mui/material'; 
import { TextField } from '@mui/material';


// Estilo del modal
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
}; 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%', // Ancho responsivo
  maxWidth: 600, // Ancho máximo
  bgcolor: 'background.paper', // Fondo blanco
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto', // Permitir scroll vertical
  maxHeight: '80%', // Limitar altura del modal
};


const AdminTiendasHelados = () => {
  const [tienda, setTienda] = useState('');
  const [maquina, setMaquina] = useState('');
  const [tolba1, setTolba1] = useState('');
  const [tolba2, setTolba2] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [tiendas, setTiendas] = useState([]);
  const [maquinas, setMaquinas] = useState([]);
  const [tolbas, setTolbas] = useState([]); 
  const [open, setOpen] = useState(false);
  const [openInsertModal1, setOpenInsertModal1] = useState(false);
  const [formData1, setFormData1] = useState({
    maq_codigo: '',
    maq_nombre: '',
    maq_descripcion: '',
    maq_estado: '',
    maq_codus_crea: '',
    maq_codus_mod: ''
  }); 

  const [openInsertModal2, setOpenInsertModal2] = useState(false);
  const [formData2, setFormData2] = useState({
    tol_codigo: '',
    tol_nombre: '',
    tol_descripcion: '',
    tol_estado: '',
    tol_codus_crea: '',
    tol_codus_mod: ''
  });

  const handleOpen2 = () => {
    setOpenInsertModal2(true);
  };

  const handleClose2 = () => {
    setOpenInsertModal2(false);
    // Limpiar el formulario al cerrar el modal (opcional)
    setFormData2({
      tol_codigo: '',
      tol_nombre: '',
      tol_descripcion: '',
      tol_estado: '',
      tol_codus_crea: '',
      tol_codus_mod: ''
    });
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setFormData2({
      ...formData2,
      [name]: value
    });
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    // Aquí puedes manejar la lógica de inserción de datos
    console.log('Datos a insertar:', formData2);
    handleClose2(); // Cerrar el modal después de enviar
  };


  const handleOpen1 = () => {
    setOpenInsertModal1(true);
  };

  const handleClose1 = () => {
    setOpenInsertModal1(false);
    // Limpiar el formulario al cerrar el modal (opcional)
    setFormData1({
      maq_codigo: '',
      maq_nombre: '',
      maq_descripcion: '',
      maq_estado: '',
      maq_codus_crea: '',
      maq_codus_mod: ''
    });
  };

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1({
      ...formData1,
      [name]: value
    });
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    // Aquí puedes manejar la lógica de inserción de datos
    console.log('Datos a insertar:', formData1);
    handleClose1(); // Cerrar el modal después de enviar
  };

  // Función para abrir el modal
  const handleOpen = () => {
    setOpen(true);
  };

  // Función para cerrar el modal
  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    tie_nombre: '',
    tie_descripcion: '',
    tie_estado: 1,
    tie_codus_crea: 101,
    tie_codus_mod: 101,
    tie_cod_warehouse: '',
    tie_ip_impresoras: '',
    tie_ip_cajas: '',
    tie_direccion: '',
    tie_telefono: '',
    tie_extension: ''
  });

  // Función para abrir el modal
  const handleAddClick = (item) => {
    setModalContent(`Agregar ${item}`);
    setOpenModal(true);
  };

  // Función para cerrar el modal
  const handleCloseModal = () => setOpenModal(false);

  // Función para obtener datos de tiendas, máquinas y tolbas
  const fetchCombos = () => {
    const fetchDataUrl = (tabla) => `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify({
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaCRUDHelados",
        Datos_Recibidos: JSON.stringify({
          Tabla: tabla,
          TipoOperacion: "Select"
        })
      }
    }))}`;

    const fetchTiendas = () => {
      fetch(fetchDataUrl("gn.tie_tienda"))
        .then(response => response.json())
        .then(data => {
          console.log(data);
          const tiendasDecodificadas = JSON.parse(data.datos_Generados); 
          console.log(tiendasDecodificadas)

          setTiendas(tiendasDecodificadas.RESP);
        })
        .catch(error => console.error('Error:', error));
    };

    const fetchMaquinas = () => {
      fetch(fetchDataUrl("gn.maq_maquina")) // Asegúrate de que esta tabla sea la correcta para las máquinas
        .then(response => response.json())
        .then(data => {
          console.log(data);
          const maquinasDecodificadas = JSON.parse(data.datos_Generados);
          setMaquinas(maquinasDecodificadas.RESP);
        })
        .catch(error => console.error('Error:', error));
    };

    const fetchTolbas = () => {
      fetch(fetchDataUrl("gn.tol_tolba")) // Asegúrate de que esta tabla sea la correcta para las tolbas
        .then(response => response.json())
        .then(data => {
          console.log(data);
          const tolbasDecodificadas = JSON.parse(data.datos_Generados);
          setTolbas(tolbasDecodificadas.RESP);
        })
        .catch(error => console.error('Error:', error));
    };

    fetchTiendas();
    fetchMaquinas();
    fetchTolbas();
  };

  // Función para obtener datos de la tabla de resultados
  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaCRUDHelados",
        Datos_Recibidos: JSON.stringify({
          TipoOperacion: "SelectForViewTMT"
        })
      }
    };

    console.log(datos);

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
    
    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
        const datosRespuesta = datosGeneradosDecodificados.RESP;
        console.log(datosRespuesta);
        setDatosGenerados(datosRespuesta);
      })
      .catch(error => console.error('Error:', error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes añadir la lógica para guardar la tienda.
    console.log(formData);
    // Puedes cerrar el modal al finalizar la operación
    setOpen(false);
  };
  
 
  

  // UseEffect para cargar datos al inicio
  useEffect(() => {
    fetchData(); // Cargar datos de la tabla
    fetchCombos(); // Cargar datos de los combos
  }, []);


  // invocaciones al api !!!!!!!! 

  const maquinaxtolba = () => {

    alert('prueba tolba')
     /* const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaCRUDHelados",
        Datos_Recibidos: JSON.stringify({
          Tabla: "gn.mt_maquina_tolba",
          TipoOperacion: "Insert",
          mt_codmaq: 1,
          mt_codtol: 2,
          mt_estado: 1,
          mt_codus_crea: 101,
          mt_codus_mod: 101
        })
      }
    };
  
    console.log(datos);
  
    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
    
    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
        console.log(datosGeneradosDecodificados);
        setDatosGenerados(datosGeneradosDecodificados);
      })
      .catch(error => console.error('Error:', error)); */
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>Admin Tiendas Helados</h2>

      {/* Controles de selección con botones de "Agregar" */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <select value={tienda} onChange={(e) => setTienda(e.target.value)} style={{ marginRight: '10px' }}>
            <option value="">Seleccionar Tienda</option>
            {tiendas.map((item, index) => (
              <option key={index} value={item.tienda}>{item.tie_nombre}</option>
            ))}
          </select>
          <Button variant="contained" onClick={() => handleOpen()}>
            Agregar Tienda
          </Button>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <select value={maquina} onChange={(e) => setMaquina(e.target.value)} style={{ marginRight: '10px' }}>
            <option value="">Seleccionar Máquina</option>
            {maquinas.map((item, index) => (
              <option key={index} value={item.maquina}>{item.maq_nombre}</option>
            ))}
          </select>
          <Button variant="contained" onClick={() => handleOpen1()}>
            Agregar Máquina
          </Button>
        </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
          <select 
            value={tolba1} 
            onChange={(e) => setTolba1(e.target.value)}  // Guarda el ID seleccionado
            style={{ marginRight: '10px' }}>
            <option value="">Seleccionar Tolba 1</option>
            {tolbas.map((item, index) => (
              <option key={index} value={item.tol_codigo}>{item.tol_nombre}</option>  // El ID como value
            ))}
          </select>

          {/* Botón que no necesita el ID (sólo abre el modal) */}
          <Button 
            variant="contained" 
            onClick={handleOpen2}  // No se pasa el ID aquí
          >
            Agregar Tolba 1
          </Button>
        </div>

      </div>

      {/* Modal para agregar elementos */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <h2>{modalContent}</h2>
          <p>Aquí puedes agregar contenido adicional para manejar el formulario de {modalContent.toLowerCase()}.</p>
          <Button variant="contained" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Box>
      </Modal> 

<Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-tienda-title"
      aria-describedby="modal-tienda-description"
    >
      <Box sx={style}>
        <h2 id="modal-tienda-title">Agregar Tienda</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            name="tie_nombre"
            label="Nombre de Tienda"
            fullWidth
            margin="normal"
            value={formData.tie_nombre}
            onChange={handleInputChange}
          />
          <TextField
            name="tie_descripcion"
            label="Descripción"
            fullWidth
            margin="normal"
            value={formData.tie_descripcion}
            onChange={handleInputChange}
          />
          <TextField
            name="tie_cod_warehouse"
            label="Código Warehouse"
            fullWidth
            margin="normal"
            value={formData.tie_cod_warehouse}
            onChange={handleInputChange}
          />
          <TextField
            name="tie_ip_impresoras"
            label="IP Impresoras"
            fullWidth
            margin="normal"
            value={formData.tie_ip_impresoras}
            onChange={handleInputChange}
          />
          <TextField
            name="tie_ip_cajas"
            label="IP Cajas"
            fullWidth
            margin="normal"
            value={formData.tie_ip_cajas}
            onChange={handleInputChange}
          />
          <TextField
            name="tie_direccion"
            label="Dirección"
            fullWidth
            margin="normal"
            value={formData.tie_direccion}
            onChange={handleInputChange}
          />
          <TextField
            name="tie_telefono"
            label="Teléfono"
            fullWidth
            margin="normal"
            value={formData.tie_telefono}
            onChange={handleInputChange}
          />
          <TextField
            name="tie_extension"
            label="Extensión"
            fullWidth
            margin="normal"
            value={formData.tie_extension}
            onChange={handleInputChange}
          />
          <Button type="submit" variant="contained" color="primary">
            Guardar Tienda
          </Button>
        </form>
      </Box>
</Modal>      


<Modal
        open={openInsertModal1}
        onClose={handleClose1}
        aria-labelledby="modal-maquina-title"
        aria-describedby="modal-maquina-description"
      >
        <Box sx={style}>
          <h2 id="modal-maquina-title">Agregar Máquina</h2>
          <form onSubmit={handleSubmit1}>
            <TextField
              name="maq_codigo"
              label="Código de Máquina"
              fullWidth
              margin="normal"
              value={formData1.maq_codigo}
              onChange={handleInputChange1}
            />
            <TextField
              name="maq_nombre"
              label="Nombre de Máquina"
              fullWidth
              margin="normal"
              value={formData1.maq_nombre}
              onChange={handleInputChange1}
            />
            <TextField
              name="maq_descripcion"
              label="Descripción"
              fullWidth
              margin="normal"
              value={formData1.maq_descripcion}
              onChange={handleInputChange1}
            />
            <TextField
              name="maq_estado"
              label="Estado"
              fullWidth
              margin="normal"
              value={formData1.maq_estado}
              onChange={handleInputChange1}
            />
            <TextField
              name="maq_codus_crea"
              label="Código Creador"
              fullWidth
              margin="normal"
              value={formData1.maq_codus_crea}
              onChange={handleInputChange1}
            />
            <TextField
              name="maq_codus_mod"
              label="Código Modificador"
              fullWidth
              margin="normal"
              value={formData1.maq_codus_mod}
              onChange={handleInputChange1}
            />
            <Button type="submit" variant="contained" color="primary">
              Guardar Máquina
            </Button>
          </form>
        </Box>
</Modal> 


<Modal
        open={openInsertModal2}
        onClose={handleClose2}
        aria-labelledby="modal-tolva-title"
        aria-describedby="modal-tolva-description"
      >
        <Box sx={style}>
          <h2 id="modal-tolva-title">Agregar Tolva</h2>
          <form onSubmit={handleSubmit2}>
            <TextField
              name="tol_codigo"
              label="Código de Tolva"
              fullWidth
              margin="normal"
              value={formData2.tol_codigo}
              onChange={handleInputChange2}
            />
            <TextField
              name="tol_nombre"
              label="Nombre de Tolva"
              fullWidth
              margin="normal"
              value={formData2.tol_nombre}
              onChange={handleInputChange2}
            />
            <TextField
              name="tol_descripcion"
              label="Descripción"
              fullWidth
              margin="normal"
              value={formData2.tol_descripcion}
              onChange={handleInputChange2}
            />
            <TextField
              name="tol_estado"
              label="Estado"
              fullWidth
              margin="normal"
              value={formData2.tol_estado}
              onChange={handleInputChange2}
            />
            <TextField
              name="tol_codus_crea"
              label="Código Creador"
              fullWidth
              margin="normal"
              value={formData2.tol_codus_crea}
              onChange={handleInputChange2}
            />
            <TextField
              name="tol_codus_mod"
              label="Código Modificador"
              fullWidth
              margin="normal"
              value={formData2.tol_codus_mod}
              onChange={handleInputChange2}
            />
            <Button type="submit" variant="contained" color="primary">
              Guardar Tolva
            </Button>
          </form>
        </Box>
</Modal>



      <br /> 

      <br />

      <div>
      <Button variant="contained"  onClick={() => handleAddClick('Tolba 1')} sx={{ marginRight: 2 }} >
            Agregar tienda maquina
      </Button> 

      <Button variant="contained" onClick={() => handleAddClick(tolba1)}>
            Agregar Maquina tolba
      </Button> 


      </div>

    <br />
      
      {/* Tabla de resultados */}
      <div style={{ border: '1px solid #ddd', padding: '10px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr style={{ backgroundColor: '#f4f4f4' }}>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Tienda</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Máquina</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Tolba 1</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Tolba 2</th>
            </tr>
          </thead>
          <tbody>
            {datosGenerados.map((dato, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dato.tienda}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dato.maquina}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dato.tolba_1}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dato.tolba_2}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminTiendasHelados;

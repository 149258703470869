import React from 'react'
import { useSelector } from 'react-redux';
import { TablaCliente } from './TablaCliente'

export const CardEmbajador = ({
    dataColaborador, onBuscarTienda, onRemoverTienda
}) => {
    const { user } = useSelector(state => state.auth);
    let personal_a_cargo = false;
    let isVisible = 'd-none';

    //obtener los permisos especiales
    user.permiso.filter(item => item.Permiso === 'ROOT').map(filteredName => {
        personal_a_cargo =true;
    });

    user.permiso.filter(item => item.Permiso === 'PERSONAL_A_CARGO').map(filteredName => {
        personal_a_cargo =true;
    });

    user.permiso.filter(item => item.Permiso === 'SUPER').map(filteredName => {
        personal_a_cargo =true;
    });

    if(personal_a_cargo)
        isVisible = '';

  return (
    <div className='row'>
        {
            dataColaborador?.map(({id, colaborador, cliente}) => (
                <div className=' col-sm-6' key = { id }>
                    <div className="card my-3">
                        <h5 className="card-header">
                            <div className="row">
                                <div className="col">
                                {`${id} - ${colaborador}`}
                                </div>
                                <div className="col-auto">
                                <button 
                                    className={`btn btn-success btn-sm rounded-pill float-end me-2 mb-2 ${isVisible}`}
                                    type="button"
                                    onClick={ (e) => onBuscarTienda(e, {id}, `${id} - ${colaborador}`) }>
                                    <i className='fa-solid fa-plus'></i>
                                </button>
                                </div>
                            </div>
                        </h5>
                        <div className="card-footer text-muted">
                            Total de tiendas/clientes: <b>{ (JSON.parse(cliente)).length }</b>
                        </div>
                        <TablaCliente
                            Id_Usuario = { id } 
                            dataCliente = { JSON.parse(cliente) } 
                            onRemoverTienda = { onRemoverTienda }
                            setPermisos = { personal_a_cargo }
                        />
                    </div>
                </div>
            ))
        }
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import '../style/sidebar.css'

import { startLogout } from '../actions/auth';

export const Sidebar = () => {

  const navegate = useNavigate();
  const dispatch = useDispatch();
  const [ isLoading, setIsLoading ] = useState(false);   
  const { user } = useSelector(state => state.auth);
  const [menu, setMenu] = useState(user.menu);

  const handleLogout = () => {
    dispatch(startLogout(user.id_usuario, setIsLoading));

    navegate('/login', {
      replace: true
    });
  }

  const handleMiCuenta = () => {
    navegate('/seguridad/mi/perfil', {
      replace: true
    });
  }

  const initMenu = () => {

    let _ul = null;
    let contador = 0;
    let _id_prd = 0;

    menu.map( ({ Id_Menu, Id_Pdr, Imagen, Nombre, Orden, Url, level }) => {

        if(Id_Pdr == 0){
          if(contador > 0){
            document.getElementById(`_${_id_prd}-collapse`).append(_ul);
            contador = 0;
          }
          
          _ul = document.createElement('ul');
          _ul.className = 'btn-toggle-nav mt-1 pb-1 small';
          _id_prd = Id_Menu;
        }

        if(Id_Pdr > 0){
            let _li = document.createElement('li');
            _li.className = 'py-2 ms-2';
            
            let _icon = document.createElement('i');
            _icon.className = (`${Imagen} link-dark` || 'fa-solid fa-link');
            _icon.innerHTML = '&nbsp;&nbsp;';

            let _a = document.createElement('a');
            _a.setAttribute('href', Url || '#');
            _a.className = 'link-dark rounded';
            _a.innerHTML = Nombre;

            _li.append(_icon, _a);
            _ul.append(_li);
            contador++;
        }
    });

    if(contador > 0){
      document.getElementById(`_${_id_prd}-collapse`).append(_ul);
      contador = 0;
    }
}

  useEffect(() => {
    initMenu();        
  }, [setMenu]);

  return (
    <>
      <div className="offcanvas offcanvas-start" id="offcanvasWithBackdrop" role="dialog" aria-labelledby="offcanvasWithBackdropLabel">
        <div className="text-center">
          <img src={process.env.REACT_APP_CDN + "images/parma/logo.png"} className="w-25 img-fluid rounded-circle" alt="avatar"/>
        </div>
        <div className="offcanvas-header pt-0">
          <a href="#" className="d-flex align-items-center pb-2 link-dark text-decoration-none border-bottom w-100" 
            onClick={handleMiCuenta} data-bs-dismiss="offcanvas" aria-label="Close">
            <span className="fs-5 fw-semibold offcanvas-title w-100 text-center">{user.nombre}</span>            
          </a>
          <button type="button" className="btn-close position-absolute end-0 me-3" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body pt-0">
          <a href='#' className='btn btn-success btn-sm text-center w-100' data-bs-dismiss="offcanvas" onClick={handleLogout}>
            <i className='fas fa-power-off' /> Cerrar Sesi&oacute;n
          </a>
          <div className="border-top my-3"></div>

          <div className="d-flex flex-column flex-shrink-0 text-white">
            <ul className="list-unstyled ps-0">
              {
                menu.map(({ Id_Menu, Id_Pdr, Imagen, Nombre, Orden, Url, level }) => (
                  (Id_Pdr == 0) &&
                  <li className="mb-1" key={Id_Menu}>
                    <button className="btn btn-toggle align-items-center text-start w-100 rounded collapsed" data-bs-toggle="collapse" data-bs-target={`#_${Id_Menu}-collapse`} aria-expanded="false">
                      <i className='fa-solid fa-angle-right'></i>&nbsp;&nbsp;{ Nombre }
                    </button>
                    <div className='collapse' id={`_${Id_Menu}-collapse`}></div>
                  </li>
                ))
              }
            </ul>
          </div>

        </div>
      </div>
    </>
  )
}

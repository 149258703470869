import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ExporXLS } from './ExporXLS';

export const EncabezadoTablaBuscador = ({ Titulo, BodyModal, onBuscarFiltro, DataTabla }) => {

  const [ isOpenFiltro, setIsOpenFiltro ] = useState(false);

  const handlerReload = (e) => {
    window.location.reload();
  } 

  return (
    <>
      <h3>{ Titulo }       
        <button type="button" className="btn btn-secondary rounded-pill float-end"
            onClick={(e) => setIsOpenFiltro(true)}
            title='Filtro para busqueda'>
            <span className='fa-solid fa-filter'></span>
        </button>
        <button type="button" className="btn btn-primary rounded-pill float-end me-2"
            onClick={handlerReload}
            title='Recargar registro'>
          <span className='fa-solid fa-sync'></span>
        </button>
        <ExporXLS DataTabla = { DataTabla } ArchivoNombre = { Titulo }/>
      </h3>
      <hr /> 

      <Modal isOpen={isOpenFiltro} size='lg'>
        <ModalBody>
          { BodyModal }
        </ModalBody>
          <ModalFooter>
            <button type="button" className="btn btn-primary" onClick={(e)=>onBuscarFiltro(e,setIsOpenFiltro) }>Buscar</button>
            <button type="button" className="btn btn-secondary" onClick={(e)=>setIsOpenFiltro(false)}>Cerrar</button>                    
          </ModalFooter>
      </Modal> 
    </>
  )
}

import React, { useEffect, useState } from 'react';
import { Container, TextField, Table, TableHead, TableBody, TableCell, TableRow, Accordion, AccordionSummary, AccordionDetails, Typography, Pagination, Button, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as XLSX from 'xlsx';

function ValesReports() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState(""); 
  const [filtroTexto, setFiltroTexto] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(10);
  const [loading, setLoading] = useState(false); // Estado para indicador de carga

  const fetchData = () => {
    setLoading(true); // Inicia el indicador de carga
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaValesDetalles",
        Datos_Recibidos: `{"FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    };
  
    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
  
    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados); 

        console.log(datosGeneradosDecodificados.VALES);
        setDatosGenerados(datosGeneradosDecodificados.VALES);
      })
      .catch(error => console.error('Error:', error))
      .finally(() => setLoading(false)); // Finaliza el indicador de carga
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const handleSearch = () => {
    fetchData();
  };

  const filteredData = datosGenerados.filter(item => 
    item.Cliente.toLowerCase().includes(filtroTexto.toLowerCase()) ||
    item.Producto.toLowerCase().includes(filtroTexto.toLowerCase())
  );

  const groupedData = filteredData.reduce((acc, current) => {
    const vale = acc.find(item => item.ID_Vale === current.ID_Vale);
    if (vale) {
      vale.items.push(current);
    } else {
      acc.push({
        ID_Vale: current.ID_Vale,
        Cliente: current.Cliente,
        Fecha: current.Fecha,
        items: [current]
      });
    }
    return acc;
  }, []);

  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = groupedData.slice(indexPrimerElemento, indexUltimoElemento);

  const exportToExcel = () => {
    const dataForExcel = datosGenerados.map(vale => ({
      ID_Cliente: vale.ID_Cliente,
      Cliente: vale.Cliente,
      Fecha: vale.Fecha,
      Hora: vale.Hora,
      Colaborador: vale.Colaborador,
      Telefono: vale.Telefono,
      Bodega: vale.Bodega,
      ID_Vale: vale.ID_Vale,
      Tracking: vale.Tracking, 
      ID_Producto: vale.ID_Producto,
      Producto: vale.Producto,
      Cantidad: vale.Cantidad,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Vales");
    XLSX.writeFile(workbook, "ValesReport.xlsx");
  };

  return (
    <Container>
      <h2>Vales</h2>

      <TextField
        id="filtroTexto"
        label="Buscar"
        value={filtroTexto}
        onChange={(e) => setFiltroTexto(e.target.value)}
        variant="outlined"
        style={{ marginBottom: '10px', width: '500px' }}
      />
      <TextField
        id="fechaInicio"
        label="Fecha de Inicio"
        type="date"
        value={fechaInicio}
        onChange={(e) => setFechaInicio(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        style={{ marginRight: '10px', marginBottom: '20px' }}
      />
      <TextField
        id="fechaFin"
        label="Fecha de Fin"
        type="date"
        value={fechaFin}
        onChange={(e) => setFechaFin(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        style={{ marginRight: '10px', marginBottom: '20px' }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleSearch}
        style={{ marginBottom: '20px', marginRight: '10px' }}
      >
        Buscar
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={exportToExcel}
        style={{ marginBottom: '20px' }}
      >
        Exportar a Excel
      </Button>

      {loading ? (
        <CircularProgress style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        datosPaginados.map((grupo, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>{`${grupo.Cliente} - ${grupo.Fecha}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID Cliente</TableCell>
                    <TableCell>Cliente</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Hora</TableCell>
                    <TableCell>Colaborador</TableCell>
                    <TableCell>Teléfono</TableCell>
                    <TableCell>Bodega</TableCell>
                    <TableCell>ID Vale</TableCell>
                    <TableCell>Tracking</TableCell>
                    <TableCell>ID Producto</TableCell>
                    <TableCell>Producto</TableCell>
                    <TableCell>Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {grupo.items.map((vale, i) => (
                    <TableRow key={i}>
                      <TableCell>{vale.ID_Cliente}</TableCell>
                      <TableCell>{vale.Cliente}</TableCell>
                      <TableCell>{vale.Fecha}</TableCell>
                      <TableCell>{vale.Hora}</TableCell>
                      <TableCell>{vale.Colaborador}</TableCell>
                      <TableCell>{vale.Telefono}</TableCell>
                      <TableCell>{vale.Bodega}</TableCell>
                      <TableCell>{vale.ID_Vale}</TableCell>
                      <TableCell>{vale.Tracking}</TableCell>  
                      <TableCell>{vale.ID_Producto}</TableCell> 
                      <TableCell>{vale.Producto}</TableCell>
                      <TableCell>{vale.Cantidad}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))
      )}

      <Pagination
        count={Math.ceil(groupedData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
        style={{ marginTop: '20px' }}
      />
    </Container>
  );
}

export default ValesReports;

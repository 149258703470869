import React, { useEffect, useState } from 'react';
import { Container, Table, TableHead, TableBody, TableCell, TableRow, TextField, Grid, Button, Select, MenuItem, Pagination } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';

function Inventory() {  
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [filtroTexto, setFiltroTexto] = useState("");
  const [filtroTienda, setFiltroTienda] = useState(""); 
  const [filtroTipo, setFiltroTipo] = useState(""); 
  const [tiendas, setTiendas] = useState([]); 
  const [tipos, setTipos] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 50;
  const { token, user } = useSelector(state => state.auth); // Aquí se obtiene el nombre de usuario

  const fetchData = () => {
    const datos = {
      Token: "TOKEN_DE_EJEMPLO",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: user?.UserNickName || "pierril" // Cambiar el UserNickName por el del usuario
      },
      DatosMetodo: {
        NombreMetodo: "ListaReportesSalas",
        Datos_Recibidos: `{"Vista":"Inventarios", "Tienda":"${filtroTienda}","Tipo":"${filtroTipo}"}`
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;

    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        try {
          if (data && data.datos_Generados) {
            const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);

            if (Array.isArray(datosGeneradosDecodificados)) {
              setDatosGenerados(datosGeneradosDecodificados);
              console.log("Datos generados:", datosGeneradosDecodificados);
            } else {
              console.error("Los datos generados no son un array:", datosGeneradosDecodificados);
              setDatosGenerados([]);
            }
          } else {
            console.error("No se recibieron datos generados válidos:", data);
            setDatosGenerados([]);
          }
        } catch (error) {
          console.error("Error al procesar los datos generados:", error);
          setDatosGenerados([]);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setDatosGenerados([]);
      });
  };

  useEffect(() => {
    fetchData();

    console.log(user.usuario)
    if(user.usuario == "TZ10"){
      console.log("entro")
      setTiendas(["TZ10"]);
    }else if(user.usuario == "TPRR"){

    }else if(user.usuario == "TSCR"){
      setTiendas(["TSCR"]); 
      
    }else if(user.usuario == "TCSV"){
      setTiendas(["TCSV"]); 
      
    }else if(user.usuario == "TXLA"){
      setTiendas([ "TXLA" ]); 
      
    }else if(user.usuario == "TMIR"){
      setTiendas([ "TMIR" ]); 
    }else{
      setTiendas(["TZ10", "TPRR", "TXLA", "TMIR", "TSCR","TCSV"]); 
    }
    setTipos(["TODO", "EMPAQUE", "PRODUCTOTERMINADO", "TERCEROS"]);
  }, []);

  // Filtrar las tiendas basándose en el nombre de usuario
  const filteredTiendas = user?.UserNickName
    ? tiendas.filter(tienda => tienda.includes(user.UserNickName))
    : tiendas;

  // Si no hay coincidencias, incluye la opción de "Todas las tiendas"
  const tiendaOptions = filteredTiendas.length > 0 ? filteredTiendas : ["Todas las tiendas", ...tiendas];

  const filteredData = datosGenerados.filter(item => {
    const searchValue = filtroTexto.toLowerCase();
    return (
      (item.itemcode.toLowerCase().includes(searchValue) ||
      item.itemname.toLowerCase().includes(searchValue)) &&
      item.cantidadenstock > 0 
    );
  });

  const handleSearch = () => {
    fetchData();
  };

  // Función para exportar a PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ["Tienda", "Código de Artículo", "Descripción", "Cantidad en Stock", "Cantidad en Lotes"];
    const tableRows = filteredData.map(item => [
      item.whscode,
      item.itemcode,
      item.itemname,
      item.cantidadenstock,
      item.cantidadenlotes,
    ]);

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save("inventario.pdf");
  };

  // Función para exportar a Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Inventario");
    XLSX.writeFile(workbook, "inventario.xlsx");
  };

  // Paginación
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <Container> 
      <h1>INVENTARIO</h1> 
      <hr />

      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={4}>
          <TextField
            label="Buscar por artículo o código"
            variant="outlined"
            fullWidth
            value={filtroTexto}
            onChange={(e) => setFiltroTexto(e.target.value)}
          />
        </Grid>

        <Grid item xs={2}>
          <Select
            label="Tienda"
            value={filtroTienda}
            onChange={(e) => setFiltroTienda(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Todas las tiendas</MenuItem>
            {tiendaOptions.map((tienda, index) => (
              <MenuItem key={index} value={tienda}>{tienda}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={2}>
          <Select
            label="Tipo"
            value={filtroTipo}
            onChange={(e) => setFiltroTipo(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Todos los tipos</MenuItem>
            {tipos.map((tipo, index) => (
              <MenuItem key={index} value={tipo}>{tipo}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={4}>
          <Button variant="contained" onClick={handleSearch}>
            Buscar
          </Button>
          <Button variant="contained" onClick={exportToPDF} style={{ marginLeft: "4px", marginRight: "4px" }} color="warning">
            PDF
          </Button>
          <Button variant="contained" onClick={exportToExcel} color="success">
            Excel
          </Button>
        </Grid>
      </Grid>
      
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tienda</TableCell>
            <TableCell>Código de Artículo</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Cantidad en Stock</TableCell>
            <TableCell>Cantidad en Lotes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.whscode}</TableCell>
              <TableCell>{item.itemcode}</TableCell>
              <TableCell>{item.itemname}</TableCell>
              <TableCell>{item.cantidadenstock}</TableCell>
              <TableCell>{item.cantidadenlotes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        count={Math.ceil(filteredData.length / rowsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        style={{ marginTop: "20px" }}
      />
    </Container>
  );
}

export default Inventory; 

import React from 'react';
import { useSelector } from 'react-redux';

import axios from 'axios';
import Swal from 'sweetalert2'

export const CardAppStore = ({
    Id,
    Titulo,
    Detalle,
    Imagen,
    Version,
    Ambiente,
    Publicado,
    Area,
    Departamento,
    setIsLoading
}) => {

    const { token, user } = useSelector(state => state.auth);

    const handleDownload = ( e ) => {
        e.preventDefault();
        
        setIsLoading(true);
        
        axios.get( `${ process.env.REACT_APP_API_APPSTORE }app/apk/${ Id }`, {
          responseType: 'blob',
        })
        .then( response =>{
            const url = window.URL.createObjectURL(
              new Blob([response.data]),
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `${ Titulo }_${ Version }.apk`
            );
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            setIsLoading(false);
        })
        .catch( ({message}) =>{
            setIsLoading(false);
            Swal.fire('AppStore', message, 'error');            
        });
    }

  return (
    <div className="card mb-3 animate__animated animate__fadeIn">
        <div className="row g-0">
          <div className="col-md-4 bg-secondary">
            {
                (Imagen)
                    ?<img src={ `data:image/jpeg;base64, ${ Imagen }` } className="img-fluid rounded-start" alt={ Titulo }/>
                    :<img src={ `${ process.env.PUBLIC_URL }/logo.png` } className="img-fluid rounded-start" alt={ Titulo }/>
            }            
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">{ Titulo }</h5>
              <p className="card-text">{ Detalle }</p>
              
              <p className="card-text">
                <span className="text-muted">Area: { Area }</span>
                <br/>
                <span className="text-muted">Departamento: { Departamento }</span>
              </p>

              <p className="card-text">
                <small className="text-muted">Versión: { Version }</small>
                <br/>
                  { 
                    (
                      ( process.env.REACT_APP_AMBIENTE == "LCL" || 
                        process.env.REACT_APP_AMBIENTE == "DEV" )
                        ? <span className="badge bg-danger">{ Ambiente }</span>
                        : (
                          ( process.env.REACT_APP_AMBIENTE == "PRB" )
                          ?<span className="badge bg-secondary">{ Ambiente }</span>
                          :<span className="badge bg-success">{ Ambiente }</span>
                        )
                    )
                  }
              </p>

              <p className="card-text">
                <button 
                    className="btn btn-link"
                    onClick = { handleDownload }
                    >
                    <span className="fa-solid fa-download"></span> Descargar
                </button>
              </p>

              <p className="card-text">
                <small className="text-muted">Publicado: { Publicado }</small>
              </p>
            </div>
          </div>
        </div>
      </div>
  )
}

import React, { useEffect, useState } from 'react';

import { Loading } from '../../../ui/Loading';
import { AppStoreList } from '../../../elements/AppStoreList';

export const AppstoreScreen = () => {

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
  }, [setIsLoading]);  

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="container">
        <h3>Lista de Aplicaciones</h3>
        <hr />
        <AppStoreList setIsLoading = {setIsLoading}/>
      </div>
    </>
  )
}

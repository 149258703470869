import React from 'react'
import { useSelector } from 'react-redux';

export const Header = () => {

  const { user } = useSelector( state => state.auth ); 

  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-success shadow">
      <div className="container-fluid">
        <button className="navbar-brand btn btn-sm btn-light rounded-pill" type='button'
          data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop">
          <i className='fa-solid fa-bars me-3'></i>Parma, S.A.
        </button>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          </ul>
          <form className="d-flex">
            <span className="navbar-text ms-4 text-white">
              @{ user.nombre }
            </span>
            <span className="navbar-text ms-4 text-white">
              Ver.{ process.env.REACT_APP_VERSION }
            </span>
          </form>
        </div>
      </div>
    </nav>
  )
}

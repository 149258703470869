import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

export const PublicRoutes = ({ children }) => {
  
  const { isLoggedIn } = useSelector( state => state.auth );
  //const lastPath = localStorage.getItem('lastPath') || '/';
  
  return isLoggedIn
    ? <Navigate to="/" />
    : children
}

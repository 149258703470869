import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from '../../../../hooks/useForm';

import { clienteSelectorLoad, procesoTracikngSelectorLoad } from '../../../actions/selector';

export const BodyFiltroVale = ({
    setIsLoading, setFormFiltro, formFiltro
}) => {
    const dispatch = useDispatch();
    
    const { token, user } = useSelector(state => state.auth);
    const { clienteData, procesoTrackingData } = useSelector(state => state.selector);
    const { vale, fecha_carga_1, fecha_carga_2, fecha_ruta_1, fecha_ruta_2, rastreo, formState, onInputChange } = useForm(formFiltro);
    const [ options, setOptions ] = useState([]);
    const [ cliente, setCliente ] = useState(null);

    useEffect(() => {
        dispatch( clienteSelectorLoad( token, user.id_usuario, setIsLoading ) );
        dispatch( procesoTracikngSelectorLoad( token, user.id_usuario, setIsLoading ) );
    }, []);

    useEffect(() => {
        document.getElementById("cliente").value = formFiltro.cliente;
        document.getElementById("rastreo").value = formFiltro.rastreo;
    }, [onInputChange]);

    useEffect(() => {
        if(clienteData){
            let lista = [];
            clienteData?.map((item) => {
                lista.push({ values: item.Id, label: `${item.Id} - ${item.Nombre}` });
            });
            setOptions(lista);
        }
    }, [clienteData]);

    useEffect(() => {
        setFormFiltro({
            vale: vale, 
            fecha_carga_1: fecha_carga_1,
            fecha_carga_2: fecha_carga_2, 
            fecha_ruta_1: fecha_ruta_1, 
            fecha_ruta_2: fecha_ruta_2, 
            cliente: cliente?.values, 
            rastreo: rastreo    
        })
    }, [vale, fecha_carga_1, fecha_carga_2, fecha_ruta_1, fecha_ruta_2, cliente, rastreo]);

  return (
    <form>
        <div className='row g-3'>
            <div className='col-12 col-md-6'>
                <label className='form-label'>No. Vale</label>
                <input type='text' className='form-control form-sm' 
                    name='vale' value={ vale } onChange={ onInputChange }/>
            </div>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Proceso(Tracking)</label>
                <select className='form-select form-sm' id='rastreo' name='rastreo' onChange={ onInputChange }>
                    <option key={0} value='-1'>Seleccione un registro</option>
                    {
                        procesoTrackingData?.map((item) => (
                            <option key={item.Id} value={item.Id} data-texto={item.Nombre}>
                                {`${item.Id} - ${item.Nombre}`}
                            </option>
                        ))
                    }
                </select>
            </div>

            <div className='col-12 col-md-12'>
                <label className='form-label'>Cliente</label>
                <Select isClearable 
                    className='form-sm' id='cliente' name='cliente'
                    onChange={ setCliente } placeholder={ 'Seleccione un cliente' }
                    options={ options }/>
            </div>
        </div>
        <div className='row mt-3'>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Fecha Carga Inicio</label>
                <input type='date' className='form-control form-sm'
                    name='fecha_carga_1' value={ fecha_carga_1 } onChange={ onInputChange }/>
            </div>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Fecha Carga Fin</label>
                <input type='date' className='form-control form-sm'
                    name='fecha_carga_2' value={ fecha_carga_2 } onChange={ onInputChange }/>
            </div>
        </div>
        <div className='row mt-3'>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Fecha Ruta Inicio</label>
                <input type='date' className='form-control form-sm'
                    name='fecha_ruta_1' value={ fecha_ruta_1 } onChange={ onInputChange }/>
            </div>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Fecha Ruta Fin</label>
                <input type='date' className='form-control form-sm'
                    name='fecha_ruta_2' value={ fecha_ruta_2 } onChange={ onInputChange }/>
            </div>
        </div>
    </form>
  )
}

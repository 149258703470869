import React from 'react'
import { Routes, Route } from "react-router-dom";
import { LoginScreen } from '../componentes/view/accesos/LoginScreen';

export const AccesoRoutes = () => {
  
  return (
    <>
        <Routes>
            <Route path="/" element={ <LoginScreen /> } />
        </Routes>
    </>
  )
}

import React, { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Pagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  CircularProgress // Importa el componente CircularProgress
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';

function GeneralSales() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [filtroTexto, setFiltroTexto] = useState("");
  const [filtroTienda, setFiltroTienda] = useState("");
  const [tiendas, setTiendas] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(50);
  const [cargando, setCargando] = useState(false); // Estado de cargando
  const { token, user } = useSelector(state => state.auth);
  const usuario = user.usuario;

  // Función para obtener los datos
  const fetchData = () => {
    const datos = {
      Token: "TOKEN_DE_EJEMPLO",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaReportesSalas",
        Datos_Recibidos: `{"Vista":"ReporteVentas", "FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;

    setCargando(true); // Iniciar el estado de cargando

    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        try {
          if (data && data.datos_Generados) {
            const datosGeneradosLimpiados = data.datos_Generados.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
            const datosGeneradosDecodificados = JSON.parse(datosGeneradosLimpiados);
            setDatosGenerados(datosGeneradosDecodificados);
            console.log(datosGeneradosDecodificados);
          } else {
            console.error("No se recibieron datos generados válidos:", data);
          }
        } catch (error) {
          console.error("Error al decodificar los datos generados:", error);
        } finally {
          setCargando(false); // Finalizar el estado de cargando
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setCargando(false); // Finalizar el estado de cargando en caso de error
      });
  };

  // Efecto para cargar las tiendas
  useEffect(() => {
    setTiendas(["TZ10", "TPRR", "TXLA", "TMIR", "TSCR", "TCSV"]); // Cambia esto para obtener las tiendas desde la API si es necesario
  }, []);

  // Efecto para fijar la tienda si coincide con el usuario
  useEffect(() => {
    if (tiendas.includes(usuario)) {
      setFiltroTienda(usuario);
    }
  }, [tiendas, usuario]);

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const limpiarJSON = (jsonString) => {
    return jsonString.replace(/\\\\"/g, '"').replace(/\\\\/g, '\\');
  };

  const filteredData = datosGenerados.flatMap(item => {
    try {
      const detalle = JSON.parse(limpiarJSON(item.Detalle)).DETALLE || [];
      return detalle.filter(prod => {
        const searchValue = filtroTexto.toLowerCase();
        const filtroPorTienda = filtroTienda ? item.Sala === filtroTienda : true;
        return (
          (prod.ItemCode.toLowerCase().includes(searchValue) ||
            prod.Dscription.toLowerCase().includes(searchValue)) &&
          filtroPorTienda &&
          prod.Price > 0
        );
      }).map(prod => {
        const partesFecha = item.DocDate.split('/');
        const fechaFormateada = `${partesFecha[0]}/${partesFecha[1]}/${partesFecha[2]}`;

        return {
          Caja: item.Caja,
          Fecha: fechaFormateada,
          Hora: item.Hora,
          NumFactura: item.DocNum,
          nit: item.nit,
          Cliente: item.Cliente,
          Codigo: prod.ItemCode,
          Descripcion: prod.Dscription,
          UnidadesVendidas: prod.Cantidad,
          Costo: prod.Price || 0,
          Tarifa: prod.Tarifa,
          Estado: item.Estado,
          Cajero: item.Cajero,
          Tienda: item.Sala
        };
      });
    } catch (error) {
      console.error("Error al procesar el detalle:", error);
      return [];
    }
  });

  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);

  // Exportar a PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("VENTAS GENERALES", 20, 10);
    doc.autoTable({
      head: [['Caja', 'Fecha', 'Hora', 'Factura', 'NIT', 'Cliente', 'Código', 'Descripción', 'Unidades Vendidas', 'Costo', 'Tarifa', 'Estado', 'Cajero']],
      body: filteredData.map(row => [
        row.Caja,
        row.Fecha,
        row.Hora,
        row.NumFactura,
        row.nit,
        row.Cliente,
        row.Codigo,
        row.Descripcion,
        row.UnidadesVendidas,
        row.Costo,
        row.Tarifa,
        row.Estado,
        row.Cajero
      ]),
    });
    doc.save('ventas-generales.pdf');
  };

  // Exportar a Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "VentasGenerales");
    XLSX.writeFile(workbook, 'ventas-generales.xlsx');
  };

  // Filtrar tiendas según el nombre de usuario
  const tiendasFiltradas = tiendas.filter(tienda => tienda.toLowerCase().includes(usuario.toLowerCase()));

  return (
    <Container>
      <h1>VENTAS GENERALES</h1>
      <hr />
      <Grid container spacing={2} alignItems="center" direction="row" wrap="nowrap">
        <Grid item xs={3}>
          <TextField
            label="Buscar"
            value={filtroTexto}
            onChange={(e) => setFiltroTexto(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
  <FormControl fullWidth>
    <InputLabel id="tienda-select-label">Tienda</InputLabel>
    <Select
      labelId="tienda-select-label"
      value={filtroTienda}
      onChange={(e) => setFiltroTienda(e.target.value)}
    >
      <MenuItem value="">
        <em>Todas las tiendas</em>
      </MenuItem>
      {tiendasFiltradas.length > 0 ? (
        tiendasFiltradas.map((tienda, index) => (
          <MenuItem key={index} value={tienda}>
            {tienda}
          </MenuItem>
        ))
      ) : (
        tiendas.map((tienda, index) => (
          <MenuItem key={index} value={tienda}>
            {tienda}
          </MenuItem>
        ))
      )}
    </Select>
  </FormControl>
</Grid>

        <Grid item xs={2}>
          <TextField
            label="Fecha Inicio"
            type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Fecha Fin"
            type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Buscar
          </Button> 
          <Button variant="contained" color="warning" style={{ marginLeft: "4px", marginRight: "2px" }} onClick={exportToPDF}>
            PDF
          </Button>
          <Button variant="contained" color="success" onClick={exportToExcel}>
            Excel
          </Button>
        </Grid>
      </Grid> 
      
      {cargando ? (
        <CircularProgress /> // Muestra un cargando mientras se obtiene la información
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Caja</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Hora</TableCell>
                <TableCell>Factura</TableCell>
                <TableCell>NIT</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Código</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Unidades Vendidas</TableCell>
                <TableCell>Costo</TableCell>
                <TableCell>Tarifa</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Cajero</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datosPaginados.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.Caja}</TableCell>
                  <TableCell>{row.Fecha}</TableCell>
                  <TableCell>{row.Hora}</TableCell>
                  <TableCell>{row.NumFactura}</TableCell>
                  <TableCell>{row.nit}</TableCell>
                  <TableCell>{row.Cliente}</TableCell>
                  <TableCell>{row.Codigo}</TableCell>
                  <TableCell>{row.Descripcion}</TableCell>
                  <TableCell>{row.UnidadesVendidas}</TableCell>
                  <TableCell>{row.Costo}</TableCell>
                  <TableCell>{row.Tarifa}</TableCell>
                  <TableCell>{row.Estado}</TableCell>
                  <TableCell>{row.Cajero}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(filteredData.length / elementosPorPagina)}
            page={paginaActual}
            onChange={handlePageChange}
            color="primary"
          />
          <Button variant="contained" onClick={exportToPDF}>
            Exportar a PDF
          </Button>
          <Button variant="contained" onClick={exportToExcel}>
            Exportar a Excel
          </Button>
        </>
      )}
    </Container>
  );
}

export default GeneralSales;

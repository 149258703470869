import { types } from '../types/types'

export const seguridadReducer = ( state = {}, action ) => {
    switch ( action.type ){
        case types.miDatoModificar:
            return {
                ...state,
                miDatoModificar: action
            }
        default:
            return state;
    }
}
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from '../../../../../hooks/useForm';

import { clienteSelectorLoad } from '../../../../actions/selector';

export const BodyFiltroPedido = ({
    setIsLoading, setFormFiltro, formFiltro
}) => {
    const dispatch = useDispatch();
    
    const { token, user } = useSelector(state => state.auth);
    const { clienteData } = useSelector(state => state.selector);
    const { pedido, sap, fecha_1, fecha_2, fecha_sap_1, fecha_sap_2, formState, onInputChange } = useForm(formFiltro);
    const [ options, setOptions ] = useState([]);
    const [ cliente, setCliente ] = useState(null);

    useEffect(() => {
        dispatch( clienteSelectorLoad( token, user.id_usuario, setIsLoading ) );
    }, []);

    useEffect(() => {
        document.getElementById("cliente").value = formFiltro.cliente;
    }, [onInputChange]);

    useEffect(() => {
        if(clienteData){
            let lista = [];
            clienteData?.map((item) => {
                lista.push({ values: item.Id, label: `${item.Id} - ${item.Nombre}` });
            });
            setOptions(lista);
        }
    }, [clienteData]);

    useEffect(() => {
        setFormFiltro({
            pedido: pedido, 
            sap: sap,
            fecha_1: fecha_1,
            fecha_2: fecha_2, 
            fecha_sap_1: fecha_sap_1, 
            fecha_sap_2: fecha_sap_2, 
            cliente: cliente?.values
        })
    }, [pedido, sap, fecha_1, fecha_2, fecha_sap_1, fecha_sap_2, cliente]);

  return (
    <form>
        <div className='row g-3'>
            <div className='col-12 col-md-6'>
                <label className='form-label'>No. Pedido</label>
                <input type='text' className='form-control form-sm' 
                    name='pedido' value={ pedido } onChange={ onInputChange }/>
            </div>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Pedido trasladado a SAP</label>
                <select className='form-select form-sm' 
                    name='sap' value={ sap } onChange={ onInputChange }>
                        <option value='0'>NO</option>
                        <option value='1'>SI</option>
                </select>
            </div>

            <div className='col-12 col-md-12'>
                <label className='form-label'>Cliente</label>
                <Select isClearable
                    className='form-sm' id='cliente' name='cliente'
                    onChange={ setCliente } placeholder={ 'Seleccione un cliente' }
                    options={ options }/>
            </div>
        </div>
        <div className='row mt-3'>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Fecha Inicio</label>
                <input type='date' className='form-control form-sm'
                    name='fecha_1' value={ fecha_1 } onChange={ onInputChange }/>
            </div>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Fecha Fin</label>
                <input type='date' className='form-control form-sm'
                    name='fecha_2' value={ fecha_2 } onChange={ onInputChange }/>
            </div>
        </div>
        <div className='row mt-3'>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Fecha SAP Inicio</label>
                <input type='date' className='form-control form-sm'
                    name='fecha_sap_1' value={ fecha_sap_1 } onChange={ onInputChange }/>
            </div>
            <div className='col-12 col-md-6'>
                <label className='form-label'>Fecha SAP Fin</label>
                <input type='date' className='form-control form-sm'
                    name='fecha_sap_2' value={ fecha_sap_2 } onChange={ onInputChange }/>
            </div>
        </div>
    </form>
  )
}

import { types } from '../types/types'

export const selectorReducer = ( state = {}, action ) => {
    switch ( action.type ){
        case types.producto:
            return {
                ...state,
                productoData: action.selector
            }
        case types.devolucion:
            return {
                ...state,
                devolucionData: action.selector
            }
        case types.motivoEliminar:
            return {
                ...state,
                motivoEliminarData: action.selector
            }
        case types.motivoFinalizar:
            return {
                ...state,
                motivoFinalizarData: action.selector
            }
        case types.cliente:
            return {
                ...state,
                clienteData: action.selector
            }
        case types.procesoTracking:
            return {
                ...state,
                procesoTrackingData: action.selector
            }
        case types.usuarioColaborador:
            return {
                ...state,
                usuarioColaboradorData: action.selector
            }
        default:
            return state;
    }
}
    
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import Slider from 'react-slick';
import axios from 'axios';
import Swal from 'sweetalert2';

import { Loading } from '../../../../ui/Loading';
import { EncabezadoFormulario } from '../../../../elements/EncabezadoFormulario';
import { pedidoLoadForm, pedidoTransferirSAP } from '../../../../actions/comercial'; 
//import 'slick-carousel/slick/slick.css';
//import 'slick-carousel/slick/slick-theme.css';

export const FormularioPedido = ({Id_Pedido, setIsOpenVisor}) => {


    const dispatch = useDispatch();
    const { token, user } = useSelector(state => state.auth);
    const { pedidoEncabezado, pedidoDetalle } = useSelector(state => state.comercial);
    const [ isLoading, setIsLoading ] = useState(false);
    const [downloadedImages, setDownloadedImages] = useState([]);
    let contador = 0;
    let handlerSAP = null;

    const [idPedido, setIdPedido] = useState('');
    const [idCliente, setIdCliente] = useState('');
    const [cliente, setCliente] = useState('');
    const [tipo_pedido, setTipoPedido] = useState('');
    const [vendedor, setVendedor] = useState('');

    const [solicitante, setSolicitante] = useState('');
    const [telefono, setTelefono] = useState('');
    const [tipo_cita, setTipoCita] = useState('');
    const [hora_inicio, setHoraInicio] = useState('');
    const [hora_fin, setHoraFin] = useState('');
    const [excepcion, setExcepcion ] = useState('');

    
    const [fecha_entrega, setFechaEntrega] = useState('');
    const [comentario, setComentario] = useState('');
    
    useEffect(async () => {
        dispatch( pedidoLoadForm( token, user.id_usuario, Id_Pedido, setIsLoading ) ); 
        // Llama a la función para descargar imágenes al cargar el formulario
           //await handlerDesgargar();
    }, []);

    useEffect(() => {
        if(pedidoEncabezado){
            pedidoEncabezado.map(({ ID_Pedido, ID_Cliente, Cliente, Fecha_Entrega, ID_Tipo_Pedido, Tipo_Pedido,
                ID_Vendedor, Vendedor, Nombre_Solicitante, Telefono_Contacto, ID_Tipo_Cita, Tipo_Cita, 
                Hora_Inicio, Hora_Fin, Excepciones, Comentario }) => {
                setIdPedido(ID_Pedido);
                setIdCliente(ID_Cliente);
                setCliente(`${ID_Cliente} - ${Cliente}`);
                setTipoPedido(`${ID_Tipo_Pedido} - ${Tipo_Pedido}`);
                setVendedor(`${ID_Vendedor} - ${Vendedor}`);
                setSolicitante(Nombre_Solicitante);
                setTelefono(Telefono_Contacto);
                setTipoCita(`${ID_Tipo_Cita} - ${Tipo_Cita}`);
                setHoraInicio(Hora_Inicio);
                setHoraFin(Hora_Fin);
                setExcepcion(Excepciones);
                setFechaEntrega(Fecha_Entrega);
                setComentario(Comentario);
            });
        }
    }, [pedidoEncabezado]);

    const handlerEnviarSAP = (e) =>{
        e.preventDefault();
        dispatch( pedidoTransferirSAP(token, user.id_usuario, idPedido, "", cliente, setIsLoading) );
    }

    user.permiso.filter(item => item.Permiso === 'ROOT').map(filteredName => {
        handlerSAP = { color: 'btn-warning', icono: 'fa-shuffle', titulo: 'Transferir a SAP', call: handlerEnviarSAP };
    });

    user.permiso.filter(item => item.Permiso === 'USUARIO_SAP').map(filteredName => {
        handlerSAP = { color: 'btn-warning', icono: 'fa-shuffle', titulo: 'Transferir a SAP', call: handlerEnviarSAP };
    });
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      }; 

     
    
      /*const handlerDesgargar = () => { 

       // e.preventDefault();
    
        setIsLoading(true);
        const datos = {
          id_usuario: user.id_usuario, id_pedido: idPedido
        } 

        console.log('los datos ' + datos);
        axios.defaults.headers.common['Authorization'] = "Bearer " + token;
        axios.post( `${ process.env.REACT_APP_API_COMERCIAL }consulta/descargar_orden_compra`, datos )
    .then(response => {
        let json = response.data;
        if (json.stt === "OK") {
            let imagen = JSON.parse(json.data);
            console.log("Contenido de la imagen:", imagen);
            const downloadedImages = Array.isArray(imagen) ? imagen : [imagen];
            setDownloadedImages(downloadedImages);
        } else {
            Swal.fire('Pedido', json.msg, 'error');
        }
        setIsLoading(false);
    })
    .catch(({ message }) => {
        setIsLoading(false);
        Swal.fire('Pedido', message, 'error');
    });

      } 
      console.log(downloadedImages);*/
  
  return (
    <>
        <Loading isLoading={isLoading} />
        <div className="container">
            <EncabezadoFormulario 
                Titulo={`Visor modo lectura pedido #${ Id_Pedido }`}
                btnAdicional={ handlerSAP }
                setIsOpen = { setIsOpenVisor }/>
            <form className="g-3">
                <div className='row'>
                    <div className="col-md-3">
                        <label className="form-label">No. Pedido</label>
                        <label className="form-control form-control-sm text-center">{ idPedido }</label>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Cliente</label>
                        <label className="form-control form-control-sm text-center">{ cliente }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Fecha Entrega</label>
                        <label className="form-control form-control-sm text-center">{ fecha_entrega }</label>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-3">
                        <label className="form-label">Tipo Pedido</label>
                        <label className="form-control form-control-sm text-center">{ tipo_pedido }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Vendedor</label>
                        <label className="form-control form-control-sm text-center">{ vendedor }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Solicitante</label>
                        <label className="form-control form-control-sm text-center">{ solicitante }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Telefono</label>
                        <label className="form-control form-control-sm text-center">{ telefono }</label>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-3">
                        <label className="form-label">Tipo de Cita</label>
                        <label className="form-control form-control-sm text-center">{ tipo_cita }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Horario Inicio</label>
                        <label className="form-control form-control-sm text-center">{ hora_inicio }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Horario Final</label>
                        <label className="form-control form-control-sm text-center">{ hora_fin }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Excepcion</label>
                        <label className="form-control form-control-sm text-center">{ excepcion }</label>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <label className="form-label">Comentario</label>
                        <label className="form-control form-control-sm text-center">{ comentario }</label>
                    </div>
                </div>
            </form>

            <hr className='bg-danger'/>

            <div className="table-responsive">
                <table className='table table-sm table-striped table-bordered'>
                    <thead>
                        <tr>
                            <th>Codigo</th>
                            <th>Producto</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead> 
                    <tbody>
                    {
                    pedidoDetalle?.map(({ID_Producto, Producto, Cantidad}) => (
                        <tr key={contador++}>
                            <td>{ID_Producto}</td>
                            <td>{Producto}</td>
                            <td>{Cantidad}</td>
                        </tr>  
                        ))
                    }  
                    </tbody>               
                </table>
            </div> 
            <div > 
        { /*       <h1>carrusel</h1>
                <Slider {...settings}>
  {downloadedImages.map((image, index) => (
    <div key={index}>
      <img src={`data:image/png;base64,${image.Imagen}`} alt={`Slide ${index}`} />
    </div>
  ))}
</Slider>
*/}
            </div>
        </div>
    </>
  )
}

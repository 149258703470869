
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Container, TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Pagination } from '@mui/material';

function ListaOM() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState(""); 
  const [filtroTexto, setFiltroTexto] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(10);

  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaOM",
        Datos_Recibidos: `{"FECHAINI":"${fechaInicio}","FECHAFIN":"${fechaFin}"}`
      }
    };
   

    console.log(datos)
    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
  
    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
        console.log(datosGeneradosDecodificados);
        setDatosGenerados(datosGeneradosDecodificados);
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, [fechaInicio, fechaFin]);

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };
  const filteredData = datosGenerados ? datosGenerados.filter(item => {
    const searchValue = filtroTexto.toLowerCase();
    return (
      item.Codigo.toLowerCase().includes(searchValue) ||
      item.Fechacreacion.toLowerCase().includes(searchValue) ||
      item.CodCliente.toLowerCase().includes(searchValue) || 
      item.NombreCliente.toLowerCase().includes(searchValue) ||
      item.Vendedor.toLowerCase().includes(searchValue) ||
      item.Telefono.toLowerCase().includes(searchValue) ||
      item.Estado.toLowerCase().includes(searchValue)
    );
  }) : [];
  
  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);
  return (
  
      <div>
     <div style={{ marginBottom: '20px', justifyContent: 'space-between' }}>
     <TextField
        id="filtroTexto"
        label="Buscar"
        value={filtroTexto}
        onChange={(e) => setFiltroTexto(e.target.value)}
        variant="outlined"
        style={{ marginBottom: '10px', width: '500px' }}
      />
        <TextField
          id="fechaInicio"
          label="Fecha de Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px', left: '10px' }}
        />
        <TextField
          id="fechaFin"
          label="Fecha de Fin"
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' , left: '10px' }}
        />
        <Button variant="contained" onClick={handleSearch}   style={{ marginRight: '10px' , left: '10px' , height: '55px', width: '100px'}} >Buscar</Button>
        </div>
       
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Código</TableCell>
            <TableCell>Fecha de Creación</TableCell>
            <TableCell>Código del Cliente</TableCell>
            <TableCell>Nombre del Cliente</TableCell>
            <TableCell>Vendedor</TableCell>
            <TableCell>Teléfono</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosPaginados.map(item => (
            <TableRow key={item.Codigo}>
              <TableCell>{item.Codigo}</TableCell>
              <TableCell>
  {new Date(item.Fechacreacion).toLocaleDateString('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })}
</TableCell>
              <TableCell>{item.CodCliente}</TableCell>
              <TableCell>{item.NombreCliente}</TableCell>
              <TableCell>{item.Vendedor}</TableCell>
              <TableCell>{item.Telefono}</TableCell>
              <TableCell>{item.Estado}</TableCell>
              <TableCell>
                <a href={`detalleom/${item.Codigo}`}>
                  <FontAwesomeIcon icon={faEdit} />
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

      </Table> 
      <Pagination
        count={Math.ceil(filteredData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
      </div>
    
  );
}

export default ListaOM;

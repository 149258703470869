import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loading } from '../../../ui/Loading';
import { EncabezadoFormulario } from '../../../elements/EncabezadoFormulario';
import { valeLoadForm } from '../../../actions/comercial';

export const FormularioVale = ({Id_Devolucion, setIsOpenVisor}) => {
    const dispatch = useDispatch();
    const { token, user } = useSelector(state => state.auth);
    const { valeEncabezado, valeDetalle } = useSelector(state => state.comercial);
    const [ isLoading, setIsLoading ] = useState(false);

    const [idVale, setIdVale] = useState('');
    const [cliente, setCliente] = useState('');
    const [fecha_ruta, setFechaRuta] = useState('');
    const [tracking, setTracking] = useState('');
    const [bodega, setBodega] = useState('');
    const [estado, setEstado] = useState('');
    const [fecha_creacion, setFechaCreacion] = useState('');
    const [observacion, setObservacion] = useState('');
    
    useEffect(() => {
        dispatch( valeLoadForm( token, user.id_usuario, Id_Devolucion, setIsLoading ) );
    }, []);

    useEffect(() => {
        if(valeEncabezado){
            valeEncabezado.map(({ ID_Vale, ID_Cliente, Cliente, Fecha_Ruta, Tracking, Bodega, Estado, Fecha, Hora, Observacion }) => {
                setIdVale(ID_Vale);
                setCliente(`${ID_Cliente} - ${Cliente}`);
                setFechaRuta(Fecha_Ruta);
                setTracking(Tracking);
                setBodega(Bodega);
                if(Estado == 1)
                    setEstado("Activo");
                else if(Estado == 2)
                    setEstado("Inactivo");
                else
                    setEstado("Anulado");
                setFechaCreacion(Fecha+" "+Hora);
                setObservacion(Observacion);
            });
        }
    }, [valeEncabezado]);

  return (
    <>
        <Loading isLoading={isLoading} />
        <div className="container">
            <EncabezadoFormulario 
                Titulo={`Visor modo lectura vale #${ Id_Devolucion }`} 
                setIsOpen = { setIsOpenVisor }/>

            <form className="g-3">
                <div className='row'>
                    <div className="col-md-3">
                        <label className="form-label">No. Vale</label>
                        <label className="form-control form-control-sm text-center">{ idVale }</label>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Cliente</label>
                        <label className="form-control form-control-sm text-center">{ cliente }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Fecha Creación</label>
                        <label className="form-control form-control-sm text-center">{ fecha_creacion }</label>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-3">
                        <label className="form-label">Tracking</label>
                        <label className="form-control form-control-sm text-center">{ tracking }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Bodega</label>
                        <label className="form-control form-control-sm text-center">{ bodega }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Estado</label>
                        <label className="form-control form-control-sm text-center">{ estado }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Fecha Ruta</label>
                        <label className="form-control form-control-sm text-center">{ fecha_ruta }</label>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <label className="form-label">Observación</label>
                        <label className="form-control form-control-sm text-center">{ observacion }</label>
                    </div>
                </div>
            </form>

            <hr className='bg-danger'/>

            <div className="table-responsive">
                <table className='table table-sm table-striped table-bordered'>
                    <thead>
                        <tr>
                            <th>Codigo</th>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Lote</th>
                            <th>Vencimiento</th>
                            <th>Motivo</th>
                        </tr>
                    </thead> 
                    <tbody>
                    {
                    valeDetalle?.map(({Lote, Fecha_Vencimiento, Motivo_Devolucion, ID_Producto, Producto, Cantidad}) => (
                        <tr key={ID_Producto}>
                            <td>{ID_Producto}</td>
                            <td>{Producto}</td>
                            <td>{Cantidad}</td>
                            <td>{Lote}</td>
                            <td>{Fecha_Vencimiento}</td>
                            <td>{Motivo_Devolucion}</td>
                        </tr>  
                        ))
                    }  
                    </tbody>               
                </table>
            </div>
        </div>
    </>
  )
}

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Container, TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Pagination } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function SalesExperienceClient() {
  const [datosGenerados, setDatosGenerados] = useState({ Detalle_Encuesta: [], Resumen_Encuesta: [] });
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState(""); 
  const [filtroTexto, setFiltroTexto] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(25);

  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "SACDatosAnalisis",
        Datos_Recibidos: `{"FechaInicio":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    };
  
    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
  
    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
        setDatosGenerados(datosGeneradosDecodificados);
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, [fechaInicio, fechaFin]);

  const handleSearch = () => {
    fetchData();
  };
  const handleExportExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Detalle Encuesta');
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'Detalle_Encuesta.xlsx');
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const filteredData = datosGenerados.Detalle_Encuesta.filter(item => {
    const searchValue = filtroTexto.toLowerCase();
    return (
      item.Tienda.toLowerCase().includes(searchValue) ||
      item.Pregunta.toLowerCase().includes(searchValue) ||
      item.Observacion.toLowerCase().includes(searchValue)
    );
  });

  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);

  return (
    <Container> 

        <div>
        {datosGenerados.Resumen_Encuesta.length > 0 && (
        <div style={{ marginBottom: '20px' }}>
          <h2>Resumen de la Encuesta</h2>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>Tienda</TableCell>
                <TableCell>Pregunta</TableCell>
                <TableCell>Promedio Punteo</TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {datosGenerados.Resumen_Encuesta.map((resumen, index) => (
                <TableRow key={index}>
                    <TableCell>{resumen.Tienda}</TableCell>
                  <TableCell>{resumen.Pregunta}</TableCell>
                  <TableCell>{resumen.Promedio}</TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
        </div>
      <br />
      <br />
      <br />
      <div style={{ marginBottom: '20px', justifyContent: 'space-between' }}>
        <TextField
          id="filtroTexto"
          label="Buscar"
          value={filtroTexto}
          onChange={(e) => setFiltroTexto(e.target.value)}
          variant="outlined"
          style={{ marginBottom: '10px', width: '500px' }}
        />
        <TextField
          id="fechaInicio"
          label="Fecha de Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px', left: '10px' }}
        />
        <TextField
          id="fechaFin"
          label="Fecha de Fin"
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px', left: '10px' }}
        />
     { /* <Button variant="contained" onClick={handleSearch} style={{ marginRight: '10px', left: '10px', height: '55px', width: '100px' }}>
          Buscar
        </Button> 
        */} 
        <Button variant="contained" onClick={handleExportExcel} style={{ marginRight: '10px', left: '10px', height: '55px', width: '100px' }}>
        Exportar a Excel
      </Button>


      </div>
        
        
    <br />
    <br />

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Tienda</TableCell>
            <TableCell>Pregunta</TableCell>
            <TableCell>Punteo</TableCell>
            <TableCell>Observación</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosPaginados.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.Fecha}</TableCell>
              <TableCell>{item.Tienda}</TableCell>
              <TableCell>{item.Pregunta}</TableCell>
              <TableCell>{item.Punteo}</TableCell>
              <TableCell>{item.Observacion}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(filteredData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </Container>
  );
}

export default SalesExperienceClient;

import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { utils, writeFile } from 'xlsx';

const ListaCA = () => {
  const [datosGenerados, setDatosGenerados] = useState([]);

  // Fetch de los datos
  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Dispositivo: {
        Marca: "SAMSUNG",
        Modelo: "S22002B",
        Version: "Android 18.125",
        McAdress: null,
        PowerStatus: "84%",
        WifiStatus: "Conected to iphone de Mauricio",
        GPSStatus: "ON",
        GPS: "321|-485|321"
      },
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaParaDashBoardCA",
        Datos_Recibidos: ""
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;

    fetch(fetchDataUrl)
      .then(response => response.json())
      .then(data => {
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
        console.log('Datos generados:', datosGeneradosDecodificados); // Verifica los datos recibidos
        setDatosGenerados(datosGeneradosDecodificados);
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Procesar los datos para las gráficas y tablas
  const centers = [...new Set(datosGenerados.map(record => record.NombreCentroAcopio))];
  const recordsByCenterArray = centers.map(center => ({
    center,
    totalWeight: datosGenerados
      .filter(record => record.NombreCentroAcopio === center)
      .reduce((sum, record) => sum + (record.Peso || 0), 0),
    details: datosGenerados.filter(record => record.NombreCentroAcopio === center)
  }));

  const centerBarChartData = {
    labels: recordsByCenterArray.map(item => item.center),
    datasets: [
      {
        label: 'Peso por Centro de Acopio',
        data: recordsByCenterArray.map(item => item.totalWeight),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }
    ]
  };

  // Datos para Dashboard por Estado
  const estados = {
    1: 'CENTRO DE ACOPIO',
    2: 'Transporte',
    3: 'Recepcionado'
  };

  const recordsByStateArray = Object.keys(estados).map(state => {
    const filteredRecords = datosGenerados.filter(record => record.Estado === parseInt(state));
    console.log(`Registros filtrados para el estado ${state} (${estados[state]}):`, filteredRecords); // Verificar filtrado por estado
    return {
      state: estados[state],
      totalWeight: filteredRecords.reduce((sum, record) => sum + (record.Peso || 0), 0),
      details: filteredRecords
    };
  });

  console.log('Datos para la gráfica por estado:', recordsByStateArray); // Verificar los datos para la gráfica

  const stateBarChartData = {
    labels: recordsByStateArray.map(item => item.state),
    datasets: [
      {
        label: 'Peso por Estado',
        data: recordsByStateArray.map(item => item.totalWeight),
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1
      }
    ]
  };

  // Función para exportar los datos a Excel
  const exportToExcel = () => {
    const worksheetData = recordsByCenterArray.flatMap(item =>
      item.details.map(detail => ({
        Centro_Acopio: item.center,
        Usuario: detail.Nombre_Usuario,
        Código_Proveedor: detail.CodProveedor,
        Nombre_Proveedor: detail.NombreProveedor,
        Observaciones: detail.Observaciones || 'N/A',
        Temperatura: detail.Temperatura + ' °F',
        Peso: detail.Peso,
        Peso_Tara: detail.PesoTara
      }))
    );

    const worksheet = utils.json_to_sheet(worksheetData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Centros de Acopio");

    writeFile(workbook, 'centros_de_acopio.xlsx');
  };

  return (
    <div>
      {/* Dashboard por Centro de Acopio */}
      <h2>Dashboard por Centro de Acopio</h2>
      <Bar data={centerBarChartData} />
      <button onClick={exportToExcel}>Exportar a Excel</button>
      <table>
        <thead>
          <tr>
            <th>Centro de Acopio</th>
            <th>Usuario</th>
            <th>Código de Proveedor</th>
            <th>Nombre del Proveedor</th> 
            <th>Observaciones</th>
            <th>Temperatura</th>
            <th>Peso</th>
            <th>Peso Tara</th> 
          </tr>
        </thead>
        <tbody>
          {recordsByCenterArray.map(item => (
            item.details.map(detail => (
              <tr key={detail.CodigoRegistro}>
                <td>{item.center}</td> 
                <td>{detail.Nombre_Usuario}</td>
                <td>{detail.CodProveedor}</td>
                <td>{detail.NombreProveedor}</td> 
                <td>{detail.Observaciones || 'N/A'}</td>
                <td>{detail.Temperatura} °F</td>
                <td>{detail.Peso}</td>
                <td>{detail.PesoTara}</td> 
              </tr>
            ))
          ))}
        </tbody>
      </table>

      {/* Dashboard por Estado */}
      <h2>Dashboard por Estado</h2>
      <Bar data={stateBarChartData} />

      {/* Tablas por Estado */}
      {recordsByStateArray.map(item => (
        <div key={item.state}>
          <h3>Estado: {item.state}</h3>
          <table>
            <thead>
              <tr>
                <th>Usuario</th>
                <th>Código de Proveedor</th>
                <th>Nombre del Proveedor</th>
                <th>Observaciones</th>
                <th>Temperatura</th>
                <th>Peso</th>
                <th>Peso Tara</th>
              </tr>
            </thead>
            <tbody>
              {item.details.map(detail => (
                <tr key={detail.CodigoRegistro}>
                  <td>{detail.Nombre_Usuario}</td>
                  <td>{detail.CodProveedor}</td>
                  <td>{detail.NombreProveedor}</td>
                  <td>{detail.Observaciones || 'N/A'}</td>
                  <td>{detail.Temperatura} °F</td>
                  <td>{detail.Peso}</td>
                  <td>{detail.PesoTara}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default ListaCA;

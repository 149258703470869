import React from 'react'

export const LayoutScreen = () => {
  return (
    <>
      <div className="p-4 p-md-5 mb-4 text-white rounded bg-dark">
        <div className="col-md-8 px-0">
          <h1 className="display-4 fst-italic">Bienvenido al territorio PARMA</h1>
          <p className="lead my-3">Creando comunidad entre campo y ciudad.</p>
          <p className="lead mb-0"><a href="https://www.parma.gt" target="_blank" className="text-white">
            <span className="fa-solid fa-globe"></span> www.parma.gt</a>
          </p>
          <p className="lead mb-0"><a href="https://tienda.parma.gt" target="_blank" className="text-white">
            <span className="fa-solid fa-cart-shopping"></span> tienda.parma.gt</a>
          </p>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import { Routes, Route } from "react-router-dom";
import ListCA from '../componentes/view/acopios/centros/listca'; 
import ListaCaRep from '../componentes/view/acopios/recepcion/carep';


export const CentroAcopioRoutes = () => {

    return (
      <Routes>
       
        <Route path="dashboards/ca" element={ <ListCA /> } />
        <Route path="lista/ca" element={ <ListaCaRep/> } />
        
      </Routes>
    )
  }
  
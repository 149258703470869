import { types } from '../types/types'

export const comercialReducer = ( state = {}, action ) => {
    switch ( action.type ){
        case types.vale:
            return {
                ...state,
                valeTabla: action.data
            }
        case types.valeDetalle:
            return {
                ...state,
                valeDetalle: action.data
            }
        case types.valeForm:
            return {
                ...state,
                valeEncabezado: action.data.encabezado,
                valeDetalle: action.data.detalle
            }
        case types.valeDetalleAgregar:
            return {
                ...state,
                idValeDetalle: action.data
            }
        case types.valeEliminar:
            return {
                ...state,
                idVale: action.data
            }
        case types.valeFinalizar:
            return {
                ...state,
                idVale: action.data
            }
        case types.valeRuta:
            return {
                ...state,
                idVale: action.data
            }
        case types.valeMotivoSinLiquidar:
            return {
                ...state,
                motivoSinLiquidar: action
            }

        case types.pedido:
            return {
                ...state,
                pedidoTabla: action.data
            }
        case types.pedidoDetalle:
            return {
                ...state,
                pedidoDetalle: action.data
            }
        case types.pedidoForm:
            return {
                ...state,
                pedidoEncabezado: action.data.encabezado,
                pedidoDetalle: action.data.detalle
            }
        case types.pedidoDetalleAgregar:
            return {
                ...state,
                idPedidoDetalle: action
            }
        case types.pedidoEliminar:
            return {
                ...state,
                idPedido: action
            }
        case types.pedidoSAP:
            return {
                ...state,
                idPedido: action
            }
        case types.pedidoComentario:
            return {
                ...state,
                pedidoComentario: action
            }
        case types.colaboradorXClienteTabla:
            return {
                ...state,
                colaboradorXClienteTabla: action.data
            }
        case types.colaboradorXClienteLinea:
            return {
                ...state,
                colaboradorXClienteLinea: action
            }
        case types.colaboradorXClienteEstado:
            return {
                ...state,
                colaboradorXClienteEstado: action
            }
        default:
            return state;
    }
}

import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const MovimientoTienda = () => {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [datosFiltrados, setDatosFiltrados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filtroTexto, setFiltroTexto] = useState('');
  const [filtroTienda, setFiltroTienda] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { token, user } = useSelector(state => state.auth); 

  const setTiendas = ["TZ10", "TPRR", "TSCR", "TCSV", "TMIR", "TXLA"];
  const [filteredTiendas, setFilteredTiendas] = useState(setTiendas);

  useEffect(() => {
    const matchingTiendas = setTiendas.filter(tienda => tienda.includes(user.usuario));
    setFilteredTiendas(matchingTiendas.length > 0 ? matchingTiendas : setTiendas);
    if (matchingTiendas.length === 0) {
      setFiltroTienda('');
    }
  }, [user.usuario]);

  const fetchDataMovimientos = async () => {
    setLoading(true);
    setError(null);
    let userconver1 = String(user.id_usuario);

    try {
      const datos = {
        Token: "LJKNFUH2FWEJBAEFFFFFKJVGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
        Ambiente: "DESA",
        Dispositivo: {
          Marca: "SAMSUNG",
          Modelo: "S22002B",
          Version: "Android 18.125",
        },
        Usr: {
          Codigo: userconver1,
          UserNickName: user.usuario
        },
        DatosMetodo: {
          NombreMetodo: "ListaReportesSalas",
          Datos_Recibidos: `{"Vista":"MovimientoTiendaFecha", "FechaIni":"${fechaInicio}", "FechaFin":"${fechaFin}", "Tienda":"${filtroTienda}"}` 
        }
      };

      const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`;
      const response = await fetch(fetchDataUrl);

      if (!response.ok) {
        throw new Error('Error al obtener los datos');
      }

      const data = await response.json();

      if (data && data.datos_Generados) {
        const datosGeneradosLimpiados = typeof data.datos_Generados === 'string' 
          ? JSON.parse(data.datos_Generados) 
          : data.datos_Generados;
        setDatosGenerados(datosGeneradosLimpiados); 
        setDatosFiltrados(datosGeneradosLimpiados); 
      } else {
        setDatosGenerados([]);
        setDatosFiltrados([]);
      }

    } catch (error) {
      setError('Error al obtener los datos');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    const textoFiltrado = filtroTexto.toLowerCase();

    const datosFiltradosLocal = datosGenerados.filter(dato =>
      (dato.ItemCode && dato.ItemCode.toLowerCase().includes(textoFiltrado)) ||
      (dato.ItemName && dato.ItemName.toLowerCase().includes(textoFiltrado)) ||
      (dato.Comments && dato.Comments.toLowerCase().includes(textoFiltrado))
    );

    setDatosFiltrados(datosFiltradosLocal);
  };

  const handleApiSearch = () => {
    if (!filtroTienda) {
      alert("Seleccione una tienda por favor");
    } else {
      fetchDataMovimientos();
    }
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Reporte de Movimiento de Tienda", 20, 10);
    doc.autoTable({
      head: [['Sala', 'Fecha', 'Código', 'Descripción', 'Cantidad', 'Movimiento', 'Número Movimiento', 'Comentarios']],
      body: datosFiltrados.map(row => [
        row.Warehouse, row.DocDate, row.ItemCode, row.ItemName, row.Quantity, row.Movimiento, row.NumMov, row.Comments
      ])
    });
    doc.save('movimiento_tienda.pdf');
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(datosFiltrados);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Movimientos");
    XLSX.writeFile(workbook, 'movimiento_tienda.xlsx');
  };

  return (
    <div>
      <h1>Entradas Y Salidas de Producto</h1>
      <hr />

      <Grid container spacing={2} alignItems="center" marginBottom={2}>
        <Grid item xs={2}>
          <TextField
            id="filtroTexto"
            label="Buscar"
            value={filtroTexto}
            onChange={(e) => {
              setFiltroTexto(e.target.value);
              handleSearch(); // Buscar automáticamente al cambiar el texto
            }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Select
            label="Tienda"
            value={filtroTienda}
            onChange={(e) => setFiltroTienda(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Todas las tiendas</MenuItem>
            {filteredTiendas.map((tienda, index) => (
              <MenuItem key={index} value={tienda}>{tienda}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="fechaInicio"
            label="Fecha de Inicio"
            type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="fechaFin"
            label="Fecha de Fin"
            type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" onClick={handleApiSearch}>
            Buscar 
          </Button>
          <Button variant="contained" color="warning" style={{ marginLeft: "4px", marginRight: "4px" }} onClick={exportToPDF}>
            PDF
          </Button>
          <Button variant="contained" color="success" onClick={exportToExcel}>
            Excel
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        <p>Cargando...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sala</TableCell>
                  <TableCell>Fecha</TableCell>
                  <TableCell>Código</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Cantidad</TableCell>
                  <TableCell>Movimiento</TableCell>
                  <TableCell>Número Movimiento</TableCell>
                  <TableCell>Comentarios</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datosFiltrados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.Warehouse}</TableCell>
                    <TableCell>{row.DocDate}</TableCell>
                    <TableCell>{row.ItemCode}</TableCell>
                    <TableCell>{row.ItemName}</TableCell>
                    <TableCell>{row.Quantity}</TableCell>
                    <TableCell>{row.Movimiento}</TableCell>
                    <TableCell>{row.NumMov}</TableCell>
                    <TableCell>{row.Comments}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={datosFiltrados.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </>
      )}
    </div>
  );
};

export default MovimientoTienda;

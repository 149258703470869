import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useForm } from '../../../../hooks/useForm';
import axios from 'axios';
import Swal from 'sweetalert2';

import { Loading } from '../../../ui/Loading';
import { EncabezadoFormulario } from '../../../elements/EncabezadoFormulario';
import { valeLoadForm, valeDetalleLoad, valeDetalleAgregar, valeDetalleEliminar, valeDetalleCantidad } from '../../../actions/comercial';
import { productoSelectorLoad, devolucionSelectorLoad } from '../../../actions/selector';

export const FormularioValeEditar = ({Id_Devolucion, setIsOpenEditor} ) => {

    const dispatch = useDispatch();

    const { token, user } = useSelector(state => state.auth);
    const { valeEncabezado, valeDetalle, idValeDetalle } = useSelector(state => state.comercial);
    const { productoData, devolucionData } = useSelector(state => state.selector);
    const [ isLoading, setIsLoading ] = useState(false);
    const motivoSelector = useRef();
    const [ options, setOptions ] = useState([]);
    const [ producto, setProducto ] = useState(null);

    const { lote, vencimiento, cantidad, onInputChange, onResetForm } = useForm({
        lote: '', vencimiento: '', cantidad: ''
    });

    const [idVale, setIdVale] = useState('');
    const [cliente, setCliente] = useState('');
    const [fecha_creacion, setFechaCreacion] = useState('');
    const [observacion, setObservacion] = useState('');
    
    useEffect(() => {
        dispatch( valeLoadForm( token, user.id_usuario, Id_Devolucion, setIsLoading ) );
        dispatch( devolucionSelectorLoad( token, user.id_usuario, setIsLoading ) );
    }, []);

    useEffect(() => {
        if(valeEncabezado){
            valeEncabezado.map(({ ID_Vale, ID_Cliente, Cliente, Fecha, Hora, Observacion }) => {
                setIdVale(ID_Vale);
                setCliente(`${ID_Cliente} - ${Cliente}`);
                setFechaCreacion(Fecha+" "+Hora);
                setObservacion(Observacion);

                dispatch( productoSelectorLoad( token, user.id_usuario, 'N', ID_Cliente, setIsLoading ) );
            });
        }
    }, [valeEncabezado]); 
    
    useEffect(() => {
        if(productoData){
            let lista = [];
            productoData?.map((item) => {
                lista.push({ values: item.Id, label: `${item.Id} - ${item.Nombre}` });
            });
            setOptions(lista);
        }
    }, [productoData]);
    
    useEffect(() => {
      if(idValeDetalle){
        dispatch( valeDetalleLoad( token, user.id_usuario, Id_Devolucion, setIsLoading ) );
        onResetForm({producto: '-1', motivo: '-1', lote: '', vencimiento: '', cantidad: ''});
      }
    }, [idValeDetalle]);

    const onNuevoRegistro = (e) => {
        e.preventDefault();
        
        dispatch( valeDetalleAgregar(
            token, user.id_usuario, Id_Devolucion, producto?.values, cantidad, 
            vencimiento, lote, motivoSelector.current.value, setIsLoading
        ));
    }

    const onEliminarRegistro = (e, id_detalle) => {
        e.preventDefault();

        dispatch( valeDetalleEliminar(
            token, user.id_usuario, Id_Devolucion, id_detalle, setIsLoading
        ));
    }

    const onCambiarCantidaRegistro = async(e, id_detalle, cantidad) => {
        e.preventDefault();

        const { value: cantidad_detalle } = await Swal.fire({
            title: 'Vale',
            input: 'number',
            inputLabel: 'Ingrese cantidad a cambiar',
            inputPlaceholder: '0',
            inputValue: cantidad,
            showCancelButton: true,
            allowOutsideClick: false,
            inputValidator: (value) => {
                if (!value) {
                    return 'Debe ingresar una cantidad'
                }
                if (parseInt(value) < 1) {
                    return 'La cantidad ingresada debe ser mayor a cero'
                }
            }
        });
        if(cantidad_detalle){      
            dispatch( valeDetalleCantidad(
                token, user.id_usuario, Id_Devolucion, id_detalle, cantidad_detalle, setIsLoading
            ));
        }
    }

  return (
    <>
        <Loading isLoading={isLoading} />
        <div className="container">
            <EncabezadoFormulario 
                Titulo = {`Modificar detalle vale #${ Id_Devolucion }`} 
                setIsOpen = { setIsOpenEditor }/>

            <div className="g-3">
                <div className='row'>
                    <div className="col-md-3">
                        <label className="form-label">No. Vale</label>
                        <label className="form-control form-control-sm text-center">{ idVale }</label>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">Cliente</label>
                        <label className="form-control form-control-sm text-center">{ cliente }</label>
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Fecha Creacion</label>
                        <label className="form-control form-control-sm text-center">{ fecha_creacion }</label>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <label className="form-label">Observación</label>
                        <label className="form-control form-control-sm text-center">{ observacion }</label>
                    </div>
                </div>
            </div>

            <hr className='bg-danger'/>

            <form className="g-3">
                <div className='row'>
                    <div className='col-6 col-md-3'>
                        <Select isClearable
                            className='form-sm' id='producto' name='producto'
                            onChange={ setProducto } placeholder={ 'Seleccione un producto' }
                            options={ options }/>
                    </div>
                    <div className='col-6 col-md-2'>
                        <select className='form-select form-control-sm' name='motivo' 
                                ref={ motivoSelector }>
                            {
                                devolucionData?.map((item) => (
                                    <option key={item.Id} value={item.Id}  data-texto={item.Motivo}>
                                        {`${item.Id} - ${item.Motivo}`}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-4 col-md-2 mt-3 mt-md-0'>
                        <input type='text' className='form-control form-control-sm text-center' placeholder='Lote'
                            name='lote' value={ lote } onChange={ onInputChange } />
                    </div>
                    <div className='col-4 col-md-2 mt-3 mt-md-0'>
                        <input type='date' className='form-control form-control-sm text-center' placeholder='Vencimiento' 
                            name='vencimiento' value={ vencimiento } onChange={ onInputChange }/>
                    </div>
                    <div className='col-4 col-md-2 mt-3 mt-md-0'>
                        <input type='text' className='form-control form-control-sm text-center' placeholder='Cantidad' 
                            name='cantidad' value={ cantidad } onChange={ onInputChange }/>
                    </div>
                    <div className='col-12 col-md-1 mt-3 mt-md-0'>
                        <button type='button' 
                                className='btn btn-success btn-sm btn-block rounded-pill' 
                                style={{width: '100%'}} 
                                title='Agregar registro al detalle'
                                onClick={ onNuevoRegistro }>
                            <span className='fa-solid fa-add'></span>
                        </button>
                    </div>
                </div>
            </form>

            <hr className='bg-danger'/>

            <div className="table-responsive">
                <table className='table table-sm table-striped table-bordered'>
                    <thead>
                        <tr>
                            <th>Accion&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <th>Codigo</th>
                            <th>Producto</th>
                            <th>Cantidad</th>
                            <th>Lote</th>
                            <th>Vencimiento</th>
                            <th>Motivo</th>
                        </tr>
                    </thead> 
                    <tbody>
                    {
                    valeDetalle?.map(({ID_Devolucion_Detalle, Lote, Fecha_Vencimiento, Motivo_Devolucion, ID_Producto, Producto, Cantidad}) => (
                        <tr key={ID_Devolucion_Detalle}>
                            <td>
                                <button type='button' className='btn btn-sm btn-danger rounded-pill mx-1'
                                    onClick={ (e) => onEliminarRegistro(e, ID_Devolucion_Detalle) }>
                                    <span className='fa-solid fa-trash'></span>
                                </button>
                                <button type='button' className='btn btn-sm btn-primary rounded-pill mx-1'
                                    onClick={ (e) => onCambiarCantidaRegistro(e, ID_Devolucion_Detalle, Cantidad) }>
                                    <span className='fa-solid fa-edit'></span>
                                </button>
                            </td>
                            <td>{ID_Producto}</td>
                            <td>{Producto}</td>
                            <td>{Cantidad}</td>
                            <td>{Lote}</td>
                            <td>{Fecha_Vencimiento}</td>
                            <td>{Motivo_Devolucion}</td>
                        </tr>  
                        ))
                    }  
                    </tbody>               
                </table>
            </div>
        </div>
    </>
  )
}
import React from 'react'

export const PaginationTable = ({
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex, 
    pageSize
}) => {
  return (
    <div className="pagination justify-content-end">
        <span className='me-4'>
            Pagina&nbsp;
            <strong>
                {pageIndex + 1} de {pageOptions.length}
            </strong>
        </span>

        <div className="btn-group me-4 bg-white" role="group">
            <button type='button' className='btn btn-outline-secondary btn-sm'
                onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <i className="fa-solid fa-angles-left"></i>
            </button>

            <button type='button' className='btn btn-outline-secondary btn-sm'
                onClick={() => previousPage()} disabled={!canPreviousPage}>
                <i className="fa-solid fa-angle-left"></i>
            </button>

            <button type='button' className='btn btn-outline-secondary btn-sm'
                onClick={() => nextPage()} disabled={!canNextPage}>
                    <i className="fa-solid fa-angle-right"></i>
            </button>

            <button  type='button' className='btn btn-outline-secondary btn-sm'
                onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <i className="fa-solid fa-angles-right"></i>
            </button>
        </div>

        <div className='col-2'>
            <select 
                className='form-select form-select-sm'
                value={pageSize} 
                onChange={e => setPageSize(Number(e.target.value))}>
                {[10, 25, 50, 100].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                    </option>
                ))}
            </select>
        </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2'

import { valeMotivoSinLiquidar } from '../../../actions/comercial';

export const AccionValePermisos = ({
    Id, Id_Vale, Cliente, Id_Rastreo, Fecha, Fecha_Ruta, 
    handlerEliminar, handlerFinalizar, handlerLiquidar, handlerRuta, setIsLoading,
    setIdDetalle, setIsOpenVisor, setIsOpenEditor
}) => {

  const dispatch = useDispatch();
  const { token, user } = useSelector(state => state.auth);  
  const navegate = useNavigate(); 
  let isVisible = 'd-none';
  let isVisibleReprogramar = 'd-none';
  let isVisibleFinalizar = 'd-none';
  let isVisibleMotivo = 'd-none';
  let isVisibleLiquidar = 'd-none';
  let supervisor = false;
  let coordinador_ruta = false;

  //obtener los permisos especiales
  user.permiso.filter(item => item.Permiso === 'ROOT').map(filteredName => {
    supervisor = true;
    coordinador_ruta =true;
  });

  user.permiso.filter(item => item.Permiso === 'SUPER').map(filteredName => {
    supervisor = true;
    coordinador_ruta =true;
  });

  user.permiso.filter(item => item.Permiso === 'COORDINADOR_RUTA').map(filteredName => {
    coordinador_ruta =true;
  });

  /* Acciones para realizar por permisos especiales de SUPERVIDOR*/
  if(Id_Rastreo == 1 && supervisor){ 
    isVisible = '';
    isVisibleReprogramar = '';
    isVisibleFinalizar = '';
  }

  /* Acciones para realizar por permisos especiales de SUPERVIDOR*/
  if((Id_Rastreo == 2 || Id_Rastreo == 5) && supervisor == true){
    isVisibleReprogramar = '';
    isVisibleFinalizar = '';
  }

  if(Id_Rastreo == 3 && supervisor == true){
    const fecha_ruta_7_dias = new Date(Fecha_Ruta);
    fecha_ruta_7_dias.setDate(fecha_ruta_7_dias.getDate() + 7);

    if(fecha_ruta_7_dias < new Date){
      isVisibleReprogramar = '';
      isVisibleLiquidar = '';
    }
  }

  /* Acciones para realizar por permisos especiales de COORDINADOR RUTA*/
  if(Id_Rastreo == 5 && coordinador_ruta == true){
    isVisibleReprogramar = '';
  }

  if(Id_Rastreo == 5){
    isVisibleMotivo = '';
  }
  
  const handlerDesgargar = (e) =>{
    e.preventDefault();

    setIsLoading(true);
    const datos = { 
      id_usuario: user.id_usuario, id_devolucion: Id
    }
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    axios.post( `${ process.env.REACT_APP_API_COMERCIAL }consulta/descargar_vale`, datos )
    .then( response =>{
        let json = response.data;

        if(json.stt == "OK"){
            const url = `data:${ json.tipo };base64,${ json.data }`;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `${ Cliente } ${ Id_Vale }_${ Fecha }.${ json.extension }`
            );
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
        }else{
            Swal.fire('Vale', json.msg, 'error');                
        }
        setIsLoading(false);
    })
    .catch( ({message}) =>{
        setIsLoading(false);
        Swal.fire('Vale', message, 'error');            
    });
  }

  const handlerVisor = (e) =>{
    e.preventDefault();
    setIdDetalle(Id);
    setIsOpenVisor(true);
    /*navegate(`/comercial/vales/s/${ Id }`,{
      replace: false
    });*/
  }

  const handlerEditar = (e) =>{
    e.preventDefault();
    setIdDetalle(Id);
    setIsOpenEditor(true);
    /*navegate(`/comercial/vales/u/${ Id }`,{
      replace: false
    });*/
  }

  const handlerMotivo = (e) =>{    
    dispatch( valeMotivoSinLiquidar(token, user.id_usuario, Id, setIsLoading) );
  }

  return (
    <div className="dropdown">
        <a 
            href='#' 
            data-bs-toggle="dropdown" 
            aria-expanded="false">
            { Id }
        </a>
        <ul className="dropdown-menu">
            <li><a className="dropdown-item" onClick={ handlerDesgargar } href="#"><i className="fa-solid fa-download"></i> Pdf</a></li>
            <li><a className="dropdown-item" onClick={ handlerVisor } href="#"><i className="fa-solid fa-eye"></i> Visor</a></li>
            <li className={ isVisible }><a className="dropdown-item" onClick={ handlerEditar } href="#"><i className="fa-solid fa-pen-to-square"></i> Modificar</a></li>
            <li className={ isVisible }><a className="dropdown-item" onClick={ (e) => handlerEliminar(e, Id) } href="#"><i className="fa-solid fa-trash-can"></i> Eliminar</a></li>
            <li className={ isVisibleReprogramar }><a className="dropdown-item" onClick={ (e) => handlerRuta(e, Id) } href="#"><i className="fa-solid fa-truck"></i> Cargar a Ruta</a></li>
            <li className={ isVisibleFinalizar }><a className="dropdown-item" onClick={ (e) => handlerFinalizar(e, Id) } href="#"><i className="fa-solid fa-flag-checkered"></i> Finalizar</a></li>
            <li className={ isVisibleLiquidar }><a className="dropdown-item" onClick={ (e) => handlerLiquidar(e, Id) } href="#"><i className="fa-solid fa-circle-check"></i> Liquidar</a></li>
            <li className={ isVisibleMotivo }><a className="dropdown-item" onClick={ (e) => handlerMotivo(e, Id) } href="#"><i className="fa-solid fa-comment"></i> Motivo</a></li>
        </ul>     
    </div>
  )
}
